
export function changeNumberToDate(price: Object) {
    let delivery = {}
    let minDays = price?.minOnlineDelivery
    let maxDays = price?.maxOnlineDelivery
    let minDelivery = new Date(Date.now() + minDays * 24 * 60 * 60 * 1000)
    let maxDelivery = new Date(Date.now() + maxDays * 24 * 60 * 60 * 1000)

    delivery.minDay = minDelivery.getDate()
    if(delivery.minDay < 10) {
        delivery.minDay = '0' + delivery.minDay
    }
    delivery.minMonth = minDelivery.getMonth() + 1
    if(delivery.minMonth < 10) {
        delivery.minMonth = '0'+delivery.minMonth
    }
    delivery.minYear = minDelivery.getFullYear()
    delivery.maxDay = maxDelivery.getDate()
    if(delivery.maxDay < 10) {
        delivery.maxDay = '0' + delivery.maxDay
    }
    delivery.maxMonth = maxDelivery.getMonth() + 1
    if(delivery.maxMonth < 10) {
        delivery.maxMonth = '0'+delivery.maxMonth
    }
    delivery.maxYear = maxDelivery.getFullYear()

    return delivery
}