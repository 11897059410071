import { ProvideNames } from "./statics";
import { v4 as uuidv4 } from "uuid";
import { getExpUserProfile } from "./ExpApi/expUser";
import { fetchCurrentCart } from "./shoppingCartApi";
import { useState, useFetch, useRuntimeConfig, useCookie, useRequestURL, useRequestHeaders, useNuxtApp } from "nuxt/app";
import type { ShoppingCart } from "~/models/shoppingCart";

/**
 * Returns the currently selected store
 *
 */
export const useCurrentStore = () =>
	useState<any>("currentStore", () => {
		const { data } = useFetch('/api/store', {
			key: 'currentStore',
			query: {
				storeId: computed(() => useStoreCookie().value)
			}, watch: [computed(() => useStoreCookie().value)]
		});
		return data;
	});

export const useCurrentStoreCompanyGroup = () => {
	return useCurrentStore()?.value?.companyGroupId
};
export const useCurrentStoreId = () => {
	return useCurrentStore()?.value?.storeId
};

interface AppCookieSettings {
	isApp: boolean;
	isNativeApp: boolean;
	hasAskedForPushPermission: boolean;
	hasAskedForLocationPermission: boolean;
	hasAskedForRating: boolean;
}

export const useAppSettingsCookie = () => useCookie<AppCookieSettings>("expertAppConfig", {
	domain: useRuntimeConfig()?.public?.cookieDomain, maxAge: 604800, default() {
		return { isApp: false, isNativeApp: false, hasAskedForLocationPermission: false, hasAskedForPushPermission: false, hasAskedForRating: false }
	},
});
export const useIsGuestCookie = () => useCookie("isGuest", { default: () => true });

export const useSearchWord = () => useState<string>('searchWord');
export const useSuggestionsOpenState = () => useState<Boolean>('suggestionsOpen', () => false);
export const useGridDesignState = () => useState<string>('gridDesign', () => 'line');

export const useCurrentSessionIdentifier = () => useState("currentIdentifier", useCurrentUserState().value?.sessionIdentifier);

export const useCurrentUserState = () => useState("userProfile");

export const useCurrentUserNameInfo = () => useCookie('userNameInfo');

export const useCurrentLoggedInState = () => {
	const freshUser = useCurrentUserState();
	let loggedIn = false;
	if (freshUser?.value && Array.isArray(freshUser?.value?.roles) && freshUser.value?.roles.length > 1) {
		loggedIn = true
	}
	if (freshUser && Array.isArray(freshUser.value?.roles) && freshUser.value?.roles.length == 1) {
		loggedIn = (freshUser.value?.roles?.[0] == 'ANONYMOUS' ? false : true)
	}
	return { value: loggedIn };
};

export const useExpUserProfile = () => useState('expUserProfile', () => {
	const { data, refresh } = useFetch('/api/expUserProfile', { retry: 3, retryStatusCodes: [404, 408, 409, 425, 429, 500, 502, 503, 504] });
	return data.value;
})

export const useUserProfile = () => useState('useUserProfile');
export const useCurrentShoppingCart = () =>
	useState("currentShoppingCart", async () => {
		const expUser = await getExpUserProfile();
		const { data: currentShoppingCart } = await fetchCurrentCart(expUser?.shoppingCartId);
		return currentShoppingCart;
	});

export const useCurrentReservationCart = () =>
	useState("currentReservationCart", async () => {
		const expUser = await getExpUserProfile();
		const { data: currentReservationCart } = await fetchCurrentCart(expUser?.reservationCartId);
		return currentReservationCart;
	});

export const useActivePromotions = () => useState("activePromotions");
export const useShoppingCartItemsCount = () => useState<number>("shoppingCartItemsCount", () => 0);
export const useReservationCartItemsCount = () => useState<number>("reservationCartItemsCount", () => 0);
export const useActivatedServicesInCart = () => useState<any>("activatedServices", () => [])

export declare interface CookieBotCookie {
	REQUIRED: boolean;
	STATISTICS: boolean;
	MARKETING: boolean;
	PRODUCT_RATINGS: boolean;
}

const permissionMapping = {
	REQUIRED: 'necessary',
	STATISTICS: 'statistics',
	MARKETING: 'marketing',
	PRODUCT_RATINGS: 'preferences'
}


export const getCookiePermissions = (permission: string) => useState("cookie" + permission, () => {
	return useCookie('CookieConsent')?.value?.[permissionMapping?.[permission]] == 'true';
}

)


export declare interface AddressFormData {
	title?: string | undefined;
	company?: string | undefined;
	firstName?: string | undefined;
	lastName?: string | undefined;
	street?: string | undefined;
	houseNumber?: string | undefined;
	zip?: number | undefined;
	city?: string | undefined;
	telephone?: string | undefined;
	email?: string | undefined;
	countryCode?: string | undefined;
}

export declare interface CheckoutRegistration {
	invoiceAddress: AddressFormData | undefined;
	login: {
		email: string | undefined;
		password: string | undefined;
	}
};

export const useReservationFormData = () => useState<AddressFormData>('reservationFormData', () => {
	return getDefaultAddress();
});

export const useReservationFormAdditionalInfo = () => useState<{ comment?: string, newsletterOptIn?: boolean }>('reservationFormAdditionalData', () => { return { comment: '', newsletterOptIn: false } });

export const useCartFormData = () => useState<{
	insuranceFlag?: boolean,
	newsletterFlag?: boolean,
	termsFlag?: boolean,
	invoiceAddress?: AddressFormData,
	shippingAddress?: AddressFormData,
	registration?: CheckoutRegistration
}>('cartFormData', () => {
	return {
		insuranceFlag: false,
		newsletterFlag: false,
		termsFlag: true,
		invoiceAddress: getDefaultAddress(),
		shippingAddress: {
			title: "UNKNOWN",
			countryCode: "DE",
			country: "Deutschland"
		},
		registration: {
			login: {}
		}
	}
});


export const useCartOverview = () => useState<AddressFormData>('cartOverview', () => {
	return {}
})

function getDefaultAddress() {
	let defaultAddress = {
		title: "UNKNOWN",
		countryCode: "DE"
	}
	let user = useCurrentUserState()?.value;
	user?.addresses?.forEach(address => {
		if (address.id == useCurrentUserState()?.value?.defaultAddress) {
			defaultAddress = address
		}
	});
	if (defaultAddress.countryCode == 'DE') {
		defaultAddress.country = "Deutschland"
	}
	return defaultAddress
}

// export const getCookieBotPermissions = () => useState<CookieBotCookie>('cookieBotPermission', () => {});

export const useExpCookieIdentifier = () => useCookie('expertShopABIdentifier', {
	secure: true, path: '/', domain: useRuntimeConfig()?.public?.cookieDomain
	// , default: () => "bt_" + uuidv4(), maxAge: 72 * 3600
});


export const useUserGroup = () => useState('userGroup', () => 'anonymous');

export const mayShowDebugContent = () => computed(() => useUserGroup()?.value == 'internal' || useFeatureState('bt-dev-debug')?.value || useFeatureState('bt-internal-test')?.value)

/**
 *
 * @returns fmarktcookie
 */
export const useStoreCookie = () =>
	useCookie(ProvideNames.STORE_COOKIE_NAME, {
		default() {
			let branchId = useRequestURL()?.searchParams?.get("branch_id");
			let campaignId = useRequestURL()?.searchParams?.get("campaignId");

			if (campaignId !== undefined && campaignId !== null) {
				console.warn(`[bt-states] got initial useStoreCookie call with campaign id param ${campaignId}`);
				return ProvideNames.DEFAULT_CENTRAL_STORE.id;
			} else if (branchId !== undefined && branchId !== null) {
				console.warn(`[bt-states] got initial useStoreCookie with branch id param ${branchId}`);
			} else {
				branchId = useRequestHeaders()["fmarktheader"];
				console.log(`[bt-states] no query param using value from header ${branchId}`);
			}

			if (branchId === undefined || branchId === null) {
				branchId = "e_2879130";
				console.log("[bt-states] initial useStoreCookie still undefined, using last fallback " + branchId);
			}
			if (branchId && !branchId.startsWith('e_'))
				branchId = 'e_' + branchId;

			return branchId;
		},
		domain: useRuntimeConfig()?.public?.cookieDomain,
		path: '/',
		secure: useRuntimeConfig()?.public?.cookieSecure === "true",
		maxAge: 30 * 3600 * 24,
	});

export const useCategoryFlyoutState = () => useState<Boolean>("categoryFlyoutState", () => false);
export const useSuperMenuState = () => useState<Boolean>("useSuperMenuState", () => false);
export const useMobileMenuState = () => useState<Boolean>("useMobileMenuState", () => false);
export const useSuperMenuDropdownState = () => useState<String>("useSuperMenuDropdownState", () => '0');
export const useSuperChildMenuDropdownState = () => useState<String>("useSuperMenuDropdownState", () => '0');
export const stateForStoreFinder = () => useState<Boolean>("localStoreFinderPopUp", () => false);
export const stateForStoreFinderFlyOut = () => useState<Boolean>("localStoreFinderFlyOut", () => false);
export const gtmGlobalCounter = () => useState<number>("globalGtmCounter", () => 0);
export const gtmDataContainer = () =>
	useState("GtmDataLayerContainer", () => {
		return {
			event: "reload",
			ecommerce: {
				impressions: [],
				detail: {
					products: {},
				},
			},
		};
	});

export const useFeatureState = (feature: string) => useState<Boolean>("feature-" + feature?.toLowerCase(), () => {
	{
		if (feature === 'cfg-blackweek-header') return true;

		if (!useDevice()?.isCrawler)
			try {
				const { $flags } = useNuxtApp();
				return $flags?.hasFeature(feature?.toLowerCase());
			} catch (error) {
				console.error('error checking flagsmith', error)
			}
		return false;
	}
})

export const useFeatureValue = (feature: string) => {
	if (!useDevice()?.isCrawler)
		try {
			const { $flags } = useNuxtApp();
			// console.debug('flagsmith state: ' + feature + "=" + $flags?.hasFeature(feature));
			return { value: $flags?.getValue(feature) }
		} catch (error) {
			console.error('error checking flagsmith', error)
		}
	return { value: undefined };
}

export const useIsCrawlerState = () => useState('isCrawler', () => false);

export const useCurrentFacettPage = () => useState("currentFacetPage", () => null);

export const useAppPushCookie = () => useCookie('expertAppPushToken', { maxAge: 30 * 24 * 3600, secure: true, sameSite: "strict" });
