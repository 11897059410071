export default defineNuxtRouteMiddleware(async (to, from) => {
	const fm_openedCookie = useCookie("fmarktcookie_opened", { domain: useRuntimeConfig()?.public?.cookieDomain });

	let branch_id;
	let campaignid;
	let partner_id;
	let cstrackid;
	let changer;

	for (let index = 0; index < Object.keys(to.query).length; index++) {
		const paramName = Object.keys(to.query)[index];
		if (paramName.toString()?.toLowerCase() == "branch_id") branch_id = to.query[paramName]?.toString();
		else if (paramName.toString()?.toLowerCase() == "campaignid") campaignid = to.query[paramName];
		else if (paramName.toString()?.toLowerCase() == "partner_id") partner_id = to.query[paramName];
		else if (paramName.toString()?.toLowerCase() == "cstrackid") cstrackid = to.query[paramName];
		else if (paramName.toString()?.toLowerCase() == "changer") changer = to.query[paramName];
	}

	if (campaignid !== undefined) {
		console.debug("[bt-middleware] campaign_id was set, updated cookie store");
		useStoreCookie().value = ProvideNames.DEFAULT_CENTRAL_STORE.id;
		useCurrentStore().value = ProvideNames.DEFAULT_CENTRAL_STORE;
		fm_openedCookie.value = "true";
		const { data } = await useFetch('/api/switchStore', {
			query: {
				branchId: ProvideNames.DEFAULT_CENTRAL_STORE.id
			}
		});
		console.debug('[bt-middleware] got result from fetch:', data?.value);
	} else if (branch_id !== undefined) {
		clearNuxtData('currentStore');
		console.debug("[bt-middleware] found branch_id parameter with value " + branch_id);
		if (!branch_id?.startsWith("e_")) {
			branch_id = "e_" + branch_id;
		}
		if (useStoreCookie().value !== branch_id) {
			console.debug("[bt-middleware] branch_id store was different, updated cookie store");
			const data = $fetch('/api/switchStore', {
				query: {
					branch_id: branch_id
				}
			});
			console.debug('[bt-middleware] got result from fetch:', data);
			changeStoreById(branch_id, useCurrentStore()?.value?.id, 'parameter-branch_id');
			refreshCookie(ProvideNames.STORE_COOKIE_NAME);
		}
		fm_openedCookie.value = "true";
	} else if (to?.fullPath?.toLowerCase().includes('campaignid')) {

	}
	if (changer !== undefined) {
		useState('isChanger').value = changer
	}

	// if (import.meta.client && to.path.endsWith('.html'))
	// if (partner_id !== undefined) {
	// 	console.log('[bt-middleware] found partner_id ' + partner_id + ' calling ' + to.path);
	// 	$fetch('/api/neo/frontend/shop/' + to.path, { query: { partner_id: partner_id } })
	// } else if (cstrackid !== undefined) {
	// 	console.log('[bt-middleware] found cstrackid ' + cstrackid + ' calling ' + to.path);
	// 	$fetch('/api/neo/frontend/shop/' + to.path, { query: { cstrackid: cstrackid } })
	// }

	// if (useStoreCookie().value !== undefined && useCurrentStore().value === undefined) {
	// 	const nuxtApp = useNuxtApp();
	// 	if (process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
	// 		console.log("[bt-middleware] store from cookie not matching", useStoreCookie().value);
	// 		fm_openedCookie.value = "true";
	// 	}
	// }
});
