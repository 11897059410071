import validate from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/pages/runtime/validate.js";
import _02_45params_45global from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/middleware/02.params.global.ts";
import _03_45navigation_45ticks_45global from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/middleware/03.navigation-ticks.global.ts";
import manifest_45route_45rule from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _02_45params_45global,
  _03_45navigation_45ticks_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}