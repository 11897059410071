
import * as cloudflareRuntime$T85kmLucUp from '/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "thumbnail": {
      "modifiers": {
        "width": 100,
        "height": 100,
        "quality": 90,
        "format": "webp"
      }
    },
    "preview": {
      "modifiers": {
        "width": 350,
        "height": 350,
        "quality": 80,
        "format": "webp",
        "fit": "contain"
      }
    },
    "ppmsmall": {
      "modifiers": {
        "width": 175,
        "height": 175,
        "quality": 90,
        "format": "auto"
      }
    },
    "ppmbig": {
      "modifiers": {
        "width": 230,
        "height": 230,
        "quality": 90,
        "format": "auto"
      }
    },
    "promo_small": {
      "modifiers": {
        "width": 60,
        "height": 60,
        "quality": 90,
        "format": "auto"
      }
    },
    "promo_big": {
      "modifiers": {
        "width": 160,
        "height": 160,
        "quality": 90,
        "format": "auto"
      }
    },
    "zoom": {
      "modifiers": {
        "width": 1000,
        "height": 1000,
        "quality": 90,
        "format": "auto"
      }
    },
    "tileView": {
      "modifiers": {
        "height": 200,
        "width": 200,
        "format": "auto",
        "fit": "pad"
      }
    },
    "pdsPrimary": {
      "modifiers": {
        "height": 250,
        "quality": 90,
        "format": "auto"
      }
    },
    "storeLogo": {
      "modifiers": {
        "height": 50,
        "width": 400,
        "quality": 90
      }
    },
    "categoryBannerDesktop": {
      "modifiers": {
        "height": 200,
        "width": 945,
        "quality": 90
      }
    },
    "categoryBannerMobile": {
      "modifiers": {
        "height": 100,
        "width": 390,
        "quality": 90,
        "format": "auto",
        "fit": "contain"
      }
    },
    "seoBanner": {
      "modifiers": {
        "quality": 50,
        "format": "auto",
        "fit": "contain",
        "compression": "fast"
      }
    }
  },
  "provider": "cloudflare",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$T85kmLucUp, defaults: {"baseURL":"https://cdn.expert.de"} }
}
        