<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <div class="close" @click="$emit('close-modal')">
        <slot name="icon"/>
      </div>
      <slot />
    </div>
  </div>
</template>
