<template>
  <template v-if="!pending && medium?.originalData">
    <NuxtImg :class="dragRestriction ? ($device.isMobile ? '' : 'pointer-events-none') : ''" provider="cloudflare"
      :src="'/' + medium?.originalData" :alt="medium.name" :densities="densities" :width="width" :preset="preset"
      :loading="lazy ? 'lazy' : undefined"></NuxtImg>
  </template>
  <template v-else-if="pending && imagePlaceHolder != undefined">
    <NuxtImg :class="dragRestriction ? ($device.isMobile ? '' : 'pointer-events-none') : ''" provider="cloudflare"
      :src="imagePlaceHolder" :densities="densities" :width="width" :preset="preset"
      :loading="lazy ? 'lazy' : undefined"></NuxtImg>
  </template>
</template>
<script setup lang="ts">
import { inject } from 'vue';
import { getMedium } from "~/composables/ExpApi/expCache";

const props = defineProps({
  mediumId: {
    type: String,
    required: true,
  },
  dragRestriction: {
    type: Boolean,
    default: true,
  },
  width: {
    type: Number,
  },
  preset: {
    type: String,
  },
  lazy: {
    type: Boolean,
    default: true,
  },
  debug: {
    type: Boolean,
    default: false,
  },
  imagePlaceHolderSrc: {
    type: String
  },
  densities: {
    type: String,
    default: 'x1',
  }
});

const imagePlaceHolder = props?.imagePlaceHolderSrc ? props?.imagePlaceHolderSrc : inject("ImagePlaceHolder");

const { pending, data: medium } = await getMedium(props?.mediumId);
if (medium?.originalData !== undefined) {
  pending.value = false;
}
</script>
