<template>
  <div class="pb-[60px]">
    <AppLogo />
    <template v-if="currentAncestorTree">
      <div
        class="border-b px-2 py-4 font-bold text-sm text-primary"
        @click="
          currentTree = navigationTree;
          currentAncestorTree = null;
          parentCategoryURL = '';
        "
      >
        Zurück
      </div>
      <NuxtLink
        :to="basedir + parentCategoryURL"
        v-if="currentAncestor != 0"
        class="border-b whitespace-nowrap flex items-center justify-between px-2 py-4 bg-primary text-white"
        @click="currentCategory = 0;"
      >
        <div>{{ parentCategoryTitle }}</div>
        <BasicIcon :name="'chevron-right'" class="w-4 h-4 text-white rotate-90" />
      </NuxtLink>
      <div class="location-nav-list"></div>
    </template>
    <template v-for="entry in getActiveTree()" :key="entry?.id">
      <li class="nav-item relative">
        <NuxtLink          
          class="nav-link border-b whitespace-nowrap flex items-center justify-between px-2 py-4"
          :class="entry?.descendants?.length > 0 ? ' dropdown' : ''"
          target="_self"
          @click="
            currentTree = navigationTree;
            currentAncestorTree = null;
            $emit('close-navigation');
            navigateCurrentAncestorTree();
          "
          :to="basedir + (parentCategoryURL?.length > 0 ? (parentCategoryURL + '/') : '') + entry?.url"
          :title="entry?.alt"
          :id="'dropdownMenu_' + entry?.id"
        >
          {{ entry?.name }}
        </NuxtLink>
        <div
          v-if="entry?.descendants?.length > 0"
          class="duration-300 ease-in-out top-0 right-0 h-full w-10 flex justify-center items-center absolute"
          :class="currentCategory == entry?.id ? 'pointer-events-none' : ''"
          @click.prevent="
            parentCategoryTitle = entry?.name;
            parentCategoryURL = (parentCategoryURL?.length > 0 ? (parentCategoryURL + '/') : '') + entry?.url;
            currentAncestor = entry?.id;
            parentCategory = entry?.id;
            currentAncestorTree = entry;
            currentCategory == entry?.id
              ? (currentCategory = '0')
              : (currentTree = entry?.descendants);
          "
        >
          <template v-if="currentCategory == entry?.id">
            <BasicIcon :name="'chevron-right'" class="w-4 h-4 text-primary rotate-90" />
          </template>
          <template v-else>
            <BasicIcon :name="'chevron-right'" class="w-4 h-4 text-primary" />
          </template>
        </div>
      </li>
    </template>
  </div>
</template>

<script setup>
import { getExpNavigationTree } from "~/composables/ExpApi/expCache";
import { inject } from "vue";
const basedir = inject(ProvideNames.BASE_DIR);
const { data: navigationTree } = await getExpNavigationTree("MAIN");
const { data: sourceTree } = await getExpNavigationTree("SUPER");
const currentTree = ref(null);

const currentAncestorTree = ref();
const currentCategory = ref(0);
const parentCategory = ref(0);
const parentCategoryTitle = ref("");
const parentCategoryURL = ref("");
const currentAncestor = ref("");

function navigateCurrentAncestorTree() {
  currentAncestorTree.value = null;
  currentCategory.value = 0;
  parentCategory.value = 0;
  parentCategoryTitle.value = "";
  parentCategoryURL.value = "";
  currentAncestor.value = "";
}
function getActiveTree() {
  if (currentTree.value == null) {
    return navigationTree.value;
  } else {
    return currentTree.value;
  }
}
</script>
