<template>
<span
  :data-pub-createdOn="pubCreatedOnDate"
  :data-pub-lastUpdated="pubLastUpdatedDate"
  :data-pub-provider="props.pubSource"
  :data-pub-cacheLevel="cacheLevel">
  <pre class="text-xs text-paragraph font-normal" style="letter-spacing: normal;" v-if="mayShowDebugContent()?.value == true">
    createdOn: {{ pubCreatedOnDate }}
    lastUpdated: {{ pubLastUpdatedDate }} 
    provider: {{ pubSource }}
    cacheLevel: {{ cacheLevel }} 
  </pre>
  <slot />
</span>
</template>

<script setup lang="ts">
const props = defineProps({
  createdOn: {
    type: Number,
  },
  lastUpdated: {
    type: Number,
  },
  pubSource: {
    type: String,
  },
  cacheLevel: {
    type: String,
  }
});

const pubCreatedOnDate = computed(() => {
  const createdOnTimestamp = props.createdOn ?? 0;
  if (createdOnTimestamp === new Date(0).getTime()) {
    return 'unset';
  }

  return new Date(createdOnTimestamp).toLocaleString();
});
const pubLastUpdatedDate = computed(() => {
  const lastUpdatedTimestamp = props.lastUpdated ?? 0;
  if (lastUpdatedTimestamp === new Date(0).getTime()) {
    return 'unset';
  }

  return new Date(lastUpdatedTimestamp).toLocaleString();
});
</script>