/**
 * Needs to be reworked
 * @deprecated
 */

import { getExpArticle, getExpArticleWithWebcode } from "./expArticle";
import { getLocalExpStore } from "./expLocalStore";
import { ExpStore } from "~/models/ExpLocalStore/exp_Localstore";
// import { useStoreCookie } from '../states';
import { ExpMediumData } from "~/models/ExpMedium";
import { getMultipleMediums } from "~/models/utils/expApi";
import { CssSyntaxError } from "postcss";

/**
 * Checks the state of the param
 *
 * @date 10.7.2023 - 19:42:21
 *
 * @param {*} param
 * @throws {Error} @if @param undefined
 */
function checkState(param: any) {
	if (param === undefined) {
		console.error("got undefined param", new Error().stack);
		throw new Error("Param was undefined");
	}
}
/**
 * Description placeholder
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @async
 * @returns {unknown}
 */
export async function getAllExpManufacturers() {
	return useLazyFetch(useRuntimeConfig().public.apiUrl + "/api/neo/article-service/getAllManufacturers", {
		method: "POST",
		key: "allmanufacturers",
		body: {},
	});
}

const defaultImage: ExpMediumData = {
	id: "default",
	mediumType: "Image",
	originalData: ProvideNames.PLACEHOLDER_IMAGE,
	name: "default Store Logo",
	alt: "default Store Logo",
	title: "default Store Logo",
};

/**
 * Fetch Medium Data by ID from Cache First
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @async
 * @param {string} mediumId
 * @returns {any}
 */
export function getMedium(mediumId: string) {
	// console.log("getMedium from cache called: " + mediumId);
	checkState(mediumId);

	const cached = useNuxtData("medium/id=" + mediumId);
	if (cached?.data?.value) {
		// console.log("found cached medium data");
		return cached;
	} else {
		// console.log("fetching medium data");
		return useFetch(useRuntimeConfig().public.apiUrl + "/api/medium/" + mediumId, {
			key: "medium/id=" + mediumId,
		});
	}
}


/**
 * Description placeholder
 * @date 24.8.2023 - 09:28:58
 *
 * @export
 * @deprecated
 * @returns {DealOfTheWeek}
 */
export function getDealOfTheWeek() {
	return useLazyFetch(
		useRuntimeConfig().public.apiUrl + "/api/nea/dotw/get",
		{
			transform(data) {
				let mapped = data;
				if (mapped?.imageSource) mapped.imageSource = mapped.imageSource.replace("https://cdn.expert.de/cdn-cgi/image/format=auto", "");
				if (mapped?.backgroundImageSource)
					mapped.backgroundImageSource = mapped.backgroundImageSource.replace("https://cdn.expert.de/cdn-cgi/image/format=auto", "");

				// console.debug("[bt-cache] handling deal of the week response (raw,mapped)", data, mapped);
				return mapped;
			}
		},
	);
}

/**
 * Returns and handles the caching of the Navigation Tree for the MegaMenu Component
 * @param storeId
 * @returns {Navigation}
 */
const doFilter = true;
/**
 * Description placeholder
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @async
 * @param {string} storeId
 * @param {?string} [navigationName]
 */
export const getExpNavigationTree = (navigationName?: string) => { return { data: useState('navigation_tree' + (navigationName || 'MAIN')) } }

export function fetchNavigationTree(navigationName?: string) {
	return $fetch(useRuntimeConfig().public.apiUrl + "/api/navigation", {
		query: {
			storeId: useCurrentStore()?.value?.id,
			tag: (navigationName || 'MAIN'),
		},
		transform(data) {
			let result = data;
			if (result && Array.isArray(result) && result.length > 0) {
				if (navigationName === "FOOTER_HARDLINKS") {
					// console.log("Unsorted ", result);
					result.forEach((item) => {
						if (item.descendants?.length > 0) {
							item.descendants.sort((a, b) => a.position - b.position);
							// console.log("Sort Descend ", item.descendants)
						}
					});
					// let resp = result.sort((a, b) => a.position - b.position);
					// console.log("Sorted ",resp);
					return result.sort((a, b) => a.position - b.position);
				}
				if (navigationName === "FOOTER_SOCIAL") {
					let resp = result;
					return resp;
				}

				let res = navigationName !== "SUPER" ? result.filter((nav) => nav.primaryTree && nav.descendants?.length > 0) : result;
				// res?.forEach((cat) => recursiveSort(cat));
				// res.sort((a, b) => b.position - a.position);
				// nuxtStorage.localStorage?.setData('navtree-' + storeId + '-' + navigationName, res.sort((a, b) => b.position - a.position), 15, 'm');
				//console.log('[bt-cache] stored navTree response in cache ', JSON.stringify(res).substring(0, 15));
				if (navigationName === "FOOTER") return res.slice(0, 6);
				else if (navigationName === "SUPER") return res.reverse();
				else return res;
			}
			return result;
		},
	});
}

export const getOrFetchExpNavigationTree = async (navigationName?: string): Promise<Array<any>> => {
	const { data: navigationData } = getExpNavigationTree(navigationName);
	if (navigationData.value) {
		return navigationData.value;
	} else {
		return await fetchNavigationTree(navigationName);
	}
};

// export async function getExpNavigationTree(navigationName?: string) {
// 	if (navigationName === undefined) navigationName = "MAIN";
// 	let cacheKey = 'navigationTree-' + navigationName;
// 	const cached = import.meta.client ? useNuxtData(cacheKey) : undefined;
// 	if (cached?.data?.value && cached?.data?.value?.[0]?.storeId == useCurrentStore()?.value?.id) {
// 		return cached;
// 	} else {
// 		const resp = 
// 		useNuxtData(cacheKey).data.value = resp;
// 		return useNuxtData(cacheKey);
// 	}
// }

/**
 * Recursively Sorts the Tree
 * @date 10.7.2023 - 19:42:21
 *
 * @param {*} cat
 */
export function recursiveSort(cat: any) {
	if (cat && cat.descendants && Array.isArray(cat.descendants)) {
		cat.descendants.sort((a: { position: number }, b: { position: number }) => b.position - a.position);
		cat.descendants?.forEach((desc: any) => recursiveSort(desc));
	}
}

/**
 * Description placeholder
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @async
 * @param {string} articleId
 * @returns {unknown}
 */
export function getExpertArticle(articleId: string) {
	return getExpArticle(articleId);
}

export function getExpBrand(brandId: string) {
	const cached = useNuxtData("brand/id=" + brandId);

	if (cached?.data?.value) {
		// console.debug("[expCache] returning cached Brand " + brandId);
		return cached;
	}
	// console.debug("[expCache] fetching fresh Brand " + brandId);
	return useLazyFetch(useRuntimeConfig().public.apiUrl + "/api/neo/article/brand/" + brandId, {
		key: "brand/id=" + brandId,
	});
}
/**
 * Description placeholder
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @async
 * @param {string} manufacturerId
 * @returns {unknown}
 */
export async function getExpManufacturer(manufacturerId: string) {
	// console.log("expCache getExpManufacturer : "+manufacturerId)
	return useLazyFetch(useRuntimeConfig().public.apiUrl + "/api/neo/article-service/getManufacturer", {
		method: "POST",
		key: "manufacturer-" + manufacturerId,
		body: {
			manufacturerId: manufacturerId,
		},
	});

	// console.log("getExpManufacturer retuner :; ",retVal)
}

/**
 * bust the chache
 * @date 10.7.2023 - 19:42:21
 *
 * @export
 * @param {string} prefix
 * @returns {*}
 */
export function bustCache(prefix: string) {
	if (prefix === "*") {
		console.debug("clearing local cache");
		// nuxtStorage.localStorage?.clear();
	}
}
