<template>
  <div
    id="recaptcha"
    class="w-100 pb-4 g-recaptcha"    
    :data-sitekey="siteKey"
  >
  </div>
</template>
<script lang="ts" setup>
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'
const { vueApp } = useNuxtApp();
const siteKey = useRuntimeConfig().public.recaptchaKey;
vueApp.use(VueReCaptcha, {
  siteKey: siteKey,
  loaderOptions: {
    renderParameters: {
      hl: 'de'
    }
  }
})

const recaptchaInstance = useReCaptcha()
recaptchaInstance?.recaptchaLoaded().then(function(){
  recaptchaInstance?.instance?.value?.recaptcha.render('recaptcha')
})
function reset() {
  recaptchaInstance.instance.value.recaptcha.reset()
}

defineExpose({reset})
</script>
<style>
  .grecaptcha-badge {
    display: none;
  }
</style>