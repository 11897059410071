import { useCurrentShoppingCart, useCurrentReservationCart } from "~/composables/states";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";

export async function addArticleToCart(article: string, options?: any) {
	let optionArray = []
	let multipleArticleArray = []
	let freshUser = await getExpUserProfile();
	let store = useCurrentStore().value.id
	if(isProxy(options)) {
		options= toRaw(options)
		for (const [key, value] of Object.entries(options)) {
			if(value?.key?.typeName) {
				optionArray.push(toRaw(value?.key?.typeName))
			} else {
				multipleArticleArray.push(value?.key?.expArticle?.articleId)
			}
		}
	} else if(Array.isArray(options)) {
		optionArray = options
	}
	if (freshUser?.shoppingCartId) {
		const { error, data: adden } = await addArticleToShoppingCart(article, 1, freshUser?.csrfToken, optionArray);
		if (adden.value !== null) {
			const {data: sdat} = await fetchCurrentCart(freshUser?.shoppingCartId);
			useCurrentShoppingCart().value = sdat.value;
			if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
				adden.value.overAllQuantity = 0
				for (let a = 0; a < adden?.value?.itemList?.length; a++) {
					if (article === adden?.value?.itemList[a]?.articleId) {
						adden.value.overAllQuantity += adden?.value?.itemList[a].quantity;
					}
				}
			}
		}
		if (error?.value !== null) {
			let errCResp = error?.value?.cause?.response?._data;
			if(error?.value?.cause?.namespace && error?.value?.cause?.errorcode) errCResp = error?.value?.cause
			// let errMsg = errResp?.body await errResp.json();
			throw new Error("Error adding to Cart", {cause: errCResp});
		} else {
			useShoppingCartItemsCount().value += 1;
		}
		if(multipleArticleArray.length > 0) {
			for(let a = 0; a< multipleArticleArray.length; a++) {
				const {error: fail , data:user} = await useFetch('/api/neo/frontend/_api/user/getCsrfToken');
				const { error, data: next } = await addArticleToShoppingCart(multipleArticleArray[a], 1, user?.value?.csrfToken, []);
				if (next.value !== null) {
					const {data: sdat} = await fetchCurrentCart(freshUser?.shoppingCartId);
					useCurrentShoppingCart().value = sdat.value;
					if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
						next.value.overAllQuantity = 0
						for (let a = 0; a < next?.value?.itemList?.length; a++) {
							if (article === next?.value?.itemList[a]?.articleId) {
								next.value.overAllQuantity += next?.value?.itemList[a].quantity;
							}
						}
					}
				}
				if (error?.value !== null) {
					let errCResp = error?.value?.cause?.response?._data;
					if(error?.value?.cause?.namespace && error?.value?.cause?.errorcode) errCResp = error?.value?.cause
					throw new Error("Error adding to Cart", {cause: errCResp});
				} else {
					useShoppingCartItemsCount().value += 1;
				}
			}
		}
	}
}

export async function addArticleToResCart(article: string, options?: []) {
	let freshUser = await getExpUserProfile();
	if (freshUser?.reservationCartId) {
		const { error, data: adden } = await addArticleToReservationCart(article, 1, freshUser?.csrfToken, options);
		if (adden.value !== null) {
			useCurrentReservationCart().value = fetchCurrentCart(freshUser?.reservationCartId).data;
			if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
				adden.value.overAllQuantity = 0
				for (let a = 0; a < adden?.value?.itemList?.length; a++) {
					adden.value.overAllQuantity += adden?.value?.itemList[a].quantity;
				}
			}
		}
		if (error?.value !== null) {
			let errCResp = error?.value?.cause?.response?._data;
			if(error?.value?.cause?.namespace && error?.value?.cause?.errorcode) errCResp = error?.value?.cause
			throw new Error("Error adding to Cart", {cause: errCResp});
		} else {
			if(isNaN(adden.value.overAllQuantity)) useReservationCartItemsCount().value += 1;
			else useReservationCartItemsCount().value = adden.value.overAllQuantity
			
		}
	}
}

export async function getAllShippingMethodOptions(methContainer: []) {
	let freshUser = await getExpUserProfile();
	let totalShippingCosts: number = 0;
	if (freshUser?.shoppingCartId) {
		const meth = await getShippingMethodOptionsByIds(freshUser?.csrfToken, methContainer);
		if (meth?.value && meth?.value.length > 0) {
			for (let a = 0; a < meth?.value.length; a++) {
				totalShippingCosts += meth?.value[a]?.defaultPrice?.gross;
			}
			return totalShippingCosts;
		}
	}
}
