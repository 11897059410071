<template>
  <header class="fixed top-0 left-0 w-full py-2 z-10 bg-white app">
    <div class="text-sm flex px-2">
      <div id="2" class="sticky-header grow">
        <div>
          <div
            class="store-logo-container max-h-14 h-14 bg-white mb-4"
          >
            <NavigationStoreLogo />
          </div>
          <div v-if="!openSearchBarMobile">
            <div id="searchbox" class="relative">
              <input
                id="termsuggest-input"
                type="text"
                name="q"
                v-model="suchWort"
                v-on:keyup.enter="handleSearch(suchWort)"
                @input="useSuggestionsOpenState().value = true"
                placeholder="Suchen nach Produkten, Marken, Web-Code & mehr..."
              />
              <button
                v-if="!isListening && activeVoiceFeature"
                class="absolute right-10 top-1/2 -translate-y-1/2"
                @click="start"
              >
                <BasicIcon
                  :name="'microphone'"
                  class="inline-block w-4 text-primary"
                />
              </button>
              <button
                v-if="isListening && activeVoiceFeature"
                class="absolute right-10 top-1/2 -translate-y-1/2"
                @click="stop"
              >
                <BasicIcon
                  :name="'microphone'"
                  class="inline-block w-4 text-primary-dark"
                />
              </button>
              <div class="voice-notifictation shadow-2xl bg-white border-2 border-primary text-primary fixed px-4 py-1 rounded-lg top-10 left-1/2 -translate-x-1/2 text-center" v-if="isListening">Spracherkennung<br/><strong>aktiviert</strong></div>
              <button
                class="absolute right-2 top-1/2 -translate-y-1/2"
                @click="handleSearch(suchWort)"
              >
                <BasicIcon
                  :name="'magnifying-glass-solid'"
                  class="inline-block w-5 text-primary"
                />
              </button>
              <div id="termsuggest"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ClientOnly>
      <Teleport to="#termsuggest">
        <div
          class="flex w-full absolute right-0 top-full"
          v-show="useSuggestionsOpenState().value"
          ref="suggestionsBox"
        >
          <div
            class="suggestions grid border-t-white absolute w-full bg-white py-4 border-white shadow"
            :class="{ 'grid-cols-12': suggestions?.termSuggest?.length > 0 }"
            v-if="suggestions?.termSuggest?.length > 0"
          >
            <div v-if="suggestions.termSuggest.length > 0" class="col-span-12">
              <template
                v-for="suggestion in suggestions.termSuggest"
                v-bind:key="suggestion.label"
              >
                <div>
                  <button
                    class="hover:bg-secondary px-4 py-2 w-11/12 text-left"
                    v-html="suggestion.highlighting"
                    @click="editSearch(suggestion.label, 'Suchvorschlag')"
                  />
                  <div class="arrow-icon">
                    <BasicIcon
                      class="w-4 h-4 text-primary"
                      :name="'arrow-up-left-solid'"
                      @click="editSearch(suggestion.label, 'Suchvorschlag')"
                    ></BasicIcon>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </Teleport>
    </ClientOnly>
  </header>
</template>

<script setup lang="ts">
import {
  onClickOutside,
  useDebounceFn,
  watchThrottled,
  useSpeechRecognition,
} from "@vueuse/core";
import { inject } from "vue";
import { mapToExpPrice, mapToBrandAndArticle } from "~/composables/ExpApi/expSearch";
// import { SearchBox, ReactiveBase } from "@appbaseio/reactivesearch-vue";
import { useCurrentStore } from "~/composables/states";
const creds = useReactiveSearchCreds();
var suchWort = ref("");
const props = defineProps({
  baseDir: {
    default: () => {
      return inject(ProvideNames.BASE_DIR);
    },
  },
});
const activeVoiceFeature = ref(false);
onNuxtReady(async () => {
  activeVoiceFeature.value = !iOS();
});

function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }
const suggestionsBox = ref();
onClickOutside(suggestionsBox, (event) => (useSuggestionsOpenState().value = false));
const route = useRoute();
const currentStore = useCurrentStore()?.value?.id;
const articleSuggests = ref(new Array());
const userAccount: Object = {};
const shoppingCart: Object = {};
const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const updateFunction = storeRef?.updateFunction || console.error;
const lang = ref("de-DE");
const speech = useSpeechRecognition({
  lang,
  interimResults: false,
  continuous: false,
});
const { isListening, stop, result } = speech;

if (speech.isSupported.value) {
  watch(speech.result, () => {
    suchWort.value = '';
    suchWort.value = speech.result.value;
    useSuggestionsOpenState().value = true
  });
}

function start() {
  speech.result.value = "";
  speech.start();
}

const queryFor = ref("");
const { data: suggestions } = await useLazyFetch(
  useRuntimeConfig()?.public.apiUrl + "/api/search/suggest",
  {
    query: {
      q: queryFor,
      storeId: useStoreCookie(),
      noArticles: useDevice().isMobile,
    },
  }
);

watchThrottled(
  suchWort,
  (val) => {
    console.debug("Suchwort changed:", suchWort.value);
    queryFor.value = suchWort.value;
  },
  { throttle: 250 }
);
function handleSearch(token) {
  if(token?.toLowerCase() == '#einruckeln'){
    useEasterEgg('einruckeln');
  } else if(token?.toLowerCase() == '#btdev'){
    useEasterEgg('btdev');
    setSearchWord('')
    return;
  }
  useMarker("SearchUsed");
  handleHardlinkCheck(token);
  useSuggestionsOpenState().value = false;
}

const openSearchBarMobile = ref(false);
function checkForClick() {
  if (openSearchBarMobile.value) {
    openSearchBarMobile.value = false;
  } else {
    openSearchBarMobile.value = true;
  }
}

const image = ref(
  useCurrentStore()?.value?.storeLogoImg ||
    "47/f1/37/344581753cb59b3dfbe24d781b76f70899/expert_logo.svg"
);

function setSearchWord(event: any) {
  if (event == "[object Event]") {
  } else {
    suchWort.value = event;
  }
}

function getSearchParams() {
  return "";
}

function setSearchParams() {}
function editSearch(suggestion: any, suggest?: any) {
  useMarker("UsedSuggest" + suggest);
  suchWort.value = suggestion;
  useSearchWord().value = suggestion;
  handleHardlinkCheck(suggestion, suggest);
}
async function transformElasticResponse(elasticsearchResponse: any, _componentId: any) {
  if (suchWort.value.length < 2) {
    articleSuggests.value.length = 0;
    return {
      hits: {
        hits: "",
      },
    };
  } else {
    if (elasticsearchResponse.hits.hits.length == 0) {
      const originalHits = elasticsearchResponse.aggregations.auto_complete.buckets;
      const terms = originalHits.map((bucket: any) => {
        const term =
          bucket.top_suchanfragen_hits.hits.hits[0]._source.suchphrase_analyzed;
        const highlight =
          bucket.top_suchanfragen_hits.hits.hits[0].highlight.suchphrase_analyzed;
        return {
          label: term,
          value: term,
          highlighting: highlight[0],
          sectionId: "index",
        };
      });

      return {
        hits: {
          hits: terms,
        },
        aggregations: elasticsearchResponse.aggregations,
      };
    } else {
      articleSuggests.value.length = 0;
      const articleSuggestions = elasticsearchResponse.hits.hits.map(
        (articleRes: any) => {
          let article = mapToBrandAndArticle(articleRes._source);
          let price = mapToExpPrice(
            articleRes._source.storeData,
            useCurrentStore()?.value?.id
          );
          const articleSuggestion = {
            article: article,
            price: price,
          };
          articleSuggests.value.push(articleSuggestion);
          return {
            name: articleSuggestion,
          };
        }
      );
      return {
        hits: {
          hits: articleSuggestions,
        },
      };
    }
  }
}
const webCodeRegEx = new RegExp("[0-9]{11}");

async function handleHardlinkCheck(value: any, suggest?: any) {
  if (value?.toLowerCase()?.trim() == "zentralshop1") {
    return await switchToCentralStore(updateFunction);
  } else if (value?.startsWith("e_")) {
    const { data: rawResult } = await useFetch(
      `/api/search/article/id/${value}?storeId=${currentStore}`
    );
    // console.log('got article and rawResult ', rawResult);
    let mappedArticle = rawResult?.value?.article;
    // console.log('got mapped result', mappedArticle)
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? "true" : undefined,
        },
      });
    } else {
      await navigateTo(
        {
          path: props?.baseDir + mappedArticle?.slug,
          query: {
            sq: value,
            queryType: "webcode",
          },
        },
        { external: false, redirectCode: 307 }
      );
    }
  } else if (webCodeRegEx.test(value)) {
    const { data: rawResult } = await useFetch(
      `/api/search/article/webcode/${value}?storeId=${currentStore}`
    );
    // console.log('got article and rawResult ', rawResult);
    let mappedArticle = rawResult?.value?.article;
    // console.log('got mapped result', mappedArticle)
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
        },
      });
    } else {
      await navigateTo(
        {
          path: props?.baseDir + mappedArticle?.slug,
          query: {
            sq: value,
            queryType: "webcode",
          },
        },
        { external: false, redirectCode: 307 }
      );
    }
  } else {
    const { data } = await useFetch(`/api/search/redirect/${value}`);
    if (data?.value?.redirectUrl?.length > 0) {
      //let reqUrl = useRequestURL();
      let redPath = data?.value?.redirectUrl?.split("?")?.at(0);

      let isExt = !redPath?.includes("unsere-produkte") && !redPath?.includes("suche");
      // redPath = isExt ? redPath : redPath?.replace("/shop", "");
      let params = new URLSearchParams("?" + data?.value?.redirectUrl?.split("?")?.at(1));
      params.append("sq", value);
      if (data?.value?.redirectType)
        params.append("queryType", data?.value?.redirectType);

      console.debug(
        "queries and final path",
        isExt,
        params,
        redPath + (params.size > 0 ? "?" + params.toString() : "")
      );

      await navigateTo(redPath + (params.size > 0 ? "?" + params.toString() : ""), {
        external: isExt,
        redirectCode: 307,
      });
    } else {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? "true" : undefined,
        },
      });
      useSearchWord().value = value;
      suchWort.value = value;
    }
  }
}

onMounted(() => {
  try {
    window.addEventListener("CookiebotOnConsentReady", function (e) {
      function delete_cookie(name, path?, domain?) {
        if (get_cookie(name)) {
          document.cookie =
            name +
            "=" +
            (path ? ";path=" + path : "") +
            (domain ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
        }
      }
      function get_cookie(name) {
        return document.cookie.split(";").some((c) => {
          return c.trim().startsWith(name + "=");
        });
      }
      var prefs = {
        REQUIRED: Cookiebot.consent.necessary,
        STATISTICS: Cookiebot.consent.statistics,
        MARKETING: Cookiebot.consent.marketing,
        PRODUCT_RATINGS: Cookiebot.consent.preferences,
      };
      delete_cookie("UserCookiePermissions");
      document.cookie =
        "UserCookiePermissions=" + encodeURIComponent(JSON.stringify(prefs));
      for (let k in prefs) {
        useState("cookie" + k).value = prefs[k];
      }
    });
  } catch (error) {
    console.warn("error checking cookie permissions.", error);
  }
});
</script>
<style>
#q-downshift #q-input {
  min-height: 52px;
  padding: 12px 36px;
  background-color: white;
  z-index: 1;
}

.css-baju5p {
  display: none;
}

#termsuggest-downshift .css-flmbq0 {
  height: 40px;
  display: block;
}

#termsuggest-downshift .css-cccewl {
  display: none;
}

#termsuggest-downshift .css-eoib4r {
  height: 100%;
  display: block;
}

#termsuggest-downshift .css-12z5vca {
  height: 100%;
  display: block;
}

.hidden_input {
  display: none;
}

.css-tfdh8t {
  @apply text-primary;
}

#termsuggest-downshift .css-v7fs1h {
  margin: 0;
}

#termsuggest-downshift .css-dra1z9 {
  min-width: 40px;
}

#termsuggest-downshift .css-dra1z9:last-child {
  border-left: 1px solid rgb(204, 204, 204);
}

.arrow-icon {
  cursor: pointer;
  display: contents;
}

.store-logo-dark {
  filter: hue-rotate(180deg);
}
.app .store-logo {
  min-height: 3.5rem;
}
.store-logo-dark-2 {
  filter: invert(1);
}

#termsuggest-input {
  @apply rounded-lg text-sm text-paragraph-light focus:text-primary leading-6 w-full border border-transparent outline-none;
  padding: 8px 7px 9px;
  min-height: unset;
  resize: none;
  overflow: hidden;
  height: 40px;
}
</style>
