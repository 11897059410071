export default defineNuxtPlugin({
    name: 'nuxt:restore-state-2',
    hooks: {
        'app:error'(err) {
            if (import.meta.client) {
                const nuxtApp = useNuxtApp()
                try {
                    let error = err?.message ? err : new Error(JSON.stringify(err));
                    if (!error?.cause)
                        error.cause = err;
                    nuxtApp.$faro.api.pushError(error)
                    // try {
                    //     const state = sessionStorage.getItem('nuxt:reload:state') || localStorage.getItem('nuxt:reload:state')
                    //     if (state) {
                    //         console.warn('restored state from storage');
                    //         sessionStorage.removeItem('nuxt:reload:state')
                    //         localStorage.removeItem('nuxt:reload:state')
                    //         // Object.assign(nuxtApp.payload.state, destr<Record<string, any>>(state)?.state)
                    //     }
                } catch {
                    // don't throw an error if we have issues reading sessionStorage
                }
            }
        },
    },
})