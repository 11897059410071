<template>
  <template v-if="serviceData">
    <div class="entry" v-for="entry in serviceData.slice().reverse()" :key="entry">
      <div class="reservName">
        <div class="checkbox pr-3" v-if="showInput">
          <input type="checkbox" :id="buildIdFromServiceEntry(entry)" @change="
            $emit('modifyOption', {
              entryId: determineUpdatedEntries(entry),
              value: $event.target?.checked,
            });
          " :disabled="readOnly" :checked="preselected?.includes(entry?.id?.replace('e_', ''))" />
        </div>
        <div class="reservTitleClass flex flex-grow lg:flex-grow-0">
          <div class="image pr-2 py-1">
            <ArticleExpMedium v-if="!entry?.iconData" :class="'w-7'" :mediumId="entry?.icon"></ArticleExpMedium>
            <NuxtImg v-else class="w-7" :src="entry?.iconData?.originalData" />
          </div>
          <div class="flex flex-col">
            <label class="title font-bold" :for="buildIdFromServiceEntry(entry)">
              {{ entry.label }}
            </label>
            <template v-if="entry.price">
              <div class="price text-sm">{{ entry.price.gross.toFixed(2) }} €</div>
            </template>
          </div>
        </div>
        <div class="info ml-2 py-0.5 relative">
          <img class="ic-icon cursor-pointer" src="~/assets/mediums/Icons/circle-info-light.svg" alt="Info"
            @mouseleave="$device.isDesktop ? (toggleInfoPopUp = '0') : false" @mouseover="
              $device.isDesktop
                ? toggleInfoPopUp == entry.id
                  ? (toggleInfoPopUp = '0')
                  : (toggleInfoPopUp = entry.id)
                : false
              " @click="
    toggleInfoPopUp == entry.id
      ? (toggleInfoPopUp = '0')
      : (toggleInfoPopUp = entry.id)
    " />
          <div v-if="toggleInfoPopUp == entry.id"
            class="infoBox w-80 text-sm absolute lg:pointer-events-none z-10 mt-2 -right-4 lg:-right-36">
            {{ entry.tooltipText }}
          </div>
        </div>
      </div>
      <div class="childEntry reservName" v-for="child in entry?.childService" :key="child">
        <div class="checkbox pr-3" v-if="showInput">
          <input type="checkbox" :id="buildIdFromServiceEntry(child)" @change="
            $emit('modifyOption', {
              entryId: determineUpdatedEntries(child, entry),
              value: $event.target?.checked,
            });
          " :disabled="readOnly" :checked="preselected?.includes(child?.id?.replace('e_', ''))" />
        </div>
        <div class="reservTitleClass flex flex-grow lg:flex-grow-0">
          <div class="image pr-2 py-1">
            <ArticleExpMedium v-if="!child?.iconData" :class="'w-7'" :mediumId="child?.icon"></ArticleExpMedium>
            <NuxtImg v-else class="w-7" :src="child?.iconData?.originalData" />
          </div>
          <div class="flex flex-col">
            <label class="title font-bold" :for="buildIdFromServiceEntry(child)">
              {{ child.label }}
            </label>
            <template v-if="child.price">
              <div class="price text-sm">{{ child.price.gross.toFixed(2) }} €</div>
            </template>
          </div>
        </div>
        <div class="info ml-2 py-0.5 relative">
          <img class="ic-icon cursor-pointer" src="~/assets/mediums/Icons/circle-info-light.svg" alt="Info" @click="
            toggleInfoPopUp == child.id
              ? (toggleInfoPopUp = '0')
              : (toggleInfoPopUp = child.id)
            " @mouseover="
    $device.isDesktop
      ? toggleInfoPopUp == child.id
        ? (toggleInfoPopUp = '0')
        : (toggleInfoPopUp = child.id)
      : false
    " @mouseleave="$device.isDesktop ? (toggleInfoPopUp = '0') : false" />
          <div v-if="toggleInfoPopUp == child.id"
            class="infoBox w-80 absolute lg:pointer-events-none z-10 mt-2 -right-4 lg:-right-36">
            {{ child.tooltipText }}
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import ArticleExpMedium from "~/components/Article/ExpMedium.vue";
const props = defineProps({
  price: {
    type: Object,
  },
  services: {
    type: Object,
  },
  showInput: {
    type: Boolean,
    required: true,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
  preselected: {
    type: Array,
    default: [],
  },
  prefix: {
    type: String,
    default: "",
  }
});
let toggleInfoPopUp = ref("0");

const serviceData = ref(
  props?.price?.availableServices || props?.services?.availableServices
);

const emit = defineEmits(["modifyOption"]);

const buildIdFromServiceEntry = (entry: any): string => {
  return (props?.prefix ? props?.prefix + '_' : '') + entry?.id;
};

const bubbleUpdateFromChildToParent = (entry: any, parentEntry: any): Array<string> => {
  const returnArr: Array<string> = [];
  const parentElement = document.querySelector<HTMLInputElement>("#" + buildIdFromServiceEntry(parentEntry));
  const entryElement = document.querySelector<HTMLInputElement>("#" + buildIdFromServiceEntry(entry));
  if(entryElement?.checked && parentElement && !parentElement.checked) {
    parentElement.checked = true; 
    returnArr.push(parentEntry.id.replace("e_", ""));
  }
  return returnArr;
};

const passUpdateFromParentToChilds = (entry: any): Array<string> => {
  const returnArr: Array<string> = [];
  for (const childService of entry.childService) {
    const childElement = document.querySelector<HTMLInputElement>("#" + buildIdFromServiceEntry(childService));
    if(childElement?.checked) {
      childElement.checked = false;
      returnArr.push(childService.id.replace("e_", ""));
    };
  }
  return returnArr;
};

const handleUncheck = (entry: any): Array<string> => {
  let returnArr: Array<string> = [];
  returnArr = returnArr.concat(passUpdateFromParentToChilds(entry));
  return returnArr;
}

const handleCheck = (entry: any, parentEntry: any): Array<string> => {
  let returnArr: Array<string> = [];
  if (parentEntry) {
    returnArr = returnArr.concat(bubbleUpdateFromChildToParent(entry, parentEntry));
  }
  return returnArr;
}

const determineUpdatedEntries = (entry: any, parentEntry?: any ) => {
  let updated: Array<string> = [entry?.id.replace("e_", "")];
  const targetCheckbox = document.querySelector<HTMLInputElement>("#" + buildIdFromServiceEntry(entry));
  updated = updated.concat(targetCheckbox?.checked ? handleCheck(entry, parentEntry) : handleUncheck(entry));
  return updated;
}
</script>
<style>
.childEntry {
  padding-left: 30px;
}
</style>
