<template>
	<div class="relatedArticles relative flex py-2 gap-3 items-start" v-for="entry in crossSaleArticles" :key="entry?.id">
		<div v-if="showInput" class="custom-checkbox">
			<label>
				<input class="relatedArticleCheckBox" type="checkbox" name="relatedArticlesCheckbox" :id="entry?.id"
					@click="changeInputState(entry, $event)" />
				<span class="custom-checkbox-helper"></span>
			</label>
		</div>
		<div class="grow" @click="changeInputState(entry, $event)">
			<div class="title font-bold">{{ entry.brand }} {{ entry.name }}</div>
			<div class="text-sm">{{ parseFloat(entry.price).toFixed(2) }} €</div>


			<div class="p-4 border bg-secondary-light text-center" :id="'showModel_' + entry.id"
				v-if="showMobileInfo && selectedInfo == entry.id">
				<ul v-for="point in entry?.expArticle?.descriptionList" :key="point" style="list-style-type: circle;">
					<li style="text-align: left; margin-left: 1em;">{{ point }}</li>
				</ul>
			</div>
		</div>
		<div class="uvp-info" v-if="!showFlyOut || $device.isMobile">
			<img style="width:20px;" src='~/assets/mediums/Icons/circle-info-light.svg' @click="openMobiletext(entry.id)"
				alt="UVP Info" />
			<div class='uvp-hover-info whitespace-nowrap' v-if='relatedInfosOpened && relatedArticleId === entry.id'>
				<ul v-for="point in entry?.expArticle?.descriptionList" :key="point" style="list-style-type: circle;">
					<li style="text-align: left; margin-left: 1em; font-size: 14px;">{{ point }}</li>
				</ul>
			</div>
		</div>
		<div class="uvp-info" v-if="showFlyOut && !$device.isMobile">
			<img style="width:20px;" src='~/assets/mediums/Icons/circle-info-light.svg'
				@mouseleave="closeRelatedInformation()" @mouseover="openRelatedInformations(entry.id)" alt="UVP Info" />
			<div class='uvp-hover-info whitespace-nowrap' v-if='relatedInfosOpened && relatedArticleId === entry.id'
				style="background-color: #EBEBEB;">
				<ul v-for="point in entry?.expArticle?.descriptionList" :key="point" style=" list-style-type: circle;">
					<li style="text-align: left; margin-left: 1em;">{{ point }}</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script setup lang="ts">
const props = defineProps({
	showInput: {
		type: Boolean,
		default: false
	},
	crossSaleArticles: {
		default: () => {
			let crSale = toValue(inject("crossSaleArticle"));
			let result = new Array();
			for (let index = 0; index < crSale?.length; index++) {
				const element = crSale[index];
				let newEntry = {
					id: element?.article?.webcode,
					name: element?.article?.title,
					brand: element?.brand?.name,
					image: element?.article?.primaryImage,
					price: element?.price?.bruttoPrice + " .-",
					expArticle: element?.article,
					expPrice: element?.price,
				};
				console.debug('pushing ', newEntry)
				result.push(newEntry);
			}
			return result;
		},
	},
	showFlyOut: {
		type: Boolean,
		default: true
	}
});
const selectedInfo = ref('')
const showMobileInfo = ref(false);
const toggleRelatedArticleFlyOut = () => useState('toggleRelatedArticle', () => false)
const relatedInfosOpened = toggleRelatedArticleFlyOut()
let relatedArticleId = ''
let activatedInputs = [];
let isInArray = false;
const emit = defineEmits(["checkboxCollection"]);

function openMobiletext(id: string) {
	if (showMobileInfo.value) {
		selectedInfo.value = ''
		showMobileInfo.value = false
	} else {
		selectedInfo.value = id
		showMobileInfo.value = true
	}
}

function changeInputState(ob: Object, event: any) {
	if (props?.showInput) {
		if (activatedInputs.includes(ob)) {
			emit("checkboxCollection", { key: ob, value: false });
			isInArray = false;
			activatedInputs = []
			document.getElementById(ob?.id).checked = false
		} else {
			document.getElementById(ob?.id).checked = true
			activatedInputs.push(ob);
			isInArray = true;
			emit("checkboxCollection", { key: ob, value: true });
		}
	}
}

function openRelatedInformations(id: string) {
	relatedInfosOpened.value = true
	relatedArticleId = id
}
function closeRelatedInformation() {
	relatedInfosOpened.value = false
	relatedArticleId = ''
}
function priceIsDecimal(price) {
	price = Number(price)
	if (Number.isInteger(price)) {
		return false
	} else {
		return true
	}
}
</script>
<style>
.relatedArticles {
	border-Bottom: 1px solid #e7e7e7;
	padding-bottom: 0.5em;
	padding-top: 0.5em;
}

.inforelatedArticles {
	margin-right: 15px;
	width: 20px;
}</style>