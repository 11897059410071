import { isNavigationFailure, NavigationFailureType } from "vue-router";
import version from '~/package.json'

export default defineNuxtPlugin((nuxtApp) => {
  const projectVersion = version?.version
  const nuxtVersion = version.devDependencies.nuxt

  useRouter().afterEach((to, from, failure) => {
    // Any kind of navigation failure
    if (isNavigationFailure(failure)) {
      console.warn("[vue-router] got navigationFailure", { failure: failure });
    }
    // Reload Nuxt App Only duplicated navigations (this "fixes" uncought type errors, causing navigation to fail)
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      console.warn("[vue-router] got duplicated navigationFailure ", { failure: failure });
      try {
        if (!useAppConfig()?.isAppUser)
          reloadNuxtApp();
      } catch (error) {
      }
      // ...
    }
    // Aborted or canceled navigations
    if (isNavigationFailure(failure, NavigationFailureType.aborted | NavigationFailureType.canceled)) {
      console.warn("[vue-router] got aborted navigationFailure ", { failure: failure });
      // ...
    }

    onNuxtReady(() => {
      gtmDataContainer().value.ecommerce.impressions = [];

      useNuxtApp()?.$pwa?.getSWRegistration()?.active?.postMessage('toast:navigateTo'+to.fullPath);
    })

    if (!to?.path?.endsWith('html') && !to?.path?.includes('checkout')) {
      nuxtApp?.$emos3?.send({
        'herkunftsland': 'Nuxt',
        'siteid': useCurrentStoreCompanyGroup(), // Gesellschafter ID
        'countryid': useCurrentStore()?.value?.storeId,
        'langid': 'ES-5386-Cart=' + useFeatureState('ES-5386-Cart')?.value,
        'content': to?.path,
        'url': [[to?.fullPath]],
        'pageId': nuxtVersion + '_' + projectVersion + '_' + to?.name
      })
      if (useGtm()?.enabled() == true) {
        useGtm()?.dataLayer()?.push({ ecommerce: null });
        useGtm()?.trackEvent({ event: "reload", ecommerce: gtmDataContainer()?.value?.ecommerce })
      }
    }
  });
});
