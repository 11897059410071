import { defineRule } from 'vee-validate';
import { isValidPhoneNumber } from 'libphonenumber-js'
export default defineNuxtPlugin((nuxtApp) => {
/**
 * Most of these are COPYPASTA(https://briankoberlein.com/blog/copy-pasta/copypasta.jpg) 
 * from https://vee-validate.logaretm.com/v4/guide/global-validators/
 */
    defineRule('required', (value: string | any[]) => {
        if (!value || !value.length) {
            return 'Bitte geben Sie einen Wert ein';
        }
        return true;
    });
    defineRule('requiredCheckbox', (value: Boolean | any[]) => {
        if (value !== true) {
            return 'Bitte akzeptieren';
        }
        return true;
    });
    defineRule('email', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if email
        if (!(value.match(/^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,63}$/))) {
            return 'Bitte geben Sie eine gültige E-Mail Adresse ein';
        }
        return true;
    });
    defineRule('phone', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if email
        if(!isValidPhoneNumber(value, "DE")) return 'Bitte geben Sie eine gültige Telefonnummer ein';
        return true;
    });
    defineRule('number', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if zip
        if (!(value.match(/^\d*$/))) {
            return 'Es sind nur Zahlen erlaubt';
        }
        return true;
    });
    defineRule('zip', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if zip
        if (!(value.match(/\b\d{5}\b/))) {
            return 'Die Postleitzahl ist ungültig';
        }
        return true;
    });
    defineRule('houseNumber', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if zip
        if (!(value.match(/^[0-9]{1,}[a-zA-Z0-9\/,_.\-]*$/))) {
            return 'Die Hausnummer ist ungültig';
        }
        return true;
    });
    defineRule('city', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if zip
        if (!(value.match(/^[a-zA-ZäÄöÖüÜß][a-zA-ZäÄöÖüÜß. ()\\-]*$/))) {
            return 'Die Stadt ist ungültig';
        }
        return true;
    });
    defineRule('street', (value: string) => {
        // Field is empty, should pass
        if (!value || !value.length) {
            return true;
        }
        // Check if zip
        if (!(value.match(/^[a-zA-ZäÄöÖüÜß][a-zA-ZäÄöÖüÜß0-9-_()\\. ]*$/))) {
            return 'Die Straße ist ungültig';
        }
        return true;
    });
    defineRule('min', (value: string, [limit]: any) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
          return true;
        }
        if (value.length < limit) {
          return `Bitte geben Sie mindestens ${limit} Zeichen ein`;
        }
        return true;
    });
    defineRule('max', (value: string, [limit]: any) => {
        if (!value || !value.length) {
          return true;
        }
        if (value.length > limit) {
          return `Bitte geben Sie maximal ${limit} Zeichen ein`;
        }
        return true;
    });
    defineRule('isPackstation', (value: string) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        const lowerCaseValue = value.toLowerCase();
        const packstationSynonyms = ["packstation", "paketshop", "postfachanschrift", "postfach", "poststation"];
        for (const synonym of packstationSynonyms) {
            if (lowerCaseValue.includes(synonym)) {
                return `Ausgeschlossen: Postfachanschriften und Packstationen.`;
            }
        }
        return true;
    });
    defineRule('salutation', (value: string) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        switch (value) {
            case 'MR':
            case 'MS':
            case 'DIVERSE':
            case 'COMPANY':
            case 'FAMILY':
                return true
            default:
                return `Bitte wählen Sie einen Eintrag aus der Liste`;
        }
    });
    defineRule('password', (value: string) => {
        // The field is empty so it should pass
        if (!value || !value.length) {
            return true;
        }
        if (value.length < 12) {
            return `Bitte geben Sie mindestens 12 Zeichen ein.`;
        }
        if(!value.match(/\W/) && !value.match(/[0-9]/)) {
            return `Bitte verwenden Sie nicht nur Buchstaben.`;
        }
        if(!value.match(/[A-Z,ÄÜÖ]/) || !value.match(/[a-z,äüö]/)) {
            return `Bitte verwenden Sie Groß- und Kleinbuchstaben.`;
        }
        if(!value.match(/\W+/)) {
            return `Bitte verwenden Sie Sonderzeichen.`;
        }
        if(value.match(/123/)) {
            return `Bitte verwenden Sie keine einfachen Muster oder aufeinanderfolgende Zeichen.`;
        }
        
        return true
    });
    defineRule('equal', (value: string, [same, name]: any) => {
        if (!value || !value.length) {
          return true;
        }
        if (value != same) {
          return `Bitte geben Sie den gleichen Wert ein, wie in dem Feld ${name}`;
        }
        return true;
    });
})