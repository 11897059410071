<template>
  <template v-if="data !== undefined && data !== null">
  <div class="text-xs text-paragraph-light mt-4">
    <BasicIcon :name="'house-chimney-solid'" class="inline-block w-3 mr-1" />
    <span style="font-size: 11px;"> wird geliefert von: expert {{ data?.city }}</span>
  </div>
  </template>
  <div v-if="pending" class="text-xs text-paragraph-light mt-4">
    <BasicIcon :name="'magnifying-glass-solid'" class="inline-block w-3 mr-1" />
    <span style="font-size: 11px;"> Suche nach Fachmarkt... </span>
  </div>
</template>
<script setup lang="ts">

const props = defineProps({
  storeId: {
    type: String,
    required: true
  }
});


const { pending, data } = await useFetch(useRuntimeConfig().public.apiUrl + '/api/neo/user-service/getInstitution', {
  method: "POST",
  key: 'localStore-' + props?.storeId,
  body: {
    institutionType: 'de.expert.services.storeImport.api.Store',
    institutionId: 'e_' + props?.storeId
  },
  server:false,
  pick: ["city"]
});

</script>
