<template>
  <div class="energyEfficiency cursor-pointer">
    <BasicBtErrorBoundary> 
      <div class="cheeseAndOnions" v-if="!energyMap.get('newScale')">
        <div class="letterContainer" @click.prevent="enableEnergyLabel = true"
          v-if="energyMap.get('ColorPalette') !== undefined" :style="'background-color:' +
            energyMap.get('ColorPalette') +
            ';color:' +
            energyMap.get('ColorPalette')
            ">
          <div class="letter">{{ energyMap.get("Letter") }}</div>
        </div>
        <div class="letterRange">
          {{ energyMap.get("LetterRange") }}
        </div>
        <div class="listDocuments" v-if="showDataFile && energyEfficiency?.get('DataFileId') != undefined">
            <div class='entry' @click.prevent='openInNewTab()'>
              Produktdatenblatt
            </div>
        </div>
      </div>
      <div v-else class="saltAndVinegar">
        <NuxtImg densities="x1" @click.prevent="enableEnergyLabel = true" v-if="energyMap?.get('Letter')" :alt="'Energy Effizienz ' +
          energyMap.get('Letter') + ' (' + energyMap.get('LetterRange') + ')'" 
          :style="labelStyles" :src="'/static/images/icons/efficiencyRating/range/energielabel_' +
            energyMap.get('Letter')?.toString()?.toLowerCase() + '.svg'">
        </NuxtImg>
          <div style="clear: both;" class="listDocuments" v-if="showDataFile && energyEfficiency?.get('DataFileId') != undefined">
              <div class='entry text-paragraph' @click.prevent='openInNewTab()'>
                Produktdatenblatt
              </div>
          </div>
      </div>
      <!--/div-->
      <Teleport to="body">
        <div v-if="enableEnergyLabel">
          <Modal @close-modal="enableEnergyLabel = false">
            <template #icon>
              <BasicIcon :name="'xmark-solid'" class="w-4 close-img"></BasicIcon>
            </template>
            <div class="p-4" v-if="getFullImage()?.originalData">
              <img :alt="'Energieeffizienz: ' + energyMap.get('Letter')?.toString()" class="mx-auto lg:max-h-[80vh]"
                :src="'https://cdn.expert.de/' + getFullImage()?.originalData" />
            </div>
          </Modal>
        </div>
      </Teleport>
    </BasicBtErrorBoundary>
  </div>
</template>
<script setup lang="ts">
import { getMedium } from "~/composables/ExpApi/expCache";
// import { IExpArticle } from "~/models/ExpArticle/exp_Article";
const props = defineProps({
  article: {
    type: Object,
    required: true,
  },
  showDataFile: {
    type: Boolean,
    default: true
  },
  labelStyles: {
    type: String,
    default: 'max-height:58px;min-height: 58px; float: right;'
  }
});

const showEEL = ref(false);
const enableEnergyLabel = ref(false);

const energyMap = ref(new Map());
let list = props?.article?.technicalData;
const energyEfficiency = new Map();
for (let a = 0; a < list.length; a++) {
  if (list[a].key === "59ba44f5a7b11b000152d48a") {
    if (list[a].value.value !== null && list[a].value.value !== "") {
      energyEfficiency.set("DataFileId", list[a].value.value);
    }
  }
  if (list[a].key === "59ba44f5a7b11b000152d4d2") {
    if (list[a].value.value !== null && list[a].value.value !== "") {
      energyEfficiency.set("ImageId", list[a].value.value);
    }
  }
  if (list[a].key === "59ba44f5a7b11b000152d466") {
    if (list[a].value.value !== null && list[a].value.value !== "") {
      let array = list[a].value.value.substring(1);
      let hexColor = array.replace("x", "#");
      energyEfficiency.set("ColorPalette", hexColor);
    }
  }
  if (list[a].key === "60501133ef918200012ab3f8") {
    if (list[a].value.value !== null && list[a].value.value !== "") {
      energyEfficiency.set("LetterRange", list[a].value.value);
    }
  }
  if (
    list[a].key === "59ba44f5a7b11b000152d1c3" ||
    list[a].key === "59ba44f5a7b11b000152d065"
  ) {
    if (list[a].value.value !== null && list[a].value.value !== "") {
      energyEfficiency.set("Letter", list[a].value.value);
    }
  }
}

if(energyEfficiency.get("Letter")?.toString().includes("+") || energyEfficiency.get("LetterRange")?.toString().includes("+")) {
  energyEfficiency.set("newScale", false);
} else {
  energyEfficiency.set("newScale", true);
}

function getFullImage() {
  if (!energyMap.value.get('FullImage')) {
    const { data: result } = getMedium(energyEfficiency.get("ImageId"));
    energyEfficiency.set("FullImage", result.value);
  }
  return energyMap.value.get('FullImage');
}

async function getDataFile() {
  if (!energyMap.value.get('DataFile')) {
    const { data: result } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/medium/" + energyEfficiency.get("DataFileId"), {
      key: "medium/id=" + energyEfficiency.get("DataFileId"),
    });
    energyEfficiency.set("DataFile", unref(result.value));
    energyMap.value.set("DataFile", unref(result.value));
  }
  return energyMap.value.get('DataFile');
}

async function openInNewTab () {+
  Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: 'https://cdn.expert.de/' + (await getDataFile())?.originalData,
  }).click()
}

energyMap.value = energyEfficiency;
</script>