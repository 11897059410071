<template>
    <div>
        <template v-if="useinternburgericon">
            <button
                class="block border-0 bg-transparent py-2 px-2.5 text-neutral-500 hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200 lg:hidden"
                type="button"
                @click="
                    showMenu = !showMenu;
                openDropdown = '0';
                ">
                <span class="[&>svg]:w-7">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-7 w-7">
                        <path fill-rule="evenodd"
                            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
                            clip-rule="evenodd" />
                    </svg>
                </span></button></template>
        <nav id="navbar-1" class="main-navbar !visible lg:flex-grow basis-[100%] items-center xl:!flex xl:basis-auto"
            :class="navbarclass + (showMenu ? ' mobile' : ' hidden')">
            <div v-if="!tree">No NavigationTree</div>
            <ul v-if="tree" class="navbar-nav list-style-none flex flex-col pl-0 lg:flex-row justify-between"
                :class="navbarnavclass">
                <li v-for="category in tree" :key="category.id" :data-sort="category.position" class="nav-item"
                    :class="showMenu ? '' : 'group transition duration-150 ease-in-out'">
                    <NuxtLink :external="external" :to="basedir + category.url" :title="category.name"
                        :id="'dropdownMenu_' + category.id" target="_self"
                        class="nav-link dropdown whitespace-nowrap flex items-center justify-between"
                        :class="navlinkdropdownclass" @mouseover="showOnHover = true">{{ category.name }}
                        <span v-if="category.descendants?.[0]" class="lg:ml-1 duration-300 ease-in-out" :class="openDropdown == category.id ? 'rotate-90' : ''
                            " @click.prevent="
        openDropdown == category.id
            ? (openDropdown = '0', openSubDropdown = '0')
            : (openDropdown = category.id)
        ">
                            <template v-if="showdesktopdropdownicon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                    class="h-5 w-5 hidden lg:block">
                                    <path fill-rule="evenodd"
                                        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                        clip-rule="evenodd" />
                                </svg>
                            </template>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                class="w-6 h-6 lg:hidden">
                                <path fill-rule="evenodd"
                                    d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                    </NuxtLink>
                    <div :id="'dropdownContainer_' + category.id" v-if="category.descendants?.[0]"
                        class="dropdown-container absolute w-full transition translate-y-0 invisible duration-500 ease-in-out group-hover:transform transform shadow-lg "
                        :class="dropdowncontainerclass + (openDropdown == category.id
                            ? ' !visible opacity-1'
                            : ' opacity-0 hidden xl:block') + (showOnHover ? ' group-hover:opacity-100 group-hover:visible' : '')
                            ">
                        <div class="flex flex-col lg:flex-row">
                            <div class="grid xl:grid-cols-4">
                                <ul class="dropdown-nav" v-for="childcategory in category.descendants"
                                    :key="childcategory.id">
                                    <!-- PARENT CATEGORY -->
                                    <li class="nav-item parent" :id="childcategory.id" :data-sort="0">
                                        <NuxtLink :external="external" :to="basedir +
                                            category.url +
                                            '/' +
                                            childcategory.url
                                            " :title="childcategory.name" target="_self"
                                            class="nav-link dropdown flex items-center justify-between">
                                            {{ childcategory.name }}
                                            <span v-if="childcategory.descendants?.[0]"
                                                class="w-5 lg:ml-2 lg:hidden duration-300 ease-in-out" :class="openSubDropdown == childcategory.id
                                                        ? 'rotate-90'
                                                        : ''
                                                    " @click.prevent="
        openSubDropdown == childcategory.id
            ? (openSubDropdown = '0')
            : (openSubDropdown = childcategory.id)
        ">
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                    fill="currentColor" class="w-6 h-6">
                                                    <path fill-rule="evenodd"
                                                        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
                                                        clip-rule="evenodd" />
                                                </svg>
                                            </span>
                                        </NuxtLink>
                                        <!-- CHILD CATEGORYS -->
                                        <ul class="dropdown-menue lg:block" :id="childcategory.id" :class="openSubDropdown == childcategory.id
                                                ? 'block'
                                                : 'hidden'
                                            ">
                                            <template
                                                v-for="(
                                                                                                    childcategoryitem, itemkey
                                                                                                ) in childcategory.descendants">
                                                <li v-if="itemkey <= limit" :key="childcategoryitem.id" class="nav-item">
                                                    <NuxtLink :external="external" v-if="itemkey < limit" :to="basedir +
                                                        category.url +
                                                        '/' +
                                                        childcategory.url +
                                                        '/' +
                                                        childcategoryitem.url
                                                        " :title="childcategoryitem.name" target="_self" class="nav-link">
                                                        {{ childcategoryitem.name }}
                                                    </NuxtLink>
                                                    <NuxtLink :external="external" v-else :key="childcategoryitem.id" :to="basedir +
                                                        category.url +
                                                        '/' +
                                                        childcategory.url
                                                        " :title="moretitle" target="_self" class="nav-link"
                                                        :class="morenavitemclass">{{ moretitle }}
                                                    </NuxtLink>
                                                </li>
                                            </template>
                                        </ul>
                                    </li>
                                </ul>
                                <!--/div-->
                            </div>
                            <div class="banner-container basis-1/3 hidden lg:block">
                                <template v-if="multiplebanner">
                                    <slot :name="'menuBannerContent_' + category.position" />
                                </template>
                                <template v-else>
                                    <slot :name="'menuBannerContent'" />
                                </template>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</template>
<script setup lang="ts">
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useMobileMenuState,useSuperMenuState } from "~/composables/states";
const props = defineProps({
    tree: {
        type: Array,
    },
    /** Limit of Child ELEM */
    limit: {
        type: Number,
        required: true,
    },
    moretitle: {
        type: String,
        required: true,
    },
    useinternburgericon: {
        type: Boolean,
        default: true,
    },
    showdesktopdropdownicon: {
        type: Boolean,
        default: true,
    },
    navbarclass: {
        type: String,
        default: '',
    },
    navbarnavclass: String,
    navlinkdropdownclass: {
        type: String,
        default: '',
    },
    morenavitemclass: String,
    dropdowncontainerclass: String,
    bannercontainerclass: String,
    multiplebanner: {
        type: Boolean,
        default: false,
    },
    basedir: {
        type: String,
        default: '/',
    },
    external: {
        type: Boolean,
        default: false
    },
    watchRoute: {
        type: Boolean,
        default: false
    }
})
const showMenu = useMobileMenuState()
const showOnHover = ref(false)

const openDropdown = ref('0');
const openSubDropdown = ref('0');

if (props?.watchRoute === true)
    watch(
        (() => useRoute()),
        (value) => {
            showMenu.value = false;
            showOnHover.value = false;
        },
        { deep: true, immediate: true }
    );
const checkRoute = useRoute()
const showSuperMenu = useSuperMenuState();
watch(checkRoute, () => {
    console.log("MegaMenu useRoute 1: ", showMenu.value, "; ", showOnHover.value, ";  ", showSuperMenu.value, "  ;  ", openDropdown.value)
    showMenu.value = false;
    showOnHover.value = false;
    showSuperMenu.value = false
    openDropdown.value = "0"
    console.log("MegaMenu useRoute 2: ", showMenu.value, "; ", showOnHover.value, ";  ", showSuperMenu.value, "  ;  ", openDropdown.value)
})

</script>
