<template>
    <nav
        class="super-navbar !visible lg:flex-grow basis-[100%] items-center xl:!flex xl:basis-auto text-paragraph"
        :class="showSuperMenu ? ' mobile' : ' hidden'"
    >
        <ul id="superNav"
            class="super-navbar-nav list-style-none flex flex-col pl-0 lg:flex-row justify-between"
        >
            <li
                class="nav-item"
            >
            <NuxtLink :external="external" :to="'/'" 
                class="nav-link whitespace-nowrap flex items-center lg:cursor-default justify-between px-2 py-4 flyout-indicator relative indicator-primary" 
                title="Unsere Produkte" >
                <template v-if="showSuperMenu">
                    <div class="flex-initial w-full" 
                    @click.prevent="$emit('showmegamenu'); openSuperDropdown == '1' ? (openSuperDropdown = '0'): (openSuperDropdown = '1')">Unsere Produkte</div>
                </template>
                <template v-else>Unsere Produkte</template>
                <span
                    class="lg:ml-1 px-4 lg:px-0 duration-300 ease-in-out lg:hidden"
                    @click.prevent="$emit('showmegamenu'); openSuperDropdown == '1' ? (openSuperDropdown = '0'): (openSuperDropdown = '1')"
                    :class="openSuperDropdown == '1' ? 'rotate-180 text-primary' : ''"
                    ><BasicIcon :name="'chevron-down'" iconclass="w-6 h-6" />
                </span>
            </NuxtLink>
              <slot name="megamenu"/>
            </li>
            <li v-for="superCategory in supercategorytree" class="nav-item" :class="'group'"
                :key="superCategory?.id || superCategory?.extId">
                <NuxtLink :external="external"
                    class="nav-link whitespace-nowrap flex items-center justify-between px-2 py-4"
                    :class="superCategory?.descendants?.length > 0 ? ' dropdown' : ''" target="_self"
                    :to="superCategory?.url" :title="superCategory?.alt" :id="'dropdownMenu_' + superCategory?.id">
                    {{ superCategory?.name }}
                    <span v-if="superCategory?.descendants?.length > 0"
                        class="w-5 lg:ml-2 lg:hidden duration-300 ease-in-out"
                        :class="openSuperDropdown == superCategory?.id ? 'rotate-90' : ''"
                        @click.prevent="openSuperDropdown == superCategory?.id ? (openSuperDropdown = '0') : (openSuperDropdown = superCategory?.id)">
                        <BasicIcon :name="'chevron-right'" class="w-6 h-6 lg:hidden" />
                    </span>
                </NuxtLink>
                <template v-if="superCategory?.descendants?.length > 0">
                    <div class="dropdown-container lg:container lg:mx-auto absolute transition invisible duration-500 ease-in-out group-hover:transform transform right-0 left-0 group-hover:opacity-100 group-hover:visible _z-10"
                        :id="'dropdownContainer_' + superCategory?.id"
                        :class="openSuperDropdown == superCategory?.id ? ' !visible opacity-1' : ' opacity-0 hidden xl:block'">
                        <ul class="navbar-nav list-style-none flex flex-col pl-0 lg:flex-row justify-between">
                            <template v-for="superChild in superCategory?.descendants"
                                :key="superChild.id || superChild?.extId">
                                <li class="nav-item">
                                    <NuxtLink :external="external" class="nav-link px-2 py-4 lg:px-3 lg:py-5" target="_self"
                                        :to="superChild.url" :title="superChild.name">{{ superChild.name }}</NuxtLink>
                                </li>
                            </template>
                        </ul>
                    </div>
                </template>
            </li>
        </ul>
    </nav>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { useSuperMenuState } from "~/composables/states";
import { useSuperMenuDropdownState } from "~/composables/states";
const props = defineProps({
    supercategorytree: {
        type: Array,
    },
    basedir: {
        type: String,
        required: true,
        default: '/',
    },
    external: {
        type: Boolean,
        default: false
    },
    showdesktopdropdownicon: {
        type: Boolean,
        default: true,
    },
});

const openSuperDropdown = useSuperMenuDropdownState();
const showSuperMenu = useSuperMenuState();

</script>