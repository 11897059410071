export interface ExpArticleAdvisor {
    id: string;
    name: string;
    brand: string;
    token: string;
    categories: string[];
    active: boolean;
    overlay: boolean;
    displayDurations: DisplayDuration[];
    createdAt: string;
    updatedAt: string;
}

export interface DisplayDuration {
    dayFrom: string;
    dayTo: string;
    comment: string;
    timeFrom: number;
    timeTo: number;
}

export enum WeekDay {
    SUNNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}