<template>
  <header id="1" class="header z-20 relative lg:mb-[3.75rem]">
    <div class="lg:pt-24 text-sm flex lg:block px-4 lg:px-0">
      <div id="2" class="sticky-header grow">
        <div
          class="flex flex-col lg:flex-row lg:flex-wrap lg:container lg:mx-auto lg:items-center lg:h-full lg:justify-between gap-4">
          <NavigationStoreLogo class="py-3" />
          <div class="lg:order-last relative items-center lg:grow flex gap-4">
            <div class="mobile-menu-trigger grow lg:hidden">
              <button class="block border-0 bg-transparent lg:px-2 text-brand_primary lg:hidden" type="button"
                title="Menue" @click="
                  showSuperMenu = !showSuperMenu;
                showMenu = false;
                openDropdown = '0';
                openSubDropdown = '0';
                ">
                <span class="[&>svg]:w-6">
                  <BasicIcon :name="'menu'" :iconclass="'w-6 h-6'" />
                </span>
              </button>
            </div>

            <div v-if="($device.isMobile || $device.isTablet) && !$device.isLighthouse">
              <div class="flex items-center gap-4">
                <div class="relative">
                  <div class="lg:border-r lg:border-r-secondary-dark flex gap-3 lg:px-4 lg:py-2 mb-1 lg:my-2 hover:text-primary cursor-pointer items-center">
                    <NuxtLink :to="'/shop/wishlist'">
                      <BasicIcon class="w-6 h-6 text-primary cursor-pointer align-bottom" :name="'heart-shape'" />
                      <template v-if="useFeatureState('ES-5404-Counter')?.value == true">{{ wishListCounter }}</template>
                    </NuxtLink>
                  </div>
                </div>
                <div class="relative">
                  <div
                    class="UserAccountButton lg:border-r lg:border-r-secondary-dark flex gap-3 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
                    :class="showUserAccountDetails && !($device.isMobile || $device.isTablet)
                      ? 'flyout-indicator'
                      : ''
                      " @click="toggleUserAccountPopUp">
                    <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'"
                      title="Mein Konto" :external="true">
                      <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
                    </NuxtLink>
                    <template v-else>
                      <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
                    </template>
                  </div>
                  <FlyOutsMyAccountFlyout v-if="showUserAccountDetails"
                    @changeFlyOutState="changeUserAccountFlyOutState($event)" :userAccount="userAccount" />
                </div>
                <div class="lg:relative">
                  <div class="LocalStoreButton flex gap-2 lg:px-4 hover:text-primary cursor-pointer items-center"
                    @click="toggleLocalStorePopUp" v-if="!showLocalStoreDetails">
                    <div class="grow text-base whitespace-nowrap">
                      <BasicIcon :name="'location-dot-solid'" class="w-4 inline-block text-primary" />
                      <div class="hidden lg:inline-block">Mein Fachmarkt</div>
                    </div>
                  </div>
                  <div
                    class="LocalStoreButton flex gap-2 lg:px-4 hover:text-primary cursor-pointer items-center flyout-indicator"
                    v-if="showLocalStoreDetails">
                    <div class="grow text-base whitespace-nowrap">
                      <BasicIcon :name="'location-dot-solid'" class="w-4 inline-block text-primary" />
                      <div class="hidden lg:inline-block">Mein Fachmarkt</div>
                    </div>
                  </div>

                  <div v-if="showLocalStoreDetails">
                    <FlyOutsLocalStoreFinderFlyout @changeFlyOutState="toggleLocalStorePopUp($event)" />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="relative lg:btn-primary lg:order-last lg:text-primary-contrast text-primary cursor-pointer lg:flex gap-4 p-0 lg:p-4 font-normal lg:items-center"
              v-if="!showShoppingCartDetails" @click="toggleShoppingCartPopUp">
              <BasicIcon class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" :name="'cart-shopping-solid'" />
              <div class="hidden lg:block">Warenkorb</div>
              <div
                class="shoppingcart-counter text-primary-contrast absolute text-xs lg:text-base bottom-full lg:bottom-auto lg:left-auto bg-black lg:bg-primary-dark lg:relative rounded-full text-center block">
                {{ shoppingCartCount + reservationCartCount }}
              </div>
            </div>
            <div
              class="relative lg:btn-primary lg:order-last lg:text-primary-contrast text-primary cursor-pointer lg:flex gap-4 p-0 lg:p-4 font-normal lg:items-center"
              v-if="showShoppingCartDetails">
              <BasicIcon class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" :name="'cart-shopping-solid'" />
              <div class="hidden lg:block">Warenkorb</div>
              <div
                class="shoppingcart-counter text-primary-contrast absolute text-xs lg:text-base bottom-full lg:bottom-auto lg:left-auto bg-black lg:bg-primary-dark lg:relative rounded-full text-center block">
                {{ shoppingCartCount + reservationCartCount }}
              </div>
            </div>
            <BasicBtErrorBoundary>
              <FlyOutsShoppingCartFlyout v-if="showShoppingCartDetails"
                @changeFlyOutState="changeShoppingCartFlyOutState($event)" :shoppingCart="shoppingCart" />
            </BasicBtErrorBoundary>
            <template v-if="!($device.isMobile || $device.isTablet) && !$device.isLighthouse">
              <div class="grow">
                <form @submit.prevent="handleSearch(suchWort)">
                  <div id="searchbox">
                    <input
                      id="termsuggest-input"
                      type="search"
                      name="q"
                      v-model="suchWort"
                      aria-autocomplete="both"
                      enterkeyhint="search"
                      aria-haspopup="false"
                      autocapitalize="off"
                      autocomplete="off"
                      autocorrect="off"
                      autofocus=""
                      @keydown="(event) => handleSearchKeyInput(event)"
                      @input="useSuggestionsOpenState().value = true"
                      placeholder=" Suchen nach Produkten, Marken, Web-Code & mehr..." />
                    <button class="px-4 border-l" @click="handleSearch(suchWort)">
                      <BasicIcon :name="'magnifying-glass-solid'" class="inline-block w-5 text-primary" />
                    </button>
                  </div>
                </form>
                <div id="termsuggest" class="relative"></div>
              </div>
            </template>
          </div>
          <template v-if="($device.isMobile || $device.isTablet) && !$device.isLighthouse">
            <div class="grow" v-if="!openSearchBarMobile">
              <form @submit.prevent="handleSearch(suchWort)">
                <div id="searchbox">
                  <input id="termsuggest-input" type="search" name="q" v-model="suchWort"
                    v-on:keyup.enter="handleSearch(suchWort)" @input="useSuggestionsOpenState().value = true"
                    enterkeyhint="search" placeholder="Suchen nach Produkten, Marken, Web-Code & mehr..."
                    style="width: 90%" />
                  <button @click="handleSearch(suchWort)">
                    <BasicIcon :name="'magnifying-glass-solid'" class="inline-block w-5 text-primary" />
                  </button>
                  <div id="termsuggest"></div>
                </div>
              </form>
            </div>
          </template>
        </div>
      </div>
    </div>
    <ClientOnly v-if="!$device?.isLighthouse">
      <Teleport to="#termsuggest">
        <div class="flex w-full absolute right-0 top-full" v-show="useSuggestionsOpenState().value" ref="suggestionsBox">
          <div class="termSuggestions grid border-t-white absolute w-full bg-white py-4 border-white shadow"
            :class="{ 'grid-cols-12': termSuggestions?.termSuggest?.length > 0 }" v-if="termSuggestions?.termSuggest?.length > 0">
            <div v-if="termSuggestions?.termSuggest?.length > 0" class="col-span-12 lg:col-span-5"
              @keydown="(event) => handleSearchKeyInput(event)">
              <template v-for="(suggestion, index) in termSuggestions?.termSuggest" v-bind:key="suggestion.label">
                <div class="suggestionBlock" @mouseover="setSuggestionCursor(index as number)">
                  <button class="hover:bg-secondary px-4 py-2 w-11/12 text-left" v-html="suggestion.highlighting"
                    @click="editSearch(suggestion.label, 'Suchvorschlag')" />
                  <div class="arrow-icon">
                    <BasicIcon class="w-4 h-4 text-primary" :name="'arrow-up-left-solid'"
                      @click="editSearch(suggestion.label, 'Suchvorschlag')"></BasicIcon>
                  </div>
                </div>
              </template>
            </div>
            <div v-if="articleSuggestions?.articleSuggest?.length > 0" class="hidden lg:block px-4 border-l"
              :class="articleSuggestions?.articleSuggest?.length > 0 ? 'col-span-7' : ''">
              <template v-for="article in articleSuggestions?.articleSuggest" v-bind:key="article.article.articleId">
                <NuxtLink :to="{
                  path: baseDir + article.article.slug,
                  query: {
                    suggest: 'true',
                    suggestType: 'Artikel',
                    sq: suchWort,
                  },
                }" class="border-t first:border-t-0 grid grid-cols-7 grid-rows-2 py-2 gap-1"
                  @click="useMarker('SearchClickArticleSuggest'); useSuggestionsOpenState().value = false;">
                  <div class="col-start-1 col-span-2 row-start-1  row-span-2">
                    <div class="relative">
                      <div class="absolute top-0 left-0 w-8">
                        <PromotionBubble :articleId="article.article.articleId" :price="article.price" />
                      </div>
                      <div class="absolute bottom-0 left-0 w-8">
                        <PromotionBubble :articleId="article.article.articleId" :price="article.price" :priority="1" />
                      </div>
                      <ArticleExpMedium class="max-h-20 mx-auto" :mediumId="article.article.primaryImage"
                        preset="preview" />
                    </div>
                  </div>
                  <ArticleEnergyEfficiency class="col-start-1 col-span-2 row-start-2 row-span-2 text-xs"
                                           :article="article?.article"
                                           :labelStyles="'max-height:28px;min-height: 28px; float: right;'" />
                  <div class="col-span-3 row-span-2">
                    <div class="title text-sm font-bold pb-2">
                      {{ article.article.title }}
                    </div>
                    <div class="articlestatus text-xs">
                      <ArticleExpArticleState :showButtons="false" :price="article.price"
                        :key="useCurrentStore()?.value?.id" />
                    </div>
                  </div>
                  <ArticleExpPrice :localarticle="article.article" :showFormerPrice="false" class="text-right col-span-2 row-span-2"
                    :enableUVPLabel="true" :exhibitInfo="false" :enableUVPFlag="false" :enableUVPFlyOut="false"
                    :formerPriceClass="'flex flex-col items-end gap-2'" :showShipmentInformation="false"
                    :displayPriceDesignClasses="'text-2xl'" />
                </NuxtLink>
              </template>
              <NuxtLink class="block btn-primary w-full text-center font-bold text-sm mt-4" :to="{
                path: '/shop/suche',
                query: {
                  q: suchWort,
                  suggest: 'true',
                  suggestType: 'Alle Suchergebnisse',
                  sq: suchWort,
                },
              }" @click="useMarker('SearchClickAllSearchResults')" :external="false">Alle Suchergebnisse anzeigen
              </NuxtLink>
            </div>
          </div>
        </div>
      </Teleport>
    </ClientOnly>

    <div class="lg:container lg:mx-auto lg:flex lg:justify-between">
      <NavigationSuperMenu @showmegamenu="showMenu = !showMenu" :supercategorytree="megaTree"
        :showSuperMenu="showSuperMenu" :openSuperDropdown="false" :showdesktopdropdownicon="false" :basedir="baseDir"
        :external="true">
        <template #megamenu>
          <div class="shop-nav left-0 right-0 absolute">
            <NavigationMegaMenuComponent v-if="navigationTree != undefined && !$device.isLighthouse"
              :tree="navigationTree" :class="'megamenu-container lg:container lg:mx-auto z-20'" :navbarnavclass="'w-full'"
              :navlinkdropdownclass="'py-4 lg:py-5 px-2'" :limit="4" :basedir="'' + baseDir" :external="false"
              :moretitle="'mehr'" :morenavitemclass="'more'" :useinternburgericon="false"
              :dropdowncontainerclass="'left-0 right-0 mt-0 lg:top-7 group-hover:translate-y-8 p-4 z-10'"
              :showMenu="showMenu" :openDropdown="openDropdown" :openSubDropdown="openSubDropdown" :multiplebanner="false"
              :showdesktopdropdownicon="false">
              <!--  :style="'background-image: url(https://cdn.expert.de/cdn-cgi/image/format=auto'+dotw?.backgroundImageSource+');background-size: cover;background-position: center;'" -->
              <template #menuBannerContent v-if="!dotwPending && dotw?.type == 'default' && dotw?.url !== undefined">
                <aside
                  class="menue-banner text-paragraph p-4 bg-gradient-to-b from-[#ef7203] via-[#f7a501] to-[#ef7203]">
                  <NuxtLink :to="dotw?.url" :external="!dotw?.url?.includes('.html')" :title="dotw?.name">
                    <NuxtImg class="" :alt="dotw?.name" :src="dotw?.imageSource" preset="preview" />
                    <div class="text-base py-2">{{ dotw?.name }}</div>
                    <div class="text-lg">{{ dotw?.description }}</div>
                  </NuxtLink>
                </aside>
              </template>
            </NavigationMegaMenuComponent>
          </div>
        </template>
      </NavigationSuperMenu>
      <div class="flex items-center" v-if="!($device.isMobile || $device.isTablet)">
        <div class="relative">
          <div
            class="lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center">
            <NuxtLink :to="'/shop/wishlist'">
              <BasicIcon class="w-6 h-6 text-primary cursor-pointer align-bottom" :name="'heart-shape'" />
              <sub v-if="useFeatureState('ES-5404-Counter')?.value == true">{{ wishListCounter }}</sub>
            </NuxtLink>
          </div>
        </div>
        <div class="relative">
          <div
            class="UserAccountButton whitespace-nowrap lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
            @click="toggleUserAccountPopUp" v-if="!showUserAccountDetails">
            <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'" :external="true"
              title="Mein Konto">
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </NuxtLink>
            <template v-else>
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </template>
            <div class="grow text-base hidden lg:block">Mein Konto</div>
          </div>
          <div
            class="UserAccountButton flyout-indicator whitespace-nowrap lg:border-r lg:border-r-secondary-dark flex gap-3 py-4 px-2 lg:px-4 lg:py-2 lg:my-2 hover:text-primary cursor-pointer items-center"
            v-if="showUserAccountDetails">
            <NuxtLink v-if="$device.isMobile || $device.isTablet" :to="'/mein-expert/uebersicht'" :external="true"
              title="Mein Konto">
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </NuxtLink>
            <template v-else>
              <BasicIcon class="w-6 h-6 text-primary" :name="'circle-user-solid_02'" />
            </template>
            <div class="grow text-base hidden lg:block">Mein Konto</div>
          </div>
          <FlyOutsMyAccountFlyout v-if="showUserAccountDetails" @changeFlyOutState="changeUserAccountFlyOutState($event)"
            :userAccount="userAccount" />
        </div>

        <div class="relative">
          <div class="LocalStoreButton flex gap-2 lg:py-4 lg:px-4 hover:text-primary cursor-pointer items-center"
            @click="toggleLocalStorePopUp" v-if="!showLocalStoreDetails">
            <div class="grow text-base whitespace-nowrap">
              <BasicIcon :name="'location-dot-solid'" class="w-4 lg:mr-2 lg:align-text-top inline-block text-primary" />
              <div class="hidden lg:inline-block">Mein Fachmarkt</div>
            </div>
          </div>
          <div
            class="LocalStoreButton flex gap-2 lg:py-4 lg:px-4 hover:text-primary cursor-pointer items-center flyout-indicator"
            v-if="showLocalStoreDetails">
            <div class="grow text-base whitespace-nowrap">
              <BasicIcon :name="'location-dot-solid'" class="w-4 lg:mr-2 lg:align-text-top inline-block text-primary" />
              <div class="hidden lg:inline-block">Mein Fachmarkt</div>
            </div>
          </div>
          <FlyOutsLocalStoreFinderFlyout v-if="showLocalStoreDetails"
            @changeFlyOutState="toggleLocalStorePopUp($event)" />
        </div>
      </div>
    </div>
  </header>
</template>
<script setup lang="ts">
import { onClickOutside, useDebounceFn, watchThrottled } from "@vueuse/core";
import { inject } from "vue";
import { getExpNavigationTree, getDealOfTheWeek, recursiveSort } from "~/composables/ExpApi/expCache";
import { mapToExpPrice, mapToBrandAndArticle } from "~/composables/ExpApi/expSearch";
import { useSuperMenuState } from "~/composables/states";
import { useMobileMenuState } from "~/composables/states";
import {
  useShoppingCartItemsCount,
  useReservationCartItemsCount,
} from "~/composables/states";

const creds = useReactiveSearchCreds();
var suchWort = ref("");
let hasStashedSearchWord: boolean;
const stashedSearchWord = ref("");
const scrolledSuggestion = ref("");
const shoppingCartCount = useShoppingCartItemsCount();
const reservationCartCount = useReservationCartItemsCount();

// const suggestOpen = ref(true);
const suggestionsBox = ref();

onClickOutside(suggestionsBox, event => useSuggestionsOpenState().value = false);

const wishListCounter = useWishListItemCount();
const route = useRoute();

const queryForTerms = ref("");
const queryForArticles = ref("");
const { data: termSuggestions } = await useLazyFetch(useRuntimeConfig()?.public.apiUrl + "/api/search/suggests/term", {
  query: {
    q: queryForTerms,
    storeId: useStoreCookie()
  },
});
const { data: articleSuggestions } = await useLazyFetch(useRuntimeConfig()?.public.apiUrl + "/api/search/suggests/article", {
  query: {
    q: queryForArticles,
    storeId: useStoreCookie(),
    noArticles: useDevice().isMobile
  },
});

watchThrottled(
  suchWort,
  (val) => {
    console.debug("suchwort changed", suchWort);
    if(hasStashedSearchWord) {
      queryForTerms.value = stashedSearchWord.value;
    } else {
      queryForTerms.value = suchWort.value;
    }
    queryForArticles.value = suchWort.value;
  },
  { throttle: 250 }
);

function handleSearch(token) {
  if (token?.toLowerCase() == '#einruckeln') {
    useEasterEgg('einruckeln');
  } else if (token?.toLowerCase() == '#btdev') {
    useEasterEgg('btdev');
    return;
  } else if (token?.toLowerCase() == '#pride') {
    useEasterEgg('pride');
    return;
  }
  useMarker('SearchUsed');
  handleHardlinkCheck(token);
  useSuggestionsOpenState().value = false;
  document?.getElementById('termsuggest-input')?.blur()
}

const userAccount: Object = {};
const shoppingCart: Object = {};
const props = defineProps({
  baseDir: {
    default: () => {
      return inject(ProvideNames.BASE_DIR);
    },
  },
});
const openSearchBarMobile = ref(false);
function checkForClick() {
  if (openSearchBarMobile.value) {
    openSearchBarMobile.value = false;
  } else {
    openSearchBarMobile.value = true;
  }
}

const showSuperMenu = useSuperMenuState();
const showMenu = useMobileMenuState();
const openDropdown = ref("0");
const openSubDropdown = ref("0");

watch(
  route,
  (value) => {
    showSuperMenu.value = false;
    showMenu.value = false;
    // showOnHover.value = false;
  },
  {
    deep: true,
    immediate: true,
  }
);

const showShoppingCartDetails = ref(false);
function changeShoppingCartFlyOutState(e) {
  if (!showShoppingCartDetails.value) {
    showShoppingCartDetails.value = true;
  } else {
    showShoppingCartDetails.value = false;
  }
}

const { data: navigationTree } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/navigation", {
  key: 'navigationTree-MAIN',
  query: {
    storeId: computed(() => useCurrentStore()?.value?.id),
    tag: "MAIN",
  },
  transform(data) {
    let result = data;
    if (result && Array.isArray(result) && result.length > 0) {
      let res = result.filter((nav) => nav.primaryTree && nav.descendants?.length > 0);
      res?.forEach((cat) => recursiveSort(cat));
      res.sort((a, b) => b.position - a.position);
      return res;
    }
    return result;
  },
});

const { data: megaTree } = await useFetch(useRuntimeConfig().public.apiUrl + "/api/navigation", {
  key: 'navigationTree-SUPER',
  query: {
    storeId: computed(() => useCurrentStore()?.value?.id),
    tag: "SUPER",
  },
  transform(data) {
    let result = data;
    if (result && Array.isArray(result) && result.length > 0) {
      let res = result;
      res?.forEach((cat) => recursiveSort(cat));
      res.sort((a, b) => b.position - a.position);
      return res.reverse();
    }
    return result;
  },
});

const { pending: dotwPending, data: dotw } = await getDealOfTheWeek();

function toggleShoppingCartPopUp() {
  showShoppingCartDetails.value = !showShoppingCartDetails.value;
}

const showUserAccountDetails = ref(false);

function changeUserAccountFlyOutState(e) {
  if (!showUserAccountDetails.value) {
    showUserAccountDetails.value = true;
  } else {
    showUserAccountDetails.value = false;
  }
}

function toggleUserAccountPopUp() {
  showUserAccountDetails.value = !showUserAccountDetails.value;
}

const showLocalStoreDetails = ref(false);

function toggleLocalStorePopUp() {
  if (!showLocalStoreDetails.value) {
    showLocalStoreDetails.value = true;
  } else {
    showLocalStoreDetails.value = false;
  }
}

const store = useCurrentStore().value;
const currentStore = useCurrentStore()?.value?.id;
const storeDataString = "storeData." + currentStore;
const storeDataScoreString = storeDataString + ".score";

const articleSuggests = ref(new Array());

const webCodeRegEx = /^\d{11}$/;
const eanRegEx = /^\d{13}$/;
const storeRef = inject(ProvideNames.CURRENT_STORE_KEY);
const updateFunction = storeRef?.updateFunction || console.error;

async function handleHardlinkCheck(value, suggest?) {
  if (value?.toLowerCase()?.trim() == 'zentralshop1') {
    return await switchToCentralStore(updateFunction);
  } else if (value?.startsWith('e_')) {
    const { data: rawResult } = await useFetch(`/api/search/article/id/${value}?storeId=${currentStore}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: '/shop/suche',
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? 'true' : undefined
        }
      });
    } else {
      await navigateTo({
        path: props?.baseDir + mappedArticle?.slug, query: {
          sq: value,
          queryType: 'webcode'
        }
      }, { external: false, redirectCode: 307 });
    }
  } else if (webCodeRegEx.test(value)) {
    const { data: rawResult } = await useFetch(`/api/search/article/webcode/${value}?storeId=${currentStore}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
        },
      });
    } else {
      await navigateTo(
        {
          path: props?.baseDir + mappedArticle?.slug,
          query: {
            sq: value,
            queryType: "webcode",
          },
        },
        { external: false, redirectCode: 307 }
      );
    }
  } else if (eanRegEx.test(value)) {
    const { data: rawResult } = await useFetch(`/api/search/article/ean/${value}?storeId=${currentStore}`);
    const mappedArticle = rawResult?.value?.article;
    if (mappedArticle?.slug == undefined) {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
        },
      });
    } else {
      await navigateTo(
        {
          path: props?.baseDir + mappedArticle?.slug,
          query: {
            sq: value,
            queryType: "webcode",
          },
        },
        { external: false, redirectCode: 307 }
      );
    }
  } else {
    const { data } = await useFetch(`/api/search/redirect/${value}`);
    if (data?.value?.redirectUrl?.length > 0) {
      const redirectPath = data?.value?.redirectUrl?.split("?")?.at(0);
      const redirectQueryParams = data?.value?.redirectUrl?.split("?")?.at(1);

      const isExternal = (!redirectPath?.includes("unsere-produkte") && !redirectPath?.includes("suche"));
      const params = new URLSearchParams(redirectQueryParams || '');

      if (redirectQueryParams) {
        params.append("sq", value);
      }

      if (data?.value?.redirectType) {
        params.append("queryType", data?.value?.redirectType);
      }

      console.debug(
        "queries and final path",
        isExternal,
        params,
        redirectPath + (params.size > 0 ? "?" + params.toString() : "")
      );

      await navigateTo(redirectPath + (params.size > 0 ? "?" + params.toString() : ""), {
        external: isExternal,
        redirectCode: 307,
      });
    } else {
      await navigateTo({
        path: "/shop/suche",
        query: {
          q: value,
          suggestType: suggest ? suggest : undefined,
          suggest: suggest ? 'true' : undefined
        },
      });
      useSearchWord().value = value;
      suchWort.value = value;
    }
  }
}

const handleSearchKeyInput = (event: KeyboardEvent): void => {
  const searchInput = document.getElementById('termsuggest-input');
  switch (event.key) {
    case 'ArrowUp':
      event.preventDefault();
      moveSuggestionCursor(-1)
      break;
    case 'ArrowDown':
      event.preventDefault();
      moveSuggestionCursor(1)
      break;
    case 'Escape':
      event.preventDefault();
      resetSuggestions();
      suchWort.value = '';
      searchInput?.focus();
      break;
    default:
      resetSuggestions();
      searchInput?.focus();
      break;
  }
}

let selectedSuggestionIndex = 0;
const moveSuggestionCursor = (directionIncrement: number): void => {
  const maxIndex = termSuggestions?.value?.termSuggest?.length - 1;
  const newIndex = selectedSuggestionIndex + directionIncrement;
  if (newIndex < 0) {
    selectedSuggestionIndex = maxIndex;
  } else if (newIndex > maxIndex) {
    selectedSuggestionIndex = 0;
  } else {
    selectedSuggestionIndex += directionIncrement;
  }

  setSuggestionCursor(selectedSuggestionIndex);

  if(!hasStashedSearchWord) {
    stashedSearchWord.value = suchWort.value;
    hasStashedSearchWord = true;
  }
  suchWort.value = scrolledSuggestion.value;
}

const setSuggestionCursor = (index: number): void => {
  const selectedSuggestion: HTMLButtonElement = document.getElementsByClassName("suggestionBlock")[index].childNodes[0] as HTMLButtonElement;
  scrolledSuggestion.value = selectedSuggestion.textContent || '';
  queryForArticles.value = selectedSuggestion.textContent || '';
  selectedSuggestion.focus();
}

const resetSuggestions = () => {
  selectedSuggestionIndex = -1;
  stashedSearchWord.value = "";
  hasStashedSearchWord = false;
  useSuggestionsOpenState().value = false;
}

function editSearch(suggestion, suggest?) {
  useMarker('SearchUsed' + suggest + 'Suggest');
  suchWort.value = suggestion;
  useSearchWord().value = suggestion;
  handleHardlinkCheck(suggestion, suggest);
  useSuggestionsOpenState().value = false;
}
watch(
  useCurrentStore(),
  (newVal, oldVal) => {
    // if (!blackweek) {
    if (import.meta.client)
      try {
        if (useCurrentStore()?.value?.companyGroupId === "1906674") {
          document.getElementById("1").style.backgroundColor = "#004899";
          document.getElementById("1").style.color = "white";
          document.getElementById("1").classList.add("theme-blue-schimmel-cheese");
          document.getElementById("2").style.backgroundColor = "#004899";
          document.getElementById("2").style.color = "white";
          // checkStoreId()
        } else {
          document.getElementById("1").style.backgroundColor = "#FFFFFF";
          document.getElementById("1").style.color = "black";
          document.getElementById("2").style.backgroundColor = "#FFFFFF";
          document.getElementById("2").style.color = "black";
          document.getElementById("1").classList.remove("theme-blue-schimmel-cheese");
        }
      } catch (error) {

      }
  },
  { immediate: true }
);

onNuxtReady(() => {
  // checkStoreId()
  try {
    window.addEventListener("CookiebotOnConsentReady", function (e) {
      var prefs = {
        REQUIRED: Cookiebot.consent.necessary,
        STATISTICS: Cookiebot.consent.statistics,
        MARKETING: Cookiebot.consent.marketing,
        PRODUCT_RATINGS: Cookiebot.consent.preferences,
      };
      refreshCookie('CookieConsent');
      for (let k in prefs) {
        useState("cookie" + k).value = prefs[k];
      }
    });
  } catch (error) {
    console.warn("error checking cookie permissions.", error);
  }
});
</script>
<style>
#q-downshift #q-input {
  min-height: 52px;
  padding: 12px 36px;
  background-color: white;
  z-index: 1;
}

.css-baju5p {
  display: none;
}

#termsuggest-downshift .css-flmbq0 {
  height: 40px;
  display: block;
}

#termsuggest-downshift .css-cccewl {
  display: none;
}

#termsuggest-downshift .css-eoib4r {
  height: 100%;
  display: block;
}

#termsuggest-downshift .css-12z5vca {
  height: 100%;
  display: block;
}

.hidden_input {
  display: none;
}

/* Inpupfielt */
.css-tfdh8t {
  @apply text-primary;
}

/* Icons Container */
#termsuggest-downshift .css-v7fs1h {
  margin: 0;
}

#termsuggest-downshift .css-dra1z9 {
  /* padding: 0 10px; */
  min-width: 40px;
}

#termsuggest-downshift .css-dra1z9:last-child {
  border-left: 1px solid rgb(204, 204, 204);
}

.arrow-icon {
  cursor: pointer;
  display: contents;
  /* margin-left: 1px; */
}

.store-logo-dark {
  filter: hue-rotate(180deg);
}

.store-logo-dark-2 {
  filter: invert(1);
}

#termsuggest-input {
  @apply pl-4 w-full bg-secondary-light text-sm outline-none focus:text-primary text-paragraph-light;
  box-sizing: border-box;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 6px;
  min-height: unset;
  resize: none;
  overflow: hidden;
  border-color: transparent;
  font-family: Lato;
  height: 40px;
  overflow-y: hidden;
}

#termsuggest-input::-webkit-search-cancel-button {
  @apply hidden lg:inline-block cursor-pointer;
  -webkit-appearance: none;
  width: 12px;
  height: 12px;
  background:
    linear-gradient(45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 43%, #ff5e19 45%, #ff5e19 55%, rgba(0, 0, 0, 0) 57%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(135deg, transparent 0%, transparent 43%, #ff5e19 45%, #ff5e19 55%, transparent 57%, transparent 100%);
}

#searchbox {
  @apply bg-secondary-light flex justify-between py-1 shadow items-center;
}

.header .promo-bubble:hover {
  transform: scale(4);
  opacity: 1;
}

.header .energyEfficiency .listDocuments .entry {
  font-size: 0.69rem;
}
</style>
