import Client from '@searchkit/instantsearch-client';
export default defineNuxtPlugin(async (nuxtApp) => {
    let apiBase = useRuntimeConfig()?.public?.apiUrl || useRequestURL()?.href;
    return {
        provide: {
            searchClient: (path) => {
                let uri = new URL(apiBase);
                uri.pathname = path;
                // console.debug('returning searchClient for path ', path);
                return Client({
                    url: uri.href,
                    headers: computed(() => {
                        let ticking;
                        try {
                            const event = {
                                eventType: "VIEW_SEARCH_RESULT",
                                sessionId: useCurrentSessionIdentifier()?.value,
                            }
                            // console.debug('user sessionId ticking', event.sessionId)
                            const generalTickingData = { ...event, ...createGeneralTickingData(event) };
                            // useState('generalTicking').value = generalTickingData;
                            ticking = btoa(JSON.stringify(generalTickingData));
                            // console.debug('ticking data', generalTickingData)
                        } catch (error) {
                            console.warn('could not generate tickingData', error);
                        }
                        let headerObj;
                        if (ticking && Object.keys(ticking).length) {
                            headerObj = {
                                'fmarktheader': computed(() => useCurrentStore()?.value?.id),
                                'generalTicking': ticking,
                            }
                        } else {
                            headerObj = {
                                'fmarktheader': computed(() => useCurrentStore()?.value?.id || useStoreCookie()?.value),
                            }
                        }
                        return headerObj;
                    }).value
                });
            }
        }
    }
})
