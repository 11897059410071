<template>
  <div
    class="bg-[#FFEEE8] relative rounded flex flex-col text-sm p-2 overflow-auto"
  >
    <span class="absolute top-2 right-2 text-xs text-primary" @click="$emit('closeLogoutModal')"
      >Abbrechen</span
    >
    <div class="text-center font-bold p-8">Wollen Sie sich wirklich abmelden?</div>
    <div
      class="text-primary-contrast w-full block bg-primary text-center text-sm py-3 px-4 font-bold"
      @click="logout()"
    >
     Abmelden 
    </div>
  </div>
</template>
<script setup>
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
async function logout() {
  try {
    if (useCurrentUserState()?.value?.csrfToken == undefined) {
      await getExpUserProfile();
    }
    if (useCurrentUserState()?.value?.csrfToken) {
      let logoutCalls = new Array();

      logoutCalls.push(
        $fetch("/api/neo/frontend/_api/user/logout", {
          headers: {
            "bt-use-user-auth": "true",
            "csrf-token": useCurrentUserState()?.value?.csrfToken,
          },
          method: "POST",
        })
      );
      logoutCalls.push($fetch("/api/logout"));
      await Promise.allSettled(logoutCalls);
      await navigateTo("/app");
      clearNuxtData();
      reloadNuxtApp();
      window.history.go();
    }
  } catch (error) {
    console.error("Logout:", error);
    clearNuxtData();
    reloadNuxtApp();
    await navigateTo("/app");
  }
}
</script>
