export default defineAppConfig({
	homelink: '/',
	homelinkExternal: true,
	isAppUser: false,
	styles: {
		primaryColor: "#ababab",
		galleryBreakpoints: {
			1081: { visibleSlides: 5, slideMultiple: 5, slideRatio: 0.4 },
			811: { visibleSlides: 4, slideMultiple: 4, slideRatio: 0.5 },
			768: { visibleSlides: 3, slideMultiple: 3, slideRatio: 0.7 },
			430: { visibleSlides: 2, slideMultiple: 2, slideRatio: 1.2, bullets: false, draggingDistance: 50 },
			361: { visibleSlides: 1, slideMultiple: 1, slideRatio: 1.3, bullets: false, draggingDistance: 50 },
		},
	},
});
