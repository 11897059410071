<template>
  <div class="text-xs">
    <template v-if="displayDurations?.length > 1">
      An diesen Zeitpunkten sind wir für Sie erreichbar
      <div v-for="displayDuration in displayDurations">
        {{ dayFromTo(displayDuration) }} von {{ timeFromTo(displayDuration) }} Uhr
      </div>
    </template>
    <template v-else>
      {{ dayFromTo(displayDurations?.[0]) }} von {{ timeFromTo(displayDurations?.[0]) }} Uhr für Sie erreichbar
    </template>
  </div>
</template>

<script setup lang="ts">
import { WeekDay } from "~/models/ExpArticle/exp_ArticleAdvisor";
import type { DisplayDuration } from "~/models/ExpArticle/exp_ArticleAdvisor";

  const props = defineProps({
    displayDurations: {
      type: Array<DisplayDuration>,
    },
  });

  const timeFromTo = (displayDuration: DisplayDuration) => {
    const timeFrom = parseTimeToString(displayDuration?.timeFrom);
    const timeTo = parseTimeToString(displayDuration?.timeTo);

    return `${timeFrom} bis ${timeTo}`;
  }

  const parseTimeToString = (time: number) => {
    const hours = usePadStart(Math.floor(time / 60), 2, '0');
    const minutes = usePadStart(time % 60, 2, '0');
    return `${hours}:${minutes}`;
  }

  const dayFromTo = (displayDuration: DisplayDuration) => {
    if (isSingleDay(displayDuration)) {
      return `${translateDay(displayDuration.dayFrom)}`;
    }
    return `${translateDay(displayDuration.dayFrom)} bis ${translateDay(displayDuration.dayTo)}`;
  };

  const isSingleDay = (displayDuration: DisplayDuration): boolean => {
    return displayDuration.dayFrom === displayDuration.dayTo;
  }

  const translateDay = (day: WeekDay) => {
    let dayString = '';
    switch (WeekDay[day]) {
      case WeekDay.MONDAY:
        dayString = 'Montag';
        break;
      case WeekDay.TUESDAY:
        dayString = 'Dienstag';
        break;
      case WeekDay.WEDNESDAY:
        dayString = 'Mittwoch';
        break;
      case WeekDay.THURSDAY:
        dayString = 'Donnerstag';
        break;
      case WeekDay.FRIDAY:
        dayString = 'Freitag';
        break;
      case WeekDay.SATURDAY:
        dayString = 'Samstag';
        break;
      case WeekDay.SUNNDAY:
        dayString = 'Sonntag';
        break;
    }

    return dayString;
  }
</script>