import type { InjectionKey } from "vue";
import { ExpStore } from "~/models/ExpLocalStore/exp_Localstore";

export interface StoreReference {
	currentStore: Ref<ExpStore>;
	updateFunction: (newId: string) => void|Promise<void>;
}

export class ProvideNames {
	public static readonly ENABLE_PROXY_DEBUG_LOGGING = false;
	public static readonly ENABLE_PROXY_TIME_LOGGING = false;
	public static readonly ENABLE_CORRECT_CART_COUNTING = true;
	public static readonly ENABLE_CACHE_DEBUG_LOGGING = false;

	/**
	 * ----------------------------------------------------------------------------------------------------
	 *                                      General - Shop related Provides
	 * ----------------------------------------------------------------------------------------------------
	 */

	public static readonly BASE_DIR = "baseDir";
	public static readonly PLACEHOLDER_IMAGE = "/17/89/06/2d7e8a948fb264651203723a2101d1283b/defaultImage_100x100_90-0.jpeg";

	/**
	 * ----------------------------------------------------------------------------------------------------
	 *                                       Store related Provides / Names / Keys
	 * ----------------------------------------------------------------------------------------------------
	 */

	public static readonly CACHE_KEY_PREFIX = "bt_exp_";

	public static readonly CURRENT_STORE_KEY = Symbol("currentStore") as InjectionKey<StoreReference>;
	public static readonly CURRENT_STORE = "currentStore";
	public static readonly CURRENT_STORE_ID = "currentStoreId";

	public static readonly DEFAULT_CENTRAL_STORE: ExpStore = {
		id: "e_2879130",
		name: "expert e-Commerce",
		majorCustomerPhone: "0511 7808 253",
		phone: "0203 80481842",
		city: "Langenhagen",
		zip: "30855",
		street: "Bayernstraße 4",
		email: "kundenservice@expert-commerce.de",
		represantiveName: "expert e-Commerce GmbH",
		storeId: "2879130",
		storeLogo: "default",
		storeLogoImg: "47/f1/37/344581753cb59b3dfbe24d781b76f70899/expert_logo.svg", // white "a0/d1/90/7ad964598b9b63833e1a4fa34bd0c2aa98/logo_2879130.svg", // "42/bd/df/2fc3a6c437a68b4e77e7d5b2b6de35da13/Expert%20Logo.jpg",
		storeLink: "",
		companyGroupId: "",
		cartSwapOnlineReservation: false,
		videoConsultation: ""
	};

	/**
	 * Name of the cookie, containing the ID of the currently selected store
	 * @date 13.7.2023 - 11:13:15
	 *
	 * @public
	 * @static
	 * @readonly
	 * @type {string}
	 */
	public static readonly STORE_COOKIE_NAME: string = "fmarktcookie";

	public static readonly REQUIRED = "REQUIRED";
	public static readonly STATISTICS = "STATISTICS";
	public static readonly MARKETING = "MARKETING";
	public static readonly PRODUCT_RATINGS = "PRODUCT_RATINGS";
}
