<template>
  <div v-if="currentShoppingCart" class="shopCartToggle absolute w-full  text-paragraph" style="margin-top: 45px">
    <OnClickOutside
      class="shoppingcart flyout flex flex-col border border-solid border-secondary w-full lg:top-full left-0 sm:left-auto sm:right-0 absolute sm:w-[400px] text-paragraph"
      @trigger="close"
    >
      <!--NUR RESERVIERUNGEN-->
      <template
        v-if="
          reservationCart !== undefined && shoppingCartCount <= 0 && reservationCount > 0
        "
      >
        <ShoppingCartContent
          :cart="reservationCart"
          :cartType="'RESERVATION_CART'"
        ></ShoppingCartContent>
      </template>
      <!--NUR SHOPPINGCART-->
      <template
        v-if="
          shoppingCartCount > 0 &&
          currentShoppingCart !== undefined &&
          reservationCount <= 0
        "
      >
        <ShoppingCartContent
          :cart="currentShoppingCart"
          :cartType="'SHOPPING_CART'"
        ></ShoppingCartContent>
      </template>
      <!--SHOPPINGCART + RESERVATIONCART-->
      <template
        v-if="
          reservationCart !== undefined &&
          currentShoppingCart !== undefined &&
          shoppingCartCount > 0 &&
          reservationCount > 0
        "
      >
        <div>
          <button
            :class="'cartButton-' + toggleCartView"
            id="shoppingCartSwitch"
            @click="switchCartLayout(true)"
            style="
              margin: 3px 0 0;
              padding: 7px 10px;
              border-right: 1px solid #e7e7e7;
              border-bottom: 1px solid #e7e7e7;
            "
          >
            Warenkorb
            <span :class="'cartAmountNumber-' + toggleCartView">{{
              currentShoppingCart?.itemList?.length
            }}</span>
          </button>
          <button
            :class="'cartButton-' + !toggleCartView"
            id="reservationCartSwitch"
            @click="switchCartLayout(false)"
            style="margin: 3px 0 0; padding: 7px 10px; border-bottom: 1px solid #e7e7e7"
          >
            Reservierung
            <span :class="'cartAmountNumber-' + !toggleCartView">{{
              reservationCart?.itemList?.length
            }}</span>
          </button>
        </div>
        <template v-if="toggleCartView">
          <ShoppingCartContent
            :cart="currentShoppingCart"
            :cartType="'SHOPPING_CART'"
          ></ShoppingCartContent>
        </template>
        <template v-if="!toggleCartView">
          <ShoppingCartContent
            :cart="reservationCart"
            :cartType="'RESERVATION_CART'"
          ></ShoppingCartContent>
        </template>
      </template>
      <!--KEIN CART-->
      <template v-if="shoppingCartCount <= 0 && reservationCount <= 0">
        <div class="py-8 text-center">Es liegen keine Artikel im Warenkorb.</div>
        <NuxtLink
          :external="true"
          :to="(useFeatureState('ES-5386-Cart').value == false) ? '/Checkout' : '/shop/checkout?step=1'"
          class="btn-primary text-lg py-4 text-center block"
        >
          <BasicIcon
            class="w-4 mr-2 text-primary-contrast"
            :name="'cart-shopping-solid'"
          ></BasicIcon
          >Zum Warenkorb
        </NuxtLink>
      </template>
    </OnClickOutside>
  </div>
  <div v-if="currentShoppingCart == null" class="shopCartToggle absolute w-full text-paragraph" style="margin-top: 45px">
    <OnClickOutside
      class="shoppingcart flyout flex flex-col border border-solid border-secondary w-full lg:top-full left-0 sm:left-auto sm:right-0 absolute sm:w-[400px]"
      @trigger="close"
    >
      <div class="py-8 text-center" style="padding-bottom:34px;">Es liegen keine Artikel im Warenkorb.</div>
      <NuxtLink
      :external="true"
      :to="(useFeatureState('ES-5386-Cart').value == false) ? '/Checkout' : '/shop/checkout?step=1'"
        class="btn-primary text-lg py-4 text-center block"
      >
        <BasicIcon
          class="w-4 mr-2 text-primary-contrast"
          :name="'cart-shopping-solid'"
        ></BasicIcon>
        Zum Warenkorb
      </NuxtLink>
    </OnClickOutside>
  </div>
</template>
<script setup lang="ts">
import { OnClickOutside } from "@vueuse/components";
import ShoppingCartContent from "~/components/Content/ShoppingCartContent.vue";
import { useCurrentShoppingCart, useCurrentReservationCart } from "~/composables/states";
const props = defineProps({
  shoppingCart: {
    type: Object,
  },
});
const showShoppingCart = ref(false);
const currentShoppingCart = await useCurrentShoppingCart().value;
const reservationCart = await useCurrentReservationCart().value;

const reservationCount = useReservationCartItemsCount();
const shoppingCartCount = useShoppingCartItemsCount();

let toggleCartView = ref(true);
function switchCartLayout(switcher: Boolean) {
  if (switcher) {
    toggleCartView.value = true;
  } else {
    toggleCartView.value = false;
  }
}

function close() {
  emit("changeFlyOutState", showShoppingCart);
}
const emit = defineEmits("changeFlyOutState");
</script>
<style>
.cartButton-false {
  width: 50%;
  border: solid grey 0 0.5px 0 0;
}

.cartButton-true {
  width: 50%;
  border: solid #ff5e19 0 0.5px 0 0;
  color: #ff5e19;
  font-weight: bolder;
}

.cartAmountNumber-true {
  background-color: #ff5e19;
  border-radius: 100%;
  padding: 0px 4px;
  color: #fff;
}

.cartAmountNumber-false {
  background-color: #737373;
  border-radius: 100%;
  padding: 0px 4px;
  color: #fff;
}
</style>
