<template>
  <template v-if="isShowFirstPage()">
    <template v-if="!showSecondSite">
      <div class="text-left modal-order flex flex-col gap-4">
        <div class="font-bold text-lg text-primary"
          @click.prevent="addArticleToCart(props?.article?.articleId, (activatedInputs?.length > 0 ? activatedInputs : []))">
          Unsere Service Angebote für Sie.</div>
        <div class="content">
          <form>

            <template v-if="article?.crossSaleArticles !== null && article?.crossSaleArticles !== undefined">
              <template v-if="article?.crossSaleArticles?.length > 0">
                <ArticleRelatedArticle :showFlyOut="false" :showInput="true"
                  @checkboxCollection="keepTheCheckBoxResults($event)"></ArticleRelatedArticle>
              </template>
            </template>
            <template v-if="warranty !== null && warranty !== undefined && checkForWarrantyAndAvailability()">
              <Warranty :showInput="true" :taxLabel="'Versicherungssteuer'" :warrantyList="unref(warranty)"
                @checkboxCollection="keepTheCheckBoxResults($event)" :mainArticleQuantity="mainArticleQuantity">
              </Warranty>
            </template>
          </form>
        </div>
        <button class="btn-primary w-full" @click="toggleSecondPage()">Weiter</button>
      </div>
    </template>
  </template>
  <template v-if="!isShowFirstPage()">
    <div class="text-left modal-order flex flex-col gap-4 relative" id="orderProcess">
      <div v-if="pendingAddToCart" class="absolute left-0 right-0 top-0 bottom-0 cursor-wait  z-10">
        <div class="bg-white h-full w-full opacity-50 absolute"></div>
        <BasicLoader :rows="1" :columns="1" height="100%" />
      </div>
      <div class="font-bold text-lg py-3">
        <template v-if="addArticlesToCart?.length > 0">Die Produkte wurden erfolgreich in den Warenkorb gelegt</template>
        <template v-else>Das Produkt wurde erfolgreich in den Warenkorb gelegt</template>
      </div>
      <div class="font-bold line-clamp-2">{{ article?.headline }}</div>
      <div class="grid grid-cols-2 gap-4">
        <div class="img">
          <ArticleExpMedium :mediumId="article?.primaryImage" :preset="'ppmsmall'" />
          <ArticleEnergyEfficiency :showDataFile="true" class="grow text-right" :article="article" />
        </div>
        <div class="text-right">
          <ArticleExpPrice :enableDeliveryModal="false" :enableUVPLabel="false" :enableEnergyLabel="true"
            :popOverClass="'right-0 text-left w-[300px]'"
            :formerPriceClass="'flex gap-2 flex-wrap relative items-center justify-end'" :addsReserveSpace="true"
            :pprice="price" class="pt-3 mt-3 text-right" :deliveryInfoClass="'whitespace-nowrap direction-reverse'"
            :displayPriceDesignClasses="'text-5xl relative text-right'" />
        </div>
      </div>
      <CheckoutAdditionItems :articleId="props?.article?.articleId" :warranties="warranty" />
      <template v-for="item in otherAddedArticles" :key="item?.articleId">
        <div class="font-bold line-clamp-2">{{ item?.article?.headline }}</div>
        <div class="grid grid-cols-2 gap-4">
          <div class="img">
            <ArticleExpMedium :mediumId="item?.article?.primaryImage" :preset="'ppmsmall'" />
            <ArticleEnergyEfficiency :showDataFile="true" class="grow text-right" :article="item?.article" />
          </div>
          <div class="text-right">
            <ArticleExpPrice :enableDeliveryModal="false" :enableUVPLabel="false" :enableEnergyLabel="true"
              :popOverClass="'right-0 text-left w-[300px]'"
              :formerPriceClass="'flex gap-2 flex-wrap relative items-center justify-end'" :addsReserveSpace="true"
              :pprice="item?.price" class="pt-3 mt-3 text-right" :deliveryInfoClass="'whitespace-nowrap direction-reverse'"
              :displayPriceDesignClasses="'text-5xl relative text-right'" />
          </div>
        </div>
      </template>
      <NuxtLink class="btn-primary text-center block"
        :to="(useFeatureState('ES-5386-Cart').value == false) ? '/Checkout' : '/shop/checkout?step=1'" :external="true">
        <BasicIcon :name="'cart-shopping-solid'" class="w-5 h-5 lg:w-4 lg:h-4 align-text-bottom" />
        Zum Warenkorb
      </NuxtLink>
      <button class="btn-primary text-center block cursor-pointer" @click="$emit('closeAll', fakeSecondPageCall)">
        Weiter einkaufen
      </button>
      <template v-if="filteredAddtionalArtCategories?.size > 0">
        <strong v-if="checkForWarrantyAndCrossSale()">Das könnte Sie auch interessieren</strong>
        <Tabs class="article-accessories" :options="{ useUrlFragment: false }" :cache-lifetime="0"
          @click="scrollToFirstEntry($event)">
          <Tab v-for="(categories, index) in filteredAddtionalArtCategories?.keys()" :key="categories"
            :name="' ' + getCategoryLabel(categories)" :id="'tabcontent_' + index">
            <div v-for="entry in filteredAddtionalArtCategories?.get(categories)" :key="entry.id">
              <ArticleExpPrice :showDataFile="true" :enableDeliveryModal="false" :enableUVPLabel="false" :showEEL="true"
                :addsReserveSpace="true" :localarticle="entry?.expArticle" class="border-t pt-3 mt-3"
                :popOverClass="'w-[250px]'">
                <template #beforePrice="{ finalPrice }">
                  <NuxtLink :to="entry?.link" v-if="finalPrice?.bruttoPrice">
                    <div class="brand text-lg text-primary line-clamp-2 h-8">
                      {{ entry?.brand }}
                    </div>
                    <div class="name text-sm line-clamp-5">
                      {{ entry?.name }}
                    </div>
                    <div class="relative img" style="min-height: 175px;">
                      <div class="absolute top-3 left-0">
                        <LazyPromotionBubble  :article-id="entry?.expArticle?.articleId" :price="finalPrice">
                        </LazyPromotionBubble>
                      </div>
                      <ArticleExpMedium :mediumId="entry?.image" style="margin: auto" :lazy="true" preset="ppmsmall">
                      </ArticleExpMedium>
                    </div>
                  </NuxtLink>
                </template>
                <template #afterPrice="{ finalPrice }">
                  <div class="text-success" :id="entry?.expArticle?.articleId + '-greenArrow'"
                    style="top:110%;right: 0px; display: none;">
                    In den Warenkorb gelegt
                    <BasicIcon :name="'check-regular'" class="w-4 scale-150 align-baseline text-success"
                      style="margin-left: 12px;" />
                  </div>
                  <div v-if="finalPrice?.bruttoPrice">
                    <button class="articleStatus" @click="addToCart(entry)" type="button">
                      <BasicIcon :name="'cart-shopping-solid'" class="w-4 mr-2 align-text-top" />
                      In den Warenkorb
                    </button>

                  </div>
                </template>
              </ArticleExpPrice>
            </div>

          </Tab>
        </Tabs>
        <div id="econdaContainer"></div>
      </template>
    </div>
  </template>
</template>
<script setup lang="ts">
import ArticleExpMedium from "~/components/Article/ExpMedium.vue";
import ArticleRelatedArticle from "~/components/Article/RelatedArticle.vue";
import { Price } from "~/models/ExpPrice/exp_Price";
import { ExpArticle } from "~/models/ExpArticle/exp_Article";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
// import { getRelationArticlesForCrossSale } from "~/composables/ExpApi/expSearch";
import { inject } from "vue";
import { Tabs, Tab } from "vue3-tabs-component";
import { useCurrentShoppingCart } from "~/composables/states";
import { addArticleToCart } from "~/composables/ExpApi/expCartActions";
import { getExpBrand, getExpNavigationTree } from "~/composables/ExpApi/expCache";
import { getCategoryFromNavigationTree, getCategoryFromTree } from "~/composables/ExpApi/expCategory";
import { getExpArticle } from "~/composables/ExpApi/expArticle";
import { getPrice } from "~/composables/ExpApi/expPrice";

const props = defineProps({
  warranty: {
    default: () => {
      return inject("primaryWarranty");
    },
  },
  article: {
    type: Object,
    default: () => {
      return inject("primaryArticle");
    },
  },
  mainArticleQuantity: {
    type: Number,
    default: 1
  },
  price: {
    type: Price,
  },
  addtionalArtCategories: {
    type: Map,
    default: () => inject('additionalArticleResult') || new Map(),
  },
  fakeSecondPageCall: {
    type: Boolean
  },
  addArticlesToCart: {
    type: Array
  },
  selectedWarranties: {
    type: Array
  },
  categoryList: {
    type: Object
  },
  orderFromBundle: {
    type: Boolean,
    default: false
  },
});
const emit = defineEmits(['close-modal', 'fakeSecondPageCallEmit'])
const showSecondSite = ref(false)
const deleteError = ref(null)
const pendingAddToCart = ref(false)
const marketingCookie = getCookiePermissions('MARKETING')
let storeId = useCurrentStore().value?.id.substring(2)
const route = useRequestURL().pathname;
const query = useRequestURL().search;
const otherAddedArticles = ref([])
for(let i = 0; i < props.addArticlesToCart?.length; i++) {
  const { data: article } = await getExpArticle(props.addArticlesToCart[i]);
  const { data: price } = await getPrice(props.addArticlesToCart[i], useCurrentStore(), false, false);
  otherAddedArticles.value.push({
    article: article.value,
    price: price.value
  });
}
//AddtionalArticles werden hinzugebucht
async function addToCart(article: Object) {
  pendingAddToCart.value = true
  if (article?.expArticle?.articleId !== undefined) {
    document.getElementById(article?.expArticle?.articleId + '-greenArrow').style.display = 'inline-block'
  }
  if (article?.expArticle?.articleId) {
    await addArticleToCart(article?.expArticle?.articleId, [])
    // isArticleInCart(article?.expArticle?.articleId)
  }
  pendingAddToCart.value = false
}
const { data: navigationTree } = await getExpNavigationTree("MAIN");

function getCategoryLabel(categoryId: string) {
  return getCategoryFromNavigationTree(categoryId, navigationTree)?.name
}

const filteredAddtionalArtCategories = ref(new Map());
function filterAdditionalArticlesByArticlesToAdd() {
  let addItems = props?.addArticlesToCart?.length ? props?.addArticlesToCart.map((e) => e) : new Array();
  let currentAdditionalArticles = toValue(props?.addtionalArtCategories) || new Map();
  addItems.push(props?.article?.articleId);
  if (currentAdditionalArticles?.size) {
    for (let [key, value] of currentAdditionalArticles) {
      let entry = currentAdditionalArticles.get(key);
      entry = entry.filter(e => {
        return !addItems.includes(e.expArticle.articleId);
      });
      if (!entry.length) {
        currentAdditionalArticles.delete(key);
      } else {
        currentAdditionalArticles.set(key, entry);
      }
    }
  }
  filteredAddtionalArtCategories.value = currentAdditionalArticles || new Map();
};
filterAdditionalArticlesByArticlesToAdd();

let activatedInputs: any = [];
function keepTheCheckBoxResults(e) {
  if (isProxy(e)) {
    e = toRaw(e)
  }
  if (e.value) {
    if (activatedInputs.length > 0) {
      if (e.key.source === 'WG') {
        for (let a = 0; a < activatedInputs.length; a++) {
          if (activatedInputs[a].key.source === 'WG') {
            activatedInputs.splice(a, 1)
            useActivatedServicesInCart().value = activatedInputs
          }
        }
      }
    }
    activatedInputs.push(e);
    useActivatedServicesInCart().value = activatedInputs
  } else {
    activatedInputs = activatedInputs.filter(obj => obj.key.typeName !== e.key.typeName)
    useActivatedServicesInCart().value = activatedInputs
  }
}


function scrollToFirstEntry(event: any) {
  if (event.srcElement.classList.contains('tabs-component-tab-a')) {
    location.href = "#" + document.querySelectorAll('.modal-overlay .tabs-component-panel:not([style*="display: none"]')[0].id;
  }
}


async function toggleSecondPage() {
  showSecondSite.value = true;
  emit('fakeSecondPageCallEmit', props?.fakeSecondPageCall)
  let mapped = activatedInputs?.map((nextArticle) => nextArticle?.key?.expArticle?.articleId).filter(Boolean);
  fillEcondaWithData()
  fillGtmAddToCart()
}

function isShowFirstPage() {
  if (props?.fakeSecondPageCall) {
    return false
  }
  if (!showSecondSite.value) {
    if (checkForWarrantyAndCrossSale()) {
      if (checkForWarrantyAndAvailability() || (props?.article?.crossSaleArticles !== null && props?.article?.crossSaleArticles?.length > 0)) {
        return true
      } else {
        return false
      }
    } else {
      showSecondSite.value = true
      return false
    }
  }
  return false
}

function checkForWarrantyAndAvailability() {
  let onlineAvailabilityArray = ["NOT_AVAILABLE", "ONLY_IN_STORE", "NONE", "SOLD_OUT", "ORDER_IN_ADVANCE", "PREORDER"];
  if (props?.warranty?.value?.warrantyList !== undefined && props?.warranty?.value?.warrantyList?.length > 0 && !onlineAvailabilityArray.includes(props?.price?.onlineButtonAction)) {
    return true
  } else {
    return false
  }
}

function checkForWarrantyAndCrossSale() {
  if (props?.warranty?.value?.warrantyList !== undefined || props?.article?.crossSaleArticles !== null) {
    if ((props?.warranty?.value?.warrantyList !== undefined && props?.warranty?.value?.warrantyList?.length > 0) || (props?.article?.crossSaleArticles !== null && props?.article?.crossSaleArticles?.length > 0)) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

if (!isShowFirstPage()) {
  pendingAddToCart.value = true
  if (!props?.orderFromBundle || !((props?.article?.crossSaleArticles || checkForWarrantyAndAvailability()))) {
    for (let i = 0; i < props?.addArticlesToCart?.length; i++) {
      await addArticleToCart(props?.addArticlesToCart[i], [])
    }
    await addArticleToCart(props?.article?.articleId, (activatedInputs?.length > 0 ? activatedInputs : []))
    fillEcondaWithData()
    fillGtmAddToCart()
  }
  pendingAddToCart.value = false
}

async function removeArticleFromCart(articleId: string) {
  let itemId: string = ""
  let actualQuantity: number = 0
  useCurrentShoppingCart().value?.itemList?.forEach(element => {
    if (element.articleId == articleId) {
      itemId = element.id
      actualQuantity = (element.quantity - 1) >= 0 ? element.quantity - 1 : 0
    }
  });
  if (itemId != "") {
    const freshUser = await getExpUserProfile();
    if (freshUser?.shoppingCartId) {
      const res = await modifyArticleQuantityInShoppingCart('shoppingcart', itemId, actualQuantity, freshUser?.csrfToken)
      if (res.value == null) {
        useShoppingCartItemsCount().value -= 1;
        useCurrentShoppingCart().value = fetchCurrentCart(freshUser?.shoppingCartId)
        return Promise.resolve()
      }
      else {
        if (deleteError.value != null) {
          (deleteError.value as HTMLElement).innerText = "Es ist ein Fehler Aufgetreten."
        }
      }
    }
  } else {
    if (deleteError.value != null) {
      (deleteError.value as HTMLElement).innerText = "Item nicht gefunden."
    }
  }
  return Promise.reject();
}

function convertCategoryList(categories) {
  if (categories?.list !== undefined) {
    categories = categories.list
    let list = ''
    for (let a = 0; a < categories.length; a++) {
      if (categories[a].name.includes('&')) {
        let word = categories[a].name.replace('&', '\u0026')
        list += '/' + word
      }
    }
    list = list.substring(1)

    return list
  }
}

async function fillGtmAddToCart() {
  const cat = await getCategoryFromTree(props?.article?.primaryCategory);
  const catString = cat?.ancestors?.replace(', ', '/') + '/' + cat?.name;
  let orderOb = {
    event: "addToCart",
    ecommerce: {
      add: {
        products: [
          {
            name: props?.article?.title,
            id: props?.article?.webcode,
            price: props?.price?.bruttoPrice,
            category: catString,
            quantity: 1
          }
        ]
      }
    }
  }
  const gtm = useGtm()
  if (gtm?.enabled()) {
    gtm?.push({ ecommerce: null })
    gtm?.push(orderOb)
  }
}


async function fillEcondaWithData(article?: Object) {
  //console.log("fillEcondaWithData : ",props?.article)
  const cat = await getCategoryFromTree(props?.article?.primaryCategory);
  const catString = cat?.ancestors?.replace(', ', '/') + '/' + cat?.name;
  onNuxtReady(() => {
    useNuxtApp().$emos3.send({
      'herkunftsland': 'Nuxt',
      'ec_Event':
        [{
          'price': props?.price?.bruttoPrice,
          'name': props?.article?.headline,
          'count': 1,
          'pid': props?.article?.internationalArticleNumber,
          'type': 'c_add',
          'sku': props?.article?.webcode,
          'group': catString
        }],
      'siteid': useCurrentStoreCompanyGroup(), // Gesellschafter ID
      'usertype': 'unknownUser',
      'langid': 'ES-5386-Cart=' + useFeatureState('ES-5386-Cart')?.value,
      'countryid': storeId,
      'content': route,
      'url': [[route + query]]
    });
  })
}
</script>
