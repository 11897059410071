export default defineNuxtPlugin((nuxtApp) => {
	let $device = nuxtApp?.$device;
	$device.heads = useRequestHeaders();// console.log("added flag for lighthouse");

	$device.isLighthouse = $device?.userAgent?.includes('Lighthouse') ? true : false;
	$device.isApplePWA = $device?.userAgent?.includes('PWAShell') ? true : false;

	if ($device?.isApplePWA == true)
		useAppSettingsCookie().value.isNativeApp = true

	if (!$device.isCrawler) {
		$device.isCrawler = $device?.heads?.["bt-bot-test"]?.includes("08") ? true : false;
	}
	// if (!$device.isLighthouse)
	// $device.isLighthouse = $device?.heads?.["x-bot-verified"]?.includes("true") ? true : false;
	// console.debug("$device state", $device);
	if (import.meta.server) {
		useState('isLighthouse').value = $device?.isLighthouse;
		useIsCrawlerState().value = $device.isCrawler;
		console.debug('[bt-lighthouse] state of crawler', useIsCrawlerState().value, useState('isLighthouse').value)
	} else {
		if (useState('isLighthouse')?.value == true) {
			console.debug('changed by state');
			$device.isLighthouse = true;
		}
		if (useIsCrawlerState()?.value == true) {
			console.debug('changed by state');
			$device.isCrawler = true;
		}
	}
	// $device.isLighthouse = true
});
