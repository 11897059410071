<template>
    <div @click="scrollTop()" class="hidden btn-scroll-to-top btn-primary cursor-pointer invisible lg:visible">nach oben</div>
</template>
<script setup>
function scrollTop() {
  if (process.client) {
    window.scrollTo(0, 0)
  }
}

function debounce(fn) {
  let frame
  return (...params) => {
    if (frame) { 
      cancelAnimationFrame(frame)
    }
    frame = requestAnimationFrame(() => {
      fn(...params)
    })
  } 
}

onMounted(()=>{
  if(!window.scrollToTopBound) {
    let scrollToTopButtons = document.getElementsByClassName('btn-scroll-to-top')
    function scrollPosition() {
        if(window.scrollY > 250) {
            for(let i = 0; i < scrollToTopButtons.length; i++) {
                scrollToTopButtons[i].classList.remove("hidden")
            }
        } else {
            for(let i = 0; i < scrollToTopButtons.length; i++) {
                scrollToTopButtons[i].classList.add("hidden")
            }
        }
    }

    document.addEventListener('scroll', debounce(scrollPosition), { passive: true });
    scrollPosition();
    window.scrollToTopBound = true
  }
})
</script>