<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    class="w-6 h-6 text-primary"
    viewBox="0 0 450.65 450.65"
  >
    <defs>
      <Style>
        .cls-1 { fill: #fff; } .cls-1, .cls-2 { stroke-width: 0px; } .cls-2 { fill:
        #eb6608; }
      </Style>
    </defs>
    <path
      class="cls-1"
      d="M0,224.9C0,100.52,100.52,0,224.9,0s225.75,101.37,225.75,225.75-100.52,225.75-224.9,224.9C101.37,450.65,0,350.13,0,224.9"
    />
    <polygon
      class="cls-2"
      points="62.19 145.67 172.08 145.67 180.6 164.41 71.56 164.41 62.19 145.67"
    />
    <polygon
      class="cls-2"
      points="76.67 174.64 185.71 174.64 195.08 193.38 85.19 193.38 76.67 174.64"
    />
    <polygon
      class="cls-2"
      points="90.3 202.75 199.34 202.75 208.71 221.49 99.67 221.49 90.3 202.75"
    />
    <polygon
      class="cls-2"
      points="391.87 145.67 382.5 164.41 273.46 164.41 281.98 145.67 391.87 145.67"
    />
    <polygon
      class="cls-2"
      points="377.39 174.64 368.87 193.38 258.97 193.38 268.35 174.64 377.39 174.64"
    />
    <polygon
      class="cls-2"
      points="363.76 202.75 354.39 221.49 245.34 221.49 254.71 202.75 363.76 202.75"
    />
    <polygon class="cls-2" points="209.56 79.23 225.75 51.11 241.94 79.23 209.56 79.23" />
    <polygon
      class="cls-2"
      points="263.23 117.56 273.46 136.3 178.04 136.3 188.27 117.56 263.23 117.56"
    />
    <polygon
      class="cls-2"
      points="258.12 108.19 193.38 108.19 204.45 89.45 247.05 88.6 258.12 108.19"
    />
    <polygon
      class="cls-2"
      points="62.19 307.53 71.56 288.79 180.6 288.79 172.08 307.53 62.19 307.53"
    />
    <polygon
      class="cls-2"
      points="76.67 278.57 85.19 259.83 195.08 259.83 185.71 278.57 76.67 278.57"
    />
    <polygon
      class="cls-2"
      points="90.3 250.46 99.67 230.86 208.71 230.86 199.34 250.46 90.3 250.46"
    />
    <polygon
      class="cls-2"
      points="391.87 307.53 282.83 307.53 273.46 288.79 382.5 288.79 391.87 307.53"
    />
    <polygon
      class="cls-2"
      points="377.39 278.57 268.35 278.57 258.97 259.83 368.87 259.83 377.39 278.57"
    />
    <polygon
      class="cls-2"
      points="363.76 250.46 254.71 250.46 245.34 230.86 354.39 230.86 363.76 250.46"
    />
    <polygon
      class="cls-2"
      points="209.56 373.98 241.94 373.98 225.75 402.09 209.56 373.98"
    />
    <polygon
      class="cls-2"
      points="263.23 335.64 188.27 335.64 178.04 316.9 273.46 316.9 263.23 335.64"
    />
    <polygon
      class="cls-2"
      points="258.12 345.02 247.05 363.76 204.45 363.76 193.38 345.02 258.12 345.02"
    />
    <path
      class="cls-2"
      d="M22.15,225.75c0,111.6,91.15,202.75,202.75,202.75s202.75-90.3,202.75-202.75S336.5,23,224.9,23,21.3,114.15,22.15,225.75M11.07,224.9C11.07,106.49,106.49,10.22,224.9,10.22s214.68,96.26,214.68,214.68-95.41,214.68-213.82,214.68S11.07,343.31,11.07,224.9"
    />
  </svg>
</template>
