import revive_payload_client_4sVQNw7RlN from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_4oHW6JzpOW from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import components_plugin_KR1HBZs4kY from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import nuxt_plugin_6wEQMY3tee from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_tbFNToZNim from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_ghbUAjaD3n from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_OVoKJro5pc from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import defaults_JNk3m5wGSX from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/defaults.js";
import init_3TppeXojsQ from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/node_modules/nuxt-schema-org/dist/runtime/nuxt/plugin/init.js";
import _01_grafana_faro_client_yG18mmzeeC from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/01.grafana-faro.client.js";
import _02_flagsmith_TIrrEhGVt2 from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/02.flagsmith.ts";
import _03_device_detection_extensions_AFmBTaOoMw from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/03.device-detection-extensions.js";
import _03_emos3_client_GFeOOvSpMQ from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/03.emos3.client.js";
import _04_router_Qea0qMCrKJ from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/04.router.js";
import _05_searchKit_tuFwh7MU4d from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/05.searchKit.ts";
import restore_state_client_BW18lS7NYo from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/restore-state.client.ts";
import vee_validate_KcKlKmvCrJ from "/opt/atlassian/pipelines/agent/build/vue-expert-shop/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  restore_state_client_4oHW6JzpOW,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  nuxt_plugin_6wEQMY3tee,
  plugin_tbFNToZNim,
  plugin_ghbUAjaD3n,
  plugin_client_OVoKJro5pc,
  defaults_JNk3m5wGSX,
  init_3TppeXojsQ,
  _01_grafana_faro_client_yG18mmzeeC,
  _02_flagsmith_TIrrEhGVt2,
  _03_device_detection_extensions_AFmBTaOoMw,
  _03_emos3_client_GFeOOvSpMQ,
  _04_router_Qea0qMCrKJ,
  _05_searchKit_tuFwh7MU4d,
  restore_state_client_BW18lS7NYo,
  vee_validate_KcKlKmvCrJ
]