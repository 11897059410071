import { WeekDay } from "~/models/ExpArticle/exp_ArticleAdvisor";
import type { ExpArticleAdvisor } from "~/models/ExpArticle/exp_ArticleAdvisor";

export const getDisplayDurationsForToday = (articleAdvisor: ExpArticleAdvisor): DisplayDuration[] => {
    return articleAdvisor?.displayDurations?.filter((item): boolean => {
        const dayFrom: WeekDay = WeekDay[item.dayFrom];
        const dayTo: WeekDay = WeekDay[item.dayTo];
        const today: WeekDay = new Date().getDay();

        return dayFrom <= today && today <= dayTo;
    }) ?? [];
};

export const available = (articleAdvisor: ExpArticleAdvisor): boolean => {
    if (articleAdvisor?.active) {
        const todaysDisplayDurations: DisplayDuration[] = getDisplayDurationsForToday(articleAdvisor);

        if (todaysDisplayDurations?.length > 0) {
            const matchingDisplayDuration = todaysDisplayDurations.find((item) => {
                const now = new Date();
                const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());

                const timeNow: number = Math.floor((Date.now() - startOfDay) / 1000 / 60);
                const timeFrom: number = item.timeFrom;
                const timeTo: number = item.timeTo;

                return timeFrom <= timeNow && timeNow <= timeTo;
            });

            return matchingDisplayDuration !== undefined;
        }
    }

    return false;
};

export default {
    available,
    getDisplayDurationsForToday,
}