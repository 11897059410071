export const getExpArticle = (id: string) => {
	const cached = useNuxtData("article/id=" + id);
	if (cached?.data?.value) {
		console.log("found cached article data");
		return cached;
	} else {
		return useFetch(useRuntimeConfig().public.apiUrl + `/api/search/article/id/${id}`, {
			key: "article/id=" + id,
			transform(data) {
				return data?.article;
			},
		});
	}
}

export const getExpArticleWithWebcode = (webcode: string, lazy?: boolean) => {
	return useFetch(useRuntimeConfig().public.apiUrl + `/api/search/article/webcode/${webcode}`, {
		key: "article/webcode=" + webcode,
		lazy: lazy,
		transform(data) {
			return data?.article;
		},
	});
}

export const getExpArticleCategory = (categoryId: string) => {
	if (categoryId === undefined)
		return { data: undefined };
	return useFetch(useRuntimeConfig().public.apiUrl + "/api/neo/article/categories", {
		query: {
			categoryId: categoryId,
		},
		key: `category/id=${categoryId}`,
	});
}

export const buildArticleLinkWithCategories = (baseDir: string, item: Object): string => {
	const primaryCategorySlug: string = item.categoriesByDistance['0'].find(category => category?.id == item?.primaryCategory?.id)?.slug;
	const categoyTree: Array<String> = [
		item?.categoriesByDistance?.['2']?.[0]?.slug,
		item?.categoriesByDistance?.['1']?.[0]?.slug,
		primaryCategorySlug ?? item?.categoriesByDistance?.['0']?.[0]?.slug,
	];
	const categoryPath: string = categoyTree.join('/');

	return baseDir + categoryPath + '/' + item.slug;
}