<template>
  <div>
    <div
      class="fixed app navigation top-0 w-[100vw] h-full left-[100vw] transition bg-white overflow-auto z-20"
      :class="navigation ? '-translate-x-full opacity-100' : 'translate-x-0 opacity-0'"
    >
      <AppNavigation @close-navigation="toggleNavigation()" />
    </div>
    <footer
      class="fixed bottom-0 text-xs text-center w-full pt-2 z-20 bg-white"
      :class="useDevice()?.isApple ? 'h-[80px]' : 'h-[60px]'"
    >
      <div class="flex justify-around pb-2">
        <template v-for="entry in menu.links" v-bind:key="entry.title">
          <template v-if="entry.type == 'reset'">
            <NuxtLink
              :external="false"
              :to="entry.href"
              @click="
                closeCartFlyout();
                closeNavigation();
              "
            >
              <div class="flex items-center flex-col pb-1">
                <AppIcon class="w-6 h-6 text-primary" />
              </div>
              <div
                v-text="entry.title"
                :class="$route.path.includes('app') && !navigation ? 'text-primary' : ''"
              />
            </NuxtLink>
          </template>

          <template v-if="entry.type == 'wishlist'">
            <NuxtLink :to="entry.target" class="relative" @click="closeNavigation()">
              <div class="flex items-center flex-col pb-1">
                <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
              </div>
              <div
                v-text="entry.title"
                :class="$route.path.includes('wishlist') ? 'text-primary' : ''"
              />
              <div class="absolute top-0 right-0">
                <div class="bg-black text-white rounded-full w-4 text-center text-xs">
                  {{ useWishListItemCount() }}
                </div>
              </div>
            </NuxtLink>
          </template>
          <template v-if="entry.type == 'navigation'">
            <div @click="toggleNavigation()">
              <div class="flex items-center flex-col pb-1">
                <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
              </div>
              <div v-text="entry.title" :class="navigation ? 'text-primary' : ''" />
            </div>
          </template>
          <template v-if="entry.type == 'cart'">
            <template v-if="shoppingCartCount == 0 && reservationCartCount == 0">
              <NuxtLink
                @click="closeNavigation()"
                :external="false"
                :to="'/shop/checkout?step=1'"
                class="relative"
              >
                <div class="flex items-center flex-col pb-1">
                  <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
                </div>
                <div
                  v-text="entry.title"
                  :class="$route.path.includes('checkout') ? 'text-primary' : ''"
                />
                <div class="absolute top-0 right-0">
                  <div class="bg-black text-white rounded-full w-4 text-center text-xs">
                    {{ (shoppingCartCount || 0) + (reservationCartCount || 0) }}
                  </div>
                </div>
              </NuxtLink>
            </template>
            <template v-if="shoppingCartCount > 0 && reservationCartCount > 0">
              <!-- <div class="fixed top-0 left-0 w-full h-full z-10"  v-if="cartnavigation" @click="closeCartFlyout();"></div>-->
              <div
                @click="
                  toggleCart();
                  closeNavigation();
                "
                class="relative"
              >
                <div class="flex items-center flex-col pb-1">
                  <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
                </div>
                <div
                  v-text="entry.title"
                  :class="$route.path.includes('checkout') ? 'text-primary' : ''"
                />
                <div class="absolute top-0 right-0">
                  <div class="bg-black text-white rounded-full w-4 text-center text-xs">
                    {{ (shoppingCartCount || 0) + (reservationCartCount || 0) }}
                  </div>
                </div>
              </div>
              <template v-if="cartnavigation">
                <div
                  class="absolute bottom-full z-20 left-0 w-full text-sm font-bold bg-white"
                >
                  <div class="grid-cols-2 grid">
                    <NuxtLink
                      :external="false"
                      :to="'/shop/checkout?step=1'"
                      class="py-2 px-3 border-y flex gap-1 items-center justify-center"
                      @click="closeCartFlyout()"
                    >
                      Warenkorb
                      <div
                        class="bg-black text-white rounded-full w-4 text-center text-xs"
                      >
                        {{ shoppingCartCount }}
                      </div>
                    </NuxtLink>
                    <NuxtLink
                      :external="false"
                      :to="'/shop/checkout/reservation?step=1'"
                      class="py-2 px-3 border-l border-y flex gap-1 items-center justify-center"
                      @click="closeCartFlyout()"
                    >
                      Reservierung
                      <div class="bg-black text-white rounded-full w-4 text-xs">
                        {{ reservationCartCount }}
                      </div>
                    </NuxtLink>
                  </div>
                </div>
              </template>
            </template>
            <template v-if="shoppingCartCount > 0 && reservationCartCount < 1">
              <NuxtLink
                @click="closeNavigation()"
                :external="false"
                :to="'/shop/checkout?step=1'"
                class="relative"
              >
                <div class="flex items-center flex-col pb-1">
                  <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
                </div>
                <div
                  v-text="entry.title"
                  :class="$route.path.includes('checkout') ? 'text-primary' : ''"
                />
                <div class="absolute top-0 right-0">
                  <div class="bg-black text-white rounded-full w-4 text-center text-xs">
                    {{ (shoppingCartCount || 0) + (reservationCartCount || 0) }}
                  </div>
                </div>
              </NuxtLink>
            </template>
            <template v-if="shoppingCartCount < 1 && reservationCartCount > 0">
              <NuxtLink
                @click="closeNavigation()"
                :external="false"
                :to="'/shop/checkout/reservation?step=1'"
                class="relative"
              >
                <div class="flex items-center flex-col pb-1">
                  <BasicIcon :name="entry.icon" class="w-6 h-6 text-primary" />
                </div>
                <div
                  v-text="entry.title"
                  :class="$route.path.includes('checkout') ? 'text-primary' : ''"
                />
                <div class="absolute top-0 right-0">
                  <div class="bg-black text-white rounded-full w-4 text-center text-xs">
                    {{ (shoppingCartCount || 0) + (reservationCartCount || 0) }}
                  </div>
                </div>
              </NuxtLink>
            </template>
          </template>
        </template>
        <div @click="changeUserState()">
          <div class="flex items-center flex-col pb-1">
            <BasicIcon
              :name="
                useCurrentLoggedInState().value
                  ? 'doorout-arrow-solid'
                  : 'circle-user-solid'
              "
              class="w-6 h-6 text-primary"
            />
          </div>
          <div v-text="useCurrentLoggedInState().value ? 'Abmelden' : 'Anmelden'" />
        </div>
        <teleport to="body">
          <Modal
            v-if="showLogoutModal"
            class="md p-2 round"
            @close-modal="showLogoutModal = false"
          >
            <BasicGeneralErrorBoundary>
              <AppLogoutNotification @closeLogoutModal="showLogoutModal = false" />
            </BasicGeneralErrorBoundary>
          </Modal>
        </teleport>
      </div>
    </footer>
  </div>
</template>

<script setup>
import {
  useShoppingCartItemsCount,
  useReservationCartItemsCount,
} from "~/composables/states";
const reservationCartCount = useReservationCartItemsCount();
const shoppingCartCount = useShoppingCartItemsCount();
const emit = defineEmits(["navigateModal"]);
const cartnavigation = ref(false);
const navigation = ref(false);
const showLogoutModal = ref(false);
const isGuest = useIsGuestCookie();
isGuest.value = false;

function changeUserState() {
  if (useCurrentLoggedInState().value) {
    showLogoutModal.value = true;
  } else {
    emit("navigateModal", "LOGIN");
  }
}

function closeNavigation() {
  navigation.value = false;
  setFootInactive();
}

function setFootInactive() {
  menu.value.links.forEach((item) => {
    item.active = false;
  });
}

async function toggleCart() {
  if (shoppingCartCount.value > 0 && reservationCartCount.value > 0) {
    cartnavigation.value = !cartnavigation.value;
  }
}

const route = useRoute();
watch(
  () => route.path,
  () => {
    closeCartFlyout();
  }
);

async function closeCartFlyout() {
  cartnavigation.value = false;
}
function toggleNavigation() {
  closeCartFlyout();
  navigation.value = !navigation.value;
  if (navigation.value) {
    menu.value.links.forEach((item) => {
      if (item.type == "navigation") {
        item.active = true;
      } else {
        item.active = false;
      }
    });
  } else {
    setFootInactive();
    navigation.value = false;
    menu.value.links.forEach((item) => {
      if (item.type == "reset") {
        item.active = true;
      }
    });
  }
}

const menu = ref({
  links: [
    {
      icon: "expert",
      title: "Startseite",
      href: "/app",
      type: "reset",
    },
    {
      icon: "bars-solid",
      title: "Sortiment",
      type: "navigation",
    },
    {
      icon: "cart-shopping-solid",
      title: "Warenkorb",
      target: "/shop/checkout?step=1",
      type: "cart",
    },
    {
      icon: "heart-shape",
      title: "Merkzettel",
      target: "/shop/wishlist",
      type: "wishlist",
    },
  ],
});
</script>
