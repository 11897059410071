<template>
  <div class="articleStatus">
    <ArticleOnlineAvailability
      :showButtons="showButtons"
      :price="price"
      :states="states"
      @openArticleOnlineStatePopUp="openArticleOnlineStatePopUp"
    />
    <template v-if="!alternativeStoreActive">
      <template v-if="price?.storeAvailability">
        <div class="pb-1 relative">
          <i :class="'stateIcon ' + price?.storeAvailability"></i>
          <div class="pointText">
            {{
              states.storeAvailability
                .find((key) => key.state == price?.storeAvailability)
                ?.textForm?.replace(
                  "$days",
                  price?.minStoreDelivery + " - " + price?.maxStoreDelivery
                )
            }}
          </div>
        </div>
        <template v-if="showButtons && price?.storeButtonAction !== undefined">
          <button
            v-if="
              states.storeButtonAction.find(
                (key) => key.state == price?.storeButtonAction
              )?.icon !== undefined
            "
            :class="'storeBtn-' + price?.storeButtonAction"
            type="button"
            @click="openArticleStoreStatePopUp(price?.storeButtonAction)"
          >
            <BasicIcon
              :name="
                states.storeButtonAction.find(
                  (key) => key.state == price?.storeButtonAction
                )?.icon
              "
              class="min-w-[1.2em]"
            ></BasicIcon>
            {{
              states.storeButtonAction.find(
                (key) => key.state == price?.storeButtonAction
              )?.textForm
            }}
          </button>
        </template>
      </template>
    </template>
    <template
      v-if="
        showStoreStateModal &&
        showButtons &&
        (activeState === 'RESERVE' ||
          activeState === 'ORDER_IN_ADVANCE' ||
          activeState === 'SEARCH_STORE')
      "
    >
      <div
        class="modal-overlay"
        @click="decideToCloseReservation()"
        :class="allowOverflow ? 'overflow-allow' : ''"
      >
        <div class="modal" @click.stop>
          <div
            class="close"
            v-if="activeState === 'RESERVE' || 'ORDER_IN_ADVANCE'"
            @click="
              decideToCloseReservation();
              allowOverflow = true;
            "
          >
            <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
          </div>
          <ArticleCTAReservation
            v-if="activeState === 'RESERVE' || 'ORDER_IN_ADVANCE'"
            @fakeSecondPageCallEmit="decideToCloseReservation()"
            @closeAll="decideToCloseReservation()"
            @close-modal="
              decideToCloseReservation();
              allowOverflow = true;
            "
            @allowOverflow="(value) => (allowOverflow = value)"
            :fakeSecondPageCall="jumpToSecondPageReservation"
            :addArtCategoriesMap="addArtCategoriesMap"
            :activeState="activeState"
          />
        </div>
      </div>
    </template>
    <template v-if="showStoreFinder">
      <template v-if="$device.isMobile">
        <div class="modal-overlay" w @click="showStoreFinder = false">
          <div class="modal" style="width: 90%" @click.stop>
            <div class="close" @click="showStoreFinder = false">
              <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
            </div>
            <PopUpsStoreFinder
              v-if="activeState === 'SEARCH_STORE'"
              style="max-height: 85%"
              @storeChanged="showStoreFinder = false"
            />
          </div>
        </div>
      </template>
      <template v-if="!$device.isMobile">
        <div class="modal-overlay" @click="showStoreFinder = false">
          <div class="modal" style="width: 50%" @click.stop>
            <div class="close" @click="showStoreFinder = false">
              <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
            </div>
            <PopUpsStoreFinder
              v-if="activeState === 'SEARCH_STORE'"
              style="max-height: 85%"
              @storeChanged="showStoreFinder = false"
            />
          </div>
        </div>
      </template>
    </template>
    <template v-if="showOnlineStateModal && showButtons">
      <div class="modal-overlay" @click="decideToCloseOrder()">
        <div class="modal" :style="(pendingAddItemRequest ? ' overflow:hidden':'')" @click.stop>
          <div v-if="pendingAddItemRequest" class="absolute left-0 right-0 top-0 bottom-0 cursor-wait z-10">
              <div class="bg-white h-full w-full opacity-50 absolute"></div>
              <BasicLoader :rows="1" :columns="1" height="100%" />
          </div>
          <div class="close z-10" @click="decideToCloseOrder()">
            <BasicIcon :name="'xmark-solid'" class="w-4 close-img" />
          </div>
          <ArticleCTAOrder
            v-if="activeState == 'ORDER' || activeState == 'ORDER_IN_ADVANCE'"
            @fakeSecondPageCallEmit="decideToCloseOrder()"
            @closeAll="decideToCloseOrder()"
            :state="activeState"
            :price="price"
            :addtionalArtCategories="addArtCategoriesMap"
            :fakeSecondPageCall="jumpToSecondPageOrder"
            :categoryList="categoryList"
          />
        </div>
      </div>
    </template>
    <ClientOnly v-if="!$device.isLighthouse && !$device.isCrawler">
      <template v-if="useFeatureState('ES-6717-Advisor')?.value == true">
        <ArticleAdvisorCard :article="article" />
      </template>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
import { inject, ref } from "vue";
import states from "~/assets/data/articleState.json";
import PopUpsStoreFinder from "~/components/PopUps/StoreFinder.vue";
import { addArticleToResCart } from "~/composables/ExpApi/expCartActions";
import { addArticleToCart } from "~/composables/ExpApi/expCartActions";

import { stateForStoreFinder, useCurrentStore } from "~/composables/states";
// import { Price } from "~/models/ExpPrice/exp_Price";
const allowOverflow = ref(true);
const props = defineProps({
  price: {
    type: Object,
    watch: true,
  },
  // showStoreStateModal: {
  // 	type: Boolean,
  // },
  // showOnlineStateModal: {
  // 	type: Boolean,
  // },
  addArtCategoriesMap: {
    type: Map,
  },
  showButtons: {
    type: Boolean,
    default: true,
  },
  alternativeStoreActive: {
    type: Boolean,
    default: false,
  },
  article: {
    default: () => {
      return inject("primaryArticle");
    },
  },
  warranty: {
    default: () => {
      return inject("primaryWarranty");
    },
  },
  categoryList: {
    type: Object,
  },
});
let activeState = "";
const showStoreFinder = ref(false);
const currentStore = useCurrentStore()?.value?.storeLink;
const showStoreStateModal = ref(false);
const showOnlineStateModal = ref(false);
const jumpToSecondPageOrder = ref(false);
const jumpToSecondPageReservation = ref(false);

function openArticleOnlineStatePopUp(state: string) {
  activeState = state;
  showOnlineStateModal.value = true;
}

async function navigationToReservation() {
  if (useFeatureState("ES-5386-Reservation").value)
    await navigateTo("/checkout/reservation", { external: true });
  else
    await navigateTo("/" + currentStore + "#1946cb49-f42c-4e42-b13a-05016fed4c59", {
      external: true,
    });
}
//----------------------------------- NEU ---------------------------------------------
let seeSecondPageOrder: Boolean;
const pendingAddItemRequest = ref(false)
async function decideToCloseOrder() {
  if (
    props?.article?.crossSaleArticles ||
    (checkForWarrantyAndAvailability())
  ) {
    jumpToSecondPageOrder.value = false;
    if (seeSecondPageOrder) {
      showOnlineStateModal.value = false;
      seeSecondPageOrder = false;
      return showOnlineStateModal.value;
    } else {
      pendingAddItemRequest.value = true
      jumpToSecondPageOrder.value = true;
      showOnlineStateModal.value = true;
      seeSecondPageOrder = true;
      let newShoppingCart = await addArticleToCart(
        props?.article?.articleId,
        useActivatedServicesInCart().value
      );
      useActivatedServicesInCart().value = [];
      pendingAddItemRequest.value = false
      return showOnlineStateModal.value;
    }
  }
  jumpToSecondPageOrder.value = true;
  showOnlineStateModal.value = false;
  seeSecondPageOrder = false;
  return showOnlineStateModal.value;
}

function checkForWarrantyAndAvailability() {
  let onlineAvailabilityArray = ["NOT_AVAILABLE", "ONLY_IN_STORE", "NONE", "SOLD_OUT", "ORDER_IN_ADVANCE", "PREORDER"];
  if (props?.warranty?.value?.warrantyList !== undefined && props?.warranty?.value?.warrantyList?.length > 0 && !onlineAvailabilityArray.includes(props?.price?.onlineButtonAction)) {
    return true
  } else {
    return false
  }
}

let seeSecondPageReservation: Boolean;
function decideToCloseReservation(e?: Event) {
  let priceTemp: any = props.price;
  if (isProxy(priceTemp)) {
    priceTemp = toRaw(priceTemp);
  }
  if (
    Array.isArray(priceTemp?.availableServices) &&
    priceTemp?.availableServices?.length > 0
  ) {
    jumpToSecondPageReservation.value = false;
    if (seeSecondPageReservation) {
      showStoreStateModal.value = false;
      seeSecondPageReservation = false;
      allowOverflow.value = true;
      return showStoreStateModal.value;
    } else {
      jumpToSecondPageReservation.value = true;
      showStoreStateModal.value = true;
      seeSecondPageReservation = true;
      allowOverflow.value = false;
      return showStoreStateModal.value;
    }
  }
  jumpToSecondPageReservation.value = true;

  seeSecondPageReservation = false;
  showStoreStateModal.value = false;
  return showStoreStateModal.value;
}
function decideToCloseStoreFinder() {
  console.log("decideToCloseStoreFinder");
  showStoreFinder.value = false;
  showStoreStateModal.value = false;
  return showStoreStateModal.value;
}

const route = useRequestURL().pathname;
async function openArticleStoreStatePopUp(state: string) {
  activeState = state;
  if (state === "RESERVE" || state === "ORDER_IN_ADVANCE") {
    // if (props?.addArtCategoriesMap && props?.addArtCategoriesMap?.size > 0) {
    showStoreStateModal.value = true;
    // } else {
    // await addArticleToResCart(props?.article?.articleId, [])
    // navigationToReservation()
    // }
  } else if (state === "REQUEST_ADVICE") {
    console.log(
      states?.storeButtonAction.find((key) => key.state == "REQUEST_ADVICE")?.directLink
    );
    await navigateTo(
      "/?" +
        states?.storeButtonAction.find((key) => key.state == "REQUEST_ADVICE")
          ?.directLink,
      { external: true }
    );
  } else if (state === "SEARCH_STORE") {
    showStoreFinder.value = true;
    // await navigateTo("/?"+states?.storeButtonAction.find(key => key.state == 'SEARCH_STORE')?.directLink, { external: true })
    stateForStoreFinder().value = true;
  } else if (
    state === "INFORM_CUSTOMER" ||
    state === "REQUEST_CONTRACT" ||
    state === "REQUEST_LEAD_TIME" ||
    state === "REQUEST_PRICE"
  ) {
    let priceRequestUrlPath =
      "/" +
      currentStore +
      "?article_id=" +
      props?.article?.articleId +
      states?.storeButtonAction.find((key) => key.state == "REQUEST_LEAD_TIME")
        ?.directLink;
    await navigateTo(priceRequestUrlPath, { external: true });
    // showStoreStateModal.value = true;
  }
}
</script>
<style>
.modal-overlay.sidebar-right .modal {
  @apply fixed w-[90vw] lg:w-[25vw] lg:right-0 rounded-2xl lg:rounded-tl-2xl lg:rounded-bl-2xl lg:rounded-tr-none lg:rounded-br-none  overflow-hidden pr-0;
}
</style>
