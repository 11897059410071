export class Price implements IPrice {
	cacheLevel?: string;
	cachedOn?: number;
	createdOn?: any;
	lastUpdated?: any;
	pubSource?: any;
	nettoPrice!: number;
	bruttoPrice!: number;
	taxRate!: number;
	onlineButtonAction!: string;
	onlineAvailability!: Availability;
	minOnlineDelivery!: number;
	maxOnlineDelivery!: number;
	onlineStock!: number;
	onlineReservedStock!: number;
	storeAvailability!: Availability;
	minStoreDelivery!: number;
	maxStoreDelivery!: number;
	storeStock!: number;
	storeReservedStock!: number;
	shipmentType!: string;
	shipmentNettoPrice!: number;
	shipmentBruttoPrice!: number;
	shipmentTaxRate!: number;
	shipmentCurrency!: string;
	hideVskText!: boolean;
	storeButtonAction!: string;
	shipmentArray!: any[];
	itemOnDisplayDescription!: string;
	itemOnDisplay!: boolean;
	availableServices!: AvailableServices[];
	storeId!: string;
	showStoreName!: string;
}

const states = ["AVAILABLE",
	"PREORDER",
	"PROCURABLE",
	"SOLD_OUT",
	"NOT_IN_STORE",
	"NEXT_DAYS_AVAILABLE",
	"ONLY_IN_STORE",
	"NOT_AVAILABLE"] as const;

type Availability = typeof states[number];

export interface IPrice {
	cacheLevel?: string;
	cachedOn?: number;
	createdOn?: any;
	lastUpdated?: any;
	pubSource?: any;
	nettoPrice?: number;
	bruttoPrice?: number;
	taxRate?: number;
	onlineButtonAction?: string;
	onlineAvailability?: Availability;
	minOnlineDelivery?: number;
	maxOnlineDelivery?: number;
	onlineStock?: number;
	onlineReservedStock?: number;
	storeAvailability?: Availability;
	minStoreDelivery?: number;
	maxStoreDelivery?: number;
	storeStock?: number;
	storeReservedStock?: number;
	shipmentType?: string;
	shipmentNettoPrice?: number;
	shipmentBruttoPrice?: number;
	shipmentTaxRate?: number;
	shipmentCurrency?: string;
	hideVskText?: boolean;
	storeButtonAction?: string;
	shipmentArray?: any[];
	itemOnDisplayDescription?: string;
	itemOnDisplay?: boolean;
	availableServices?: AvailableServices[];
	storeId?: string;
	showStoreName?: string;
}

export type AvailableServices = {
	label: string;
	id: string;
	toolTip: string;
	icon: string;
	childService: any[];  // It's good to specify a more detailed type than 'any' if possible.
}

export interface BasePrice {
	taxRate: number;
	gross: number;
	currency: string;
	net: number;
}