<template>
  <NuxtErrorBoundary @error="someErrorLogger">
    <template #error="{ error, clearError }">
      <div class="flex flex-col gap-y-4 p-4 lg:p-0">
        <div class="container mx-auto py-5 text-paragraph font-bold justify-between text-center">
          <div class="bg-wheat-500 hover:bg-wheat-700 text-primary font-bold py-2 px-4 rounded self-bottom"
            :class="messageClass">
            <button @click="
              clearError();
            if (shouldClearData == true) clearNuxtData();
            " class="border rounded-xl w-full flex justify-center items-center text-info"
              :class="messageClass == 'info' ? 'border-primary' : 'border-error'">
              <div class="px-4 py-7 flex items-center">
                <div :class="'text-' + messageClass">
                  <BasicIcon :name="'circle-info-light'" v-if="messageClass == 'info'" class="w-4 h-[14px]" />
                  <BasicIcon :name="'xmark-solid'" v-else-if="messageClass == 'error'" class="w-4 h-4 scale-150" />
                  {{ messageText }}
                </div>
                <div class="hidden" v-if="useFeatureState('bt-dev-debug')?.value == true || useAppConfig()?.isAppUser">
                  {{ messageDebug }}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
    </template>
    <slot />
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import cartErrorCodes from "~/assets/data/cartErrorCodes.json";

const messageClass = ref("error");
const messageText = ref(
  "Es ist ein unbekannter Fehler aufgetreten. Hier klicken um es erneut zu versuchen."
);
const messageDebug = ref("");
const shouldClearData = ref(false);

function someErrorLogger(error: any) {
  if (error) {
    let checker = error.toString();
    console.log("GeneralErrorBoundary : ", error, " ;  ");
    try {
      console.debug("[bt-GeneralErrorBoundary] Some error occured: ", error);
      console.debug("got error cause", error?.cause);
      const msg = cartErrorCodes?.codes?.find(
        (cEC: { namespace: any; errorcode: any }) =>
          cEC.namespace == error?.cause?.namespace &&
          cEC.errorcode == error?.cause?.errorcode
      );
      if (msg) {
        messageClass.value = msg?.displayClass;
        messageText.value = msg?.displayMessage;
      } else {
        console.error('got unknown error', error);
        if (error?.cause?.errorcode == 500) {
          shouldClearData.value = true;
        }
        messageDebug.value = `namespace=${error?.cause?.namespace}, errorcode=${error?.cause?.errorcode}, id=${error?.cause?.id}, shouldClearData=${shouldClearData.value}`;
      }
    } catch (error) {
      throw createError({
        statusCode: 500,
        statusMessage: "Page Broken",
        cause: error,
        fatal: true,
      });
    }
  }
}
</script>
