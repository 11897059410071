export default defineNuxtRouteMiddleware((to, from) => {
    const sessIdent = useCurrentSessionIdentifier()?.value
    if (sessIdent != undefined && sessIdent != null) {
        useGeneralTrick({
            eventType: "VIEW_PAGE",
            sessionId: sessIdent,
            json: {
                content: to.path,
                url: [[to?.fullPath]],
                lastActions: useLastActions()?.value,
            },
        }, to);
        if (useLastActions()?.value?.length)
            useLastActions().value.length = 0;
    }
})
