<template>
  <div>
    <NuxtLayout :name="useAppConfig()?.isAppUser ? 'app' : undefined">
      <div>
        <component :is="$device?.isLighthouse ? dynamicComp : 'div'">
          <BasicBtErrorBoundary>
            <ClientOnly>
              <LazyBasicSessionReplay v-if="useUserGroup()?.value == 'internal'" />
            </ClientOnly>
          </BasicBtErrorBoundary>
          <Transition mode="in-out">
            <div class="bubble flex gap-y-4 p-4 text-primary" v-if="showInfo">
              <div class="bubble-container container">
                <BasicIcon :name="'location-dot-solid'" class="inline-block w-4 text-primary" />
                <span class="pl-1 w-full">Sie wurden einem lokalem Fachmarkt in der Nähe zugewiesen!
                  <u>
                    <NuxtLink :external="true"
                      href="/Footer/Website/Datenschutzerklaerung#:~:text=16.%20Geo%2DLokalisierung%20f%C3%BCr%20den%20Fachmarkt%2DFinder">
                      Mehr erfahren</NuxtLink>
                  </u></span>
              </div>
            </div>
          </Transition>
          <NuxtPage />
          <NuxtLoadingIndicator :color="'#ff5e19'" :height="useDevice()?.isMobile ? 4 : 10" :duration="4" />
        </component>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { identity, useBroadcastChannel } from "@vueuse/core";
import { provide } from "vue";

import { useCurrentStore, useStoreCookie } from "~/composables/states";
import { ProvideNames } from "~/composables/statics";
import { getExpUserProfile } from "~/composables/ExpApi/expUser";
import { useWindowSize } from "@vueuse/core";
import { fetchNavigationTree, getExpNavigationTree } from "./composables/ExpApi/expCache";
import { getWishlist } from "~/composables/expWishList";
import type { DeeplinkResponse } from "~/models/Deeplink";

const dynamicComp = useDevice()?.isLighthouse ? resolveComponent('DelayHydration') : 'div';

// #region Store Change Handling>
/** 
  * Store Change Broadcast Channel Handling. This is receiving Store Changes
  * from other tabs, so every tab will always be assigned to the same FM. 
*/
const { isSupported, data, post, error } = useBroadcastChannel({
  name: "fmarkt-channel",
});

watch(data, () => {
  if (useCurrentStore()?.value?.id != data?.value) {
    const rout = useRoute()?.fullPath?.toLowerCase();
    if (rout.includes("campaignid") || rout.includes("branch_id")) {
      useRouter().push({
        path: useRoute().path,
        query: {},
      });
    }
    nextTick(async () => {
      console.debug("got message for different store!! will switch");
      await changeStoreById(useStoreCookie()?.value, useCurrentStore()?.value?.id, 'broadcast-message');
    });
  }
});
let route = useRoute()?.fullPath?.toLowerCase();
if (route?.startsWith('/app'))
  useAppConfig().isAppUser = true;
else if (useAppSettingsCookie()?.value?.isApp)
  useAppConfig().isAppUser = true;

onNuxtReady(async () => {
  if (isSupported.value == true && useAppConfig()?.isAppUser != true) {
    post(useStoreCookie()?.value);
  }
  if (import.meta.client && useAppConfig()?.isAppUser != true) {
    if (useCurrentStore()?.value?.id != useStoreCookie()?.value) {
      console.debug("state and cookie do not match");
      await changeStoreById(useStoreCookie()?.value, useCurrentStore()?.value?.id, 'state-cookie-mismatch');
    }
    const abcookie = useCookie("expertShopABConfig", {
      maxAge: 72 * 3600,
      default: () => "false",
      sameSite: "strict",
    });
    const resW = watchEffect(async () => {
      if (useCurrentSessionIdentifier().value) {
        if (useFeatureState("bt-internal-test").value) {
          // startTracking()
        }

        // console.debug('ready to trick');
        if (abcookie?.value != undefined && abcookie.value != "true") {
          await useGeneralTrick({
            eventType: "VIEW_PAGE",
            sessionId: useCurrentSessionIdentifier()?.value,
            tickId: useExpCookieIdentifier()?.value,
            json: {
              content: "/consent/",
              btId: useExpCookieIdentifier()?.value,
              cookies: useCookie("UserCookiePermissions").value,
            },
          });
          abcookie.value = "true";
          resW();
        }
      }
    });
  }
});

const currentStore = useCurrentStore();

const storeRef = {
  currentStore: currentStore,
  updateFunction: changeStoreById,
};

// #endregion Store Change Handling>

//#region <Automatic Store Assignment>

onNuxtReady(async () => {
  if (
    useRoute()?.fullPath?.toLowerCase().includes("campaignid") &&
    (useStoreCookie()?.value != "e_2879130" ||
      useCurrentStore()?.value?.id != "e_2879130")
  ) {
    console.debug("found campaign param but store is wrong");
    await nextTick(async () => {
      await changeStoreById("e_2879130", useCurrentStore()?.value?.id, "parameter-campaignid");
    });
  }

  //#region <Deferred Deeplink Handling (EXSI-145)>
  if (useAppConfig()?.isAppUser && import.meta.client) {
    if (useStoreCookie()?.value === undefined || useStoreCookie()?.value === "e_2879130") {
      console.debug('trying to get store from deeplink')
      const deeplink: DeeplinkResponse = await $fetch<DeeplinkResponse>('/api/deeplink');
      if (deeplink?.branchId) {
        refreshCookie(ProvideNames.STORE_COOKIE_NAME);
        nextTick(async () => {
          await changeStoreById(deeplink?.branchId, useCurrentStore()?.value?.id, 'deferred-deeplink');
        })
      }
    }
  }
  //#endregion

  if (useCookie("fmarkt_autoComplete").value !== undefined && !useDevice()?.isCrawler && !useAppConfig()?.isAppUser && !useAppSettingsCookie()?.value?.isApp) {
    const oldStore = useCurrentStore().value?.id;
    if (
      useCookie("fmarkt_autoComplete").value !== oldStore ||
      useCookie("fmarkt_autocomplete").value !== useCurrentStore().value?.id
    ) {
      let newStore = await useFetch(
        useRuntimeConfig().public.apiUrl + "/api/switchStore",
        {
          server: false,
          params: { branch_id: unref(useRoute()?.query)?.branch_id },
          key: Date.now() + "store",
        }
      );
      if (
        oldStore !== newStore.data.value?.newStore ||
        useCurrentStore().value?.id !== newStore.data.value?.newStore
      ) {
        await changeStoreById(newStore.data.value?.newStore, oldStore, 'auto-assign');
        nextTick(() => {
          showInfo.value = true;
          new Promise((resolve) => setTimeout(resolve, 10000)).then((res) => {
            showInfo.value = false;
          });
        });
      }
    }
  }
  if (
    (useCookie("fmarktcookie_opened").value === "false" ||
      useCookie("fmarktcookie_opened").value === undefined) &&
    useRoute().query?.campaignId === undefined &&
    useRoute().query?.branch_id === undefined
  ) {
    if (useDevice()?.isApplePWA || useAppConfig().isAppUser || useNuxtApp()?.$pwa?.isPWAInstalled || useDevice()?.isLighthouse || useDevice()?.isCrawler) {
      // No Auto Assign for App
    } else {
      console.warn("auto assigning store");
      const storeFromCookie = await useFetch(
        "/api/storeFinder",
        { params: { maxResults: 1 }, method: "GET" }
      );
      useCookie("fmarktcookie_opened", { domain: useRuntimeConfig()?.public?.cookieDomain }).value = "true";
      if (useStoreCookie().value === undefined)
        useStoreCookie().value = storeFromCookie?.data?.value?.at(0)?.store?.id;
      // useCookie('fmarkt_autoComplete').value = storeFromCookie?.data?.value[0]?.store?.id;
      //console.log("update function called", storeFromCookie?.data?.value?.at(0)?.store?.id);
      await changeStoreById(storeFromCookie?.data?.value?.at(0)?.store?.id, useCurrentStore()?.value?.id, 'auto-assign-2');
      nextTick(() => {
        showInfo.value = true;
        new Promise((resolve) => setTimeout(resolve, 10000)).then((res) => {
          showInfo.value = false;
        });
      });
    }
  } else if (
    useCurrentStore().value?.id === undefined &&
    useStoreCookie().value !== undefined
  ) {
    await changeStoreById(useStoreCookie().value, useCurrentStore().value?.id, 'set-to-cookie');
  }
})


//#endregion </Automatic Store Assignment>

if (useRequestHeaders()["bt-user-group"])
  useUserGroup().value = useRequestHeaders()["bt-user-group"];

useNuxtApp().vueApp.config.warnHandler = (msg, instance, trace) => {
  // `trace` is the component hierarchy trace
  if (!msg?.includes("Hydration")) {
    console.warn("[vue-warning] ", msg);
  }
};

onNuxtReady(() => {
  // #region <Click Tracking>
  console.debug('nuxt ready - adding click event listener');
  document.addEventListener('click', (ev) => {
    let targetData = {
      group: ev.target?.getAttribute('data-tgroup') || ev.target?.getAttribute('aria-label') || checkTenParents(ev.target, 'aria-label'),
      name: ev.target?.getAttribute('data-tname') || ev.target?.getAttribute('type') == 'button' ? ev.target?.textContent?.trim() : ev.target.getAttribute('class'),
      detail: ev.target?.getAttribute('data-tdetail') || checkTenParents(ev.target, 'data-tdetail'),
      href: ev.target?.getAttribute('href') || checkTenParents(ev.target, 'href'),
      type: ev.target?.getAttribute('type') || checkTenParents(ev.target, 'type')
    }
    if (useFeatureState('cfg-clicktracking')?.value == true || //
      (useFeatureState('cfg-linktracking')?.value == true && //
        (targetData?.href || targetData?.type))) {
      const eve = {
        eventType: 'USER_INPUT',
        tickId: crypto.randomUUID(),
        sessionId: useCurrentSessionIdentifier().value,
        json: {
          clientX: ev.clientX,
          clientY: ev.clientY,
          pointerType: ev.pointerType,
          type: ev.type,
          target: ev.target?.localName,
          ...targetData
        }
      };
      console.debug('clicked ', ev, eve)
      useGeneralTrick(eve)
    }
  })
  // #endregion </Click Tracking>


  // #region <App Detection>

  try {
    route = useRoute()?.fullPath?.toLowerCase();
    let context: any = {
      identity: {
        identifier: useExpCookieIdentifier()?.value,
        traits: {
          isappuser: { value: false }
        }
      }
    }
    // console.debug('got route',route);
    if (useRuntimeConfig()?.public?.isPWABuild || route.startsWith("/app")) {
      context.identity.traits['isappuser'] = { value: true };
      useAppSettingsCookie().value.isApp = true;
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btdevice=android')) {
      context.identity.traits['isappuser'] = { value: true };
      context.identity.traits['appdeviceos'] = { value: "android" };
      useAppSettingsCookie().value.isNativeApp = true;
      useAppSettingsCookie().value.isApp = true;
      useAppConfig().isAppUser = true;
    } else if (useDevice()?.isApplePWA) {
      context.identity.traits['isappuser'] = { value: true };
      context.identity.traits['appdeviceos'] = { value: "ios" };
      useAppSettingsCookie().value.isNativeApp = true;
      useAppSettingsCookie().value.isApp = true;
    }
    if (route.includes('bttargetversion')) {
      context.identity.traits['isappuser'] = { value: true };
      context.identity.traits['appversion'] = { value: useRoute()?.query?.bttargetversion?.toString() };
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btversionenv')) {
      context.identity.traits['isappuser'] = { value: true };
      context.identity.traits['appversionenv'] = { value: useRoute()?.query?.btversionenv?.toString() };
      useAppConfig().isAppUser = true;
    }
    if (route.includes('btpushnotificationid')) {
      console.debug('got notificationId to track', useRoute()?.query?.btpushnotificationid?.toString())
      useAppConfig().isAppUser = true;
      let cont = useNuxtApp()?.$flags.getContext();
      let oldCount = (cont.identity?.traits?.btpushnotifications?.value ?? 0);
      context.identity.traits.btpushnotifications = { value: oldCount++ }
      useMarker('notification-opened');
      useGeneralTrick({
        eventType: "NOTIFICATION_OPEN",
        sessionId: useCurrentSessionIdentifier()?.value,
        json: {
          btpushnotificationid: useRoute()?.query?.btpushnotificationid?.toString(),
          url: [useRoute()?.fullPath],
        }
      })
    }
    if (route.includes('btpushtoken')) {
      useAppConfig().isAppUser = true;
      try {
        useAppPushCookie().value = JSON.parse(useRoute()?.query?.btpushtoken?.toString() || useRoute()?.query?.token?.toString());
      } catch (error) {
        console.debug('error, using unparsed', error)
        useAppPushCookie().value = useRoute()?.query?.btpushtoken?.toString() || useRoute()?.query?.token?.toString();
      }
    }
    useNuxtApp()?.$flags?.setContext(context)
  } catch (error) {
    console.error("could not set flagsmith trait", error);
  }

  if (useAppPushCookie()?.value != undefined) {
    useNuxtApp()?.$flags.setTrait("pushtoken", useAppPushCookie().value);
  }

  // #endregion </App Detection>

});

onNuxtReady(async () => {
  watch(() => useRoute()?.fullPath, async () => {
    let { data: navigationTree } = await getExpNavigationTree("MAIN");
    if (!navigationTree.value) {
      navigationTree.value = await fetchNavigationTree("MAIN");
    }

    const routeParams = useRoute().params;

    let currCategory = navigationTree.value.find((node) =>
      node?.url === routeParams.parentCategory);

    if (useRoute().params.childCategory !== undefined) {
      currCategory = currCategory.descendants.find((node) =>
        node?.url === routeParams.childCategory);
      if (useRoute().params.productType !== undefined) {
        currCategory = currCategory.descendants.find((node) =>
          node?.url === routeParams.productType);
      }
    }

    const categoryParam = currCategory ? `&selectedCategoryId=${currCategory.extId}` : ``;

    const apiPath = `${useRuntimeConfig().public.apiUrl}/api/nea/canonical-service/canonicalProvider?url=${encodeURIComponent(location.href)}${categoryParam}`;
    const { data } = await useFetch(apiPath);

    if (data.value && data.value !== "") {
      useHead({
        link: [{
          rel: "canonical",
          href: data.value,
        }]
      });
    }
  }, { immediate: true });
});

watchEffect(async () => {
  if (useCurrentUserState()?.value?.shoppingCartId != undefined) {
    console.debug("updating carts", useCurrentUserState());
    const { data: sstate } = await fetchCurrentCart(
      useCurrentUserState().value.shoppingCartId
    );
    const { data: rstate } = await fetchCurrentCart(
      useCurrentUserState().value.reservationCartId
    );
    if (ProvideNames.ENABLE_CORRECT_CART_COUNTING) {
      //console.log('calculating cart count');
      if (sstate?.value) {
        sstate.value.overAllQuantity = 0
        for (let a = 0; a < sstate?.value?.itemList?.length; a++) {
          sstate.value.overAllQuantity += sstate?.value?.itemList[a].quantity;
        }
      }
      if (rstate?.value) {
        rstate.value.overAllQuantity = 0
        for (let a = 0; a < rstate?.value?.itemList?.length; a++) {
          rstate.value.overAllQuantity += rstate?.value?.itemList[a].quantity;
        }
      }
    }
    useShoppingCartItemsCount().value = !isNaN(sstate?.value?.overAllQuantity)
      ? sstate?.value?.overAllQuantity
      : 0;
    useReservationCartItemsCount().value = !isNaN(rstate?.value?.overAllQuantity)
      ? rstate?.value?.overAllQuantity
      : 0;
    useCurrentShoppingCart().value = sstate.value;
    useCurrentReservationCart().value = rstate.value;
  } else {
    // console.warn("no user, no cart");
  }
});



if (!useDevice()?.isCrawler) {
  let heartbeatInterval;

  onNuxtReady(() => {
    heartbeatInterval = setInterval(function () {
      $fetch("/api/neo/frontend/_api/user/heartbeat", { method: "POST", body: "{}" });
    }, 600000);
  });

  onBeforeUnmount(() => {
    if (heartbeatInterval) clearInterval(heartbeatInterval);
  });
}

const ratingsCookie = getCookiePermissions(ProvideNames.PRODUCT_RATINGS);

callOnce('fetchPromotionsOnce', async () => {
  console.debug("fetching promotions");
  if (useDevice()?.isLighthouse == true || useState("isLighthouse")?.value == true) {
    console.debug("loading nothing");
  } else {
    useActivePromotions().value = await $fetch(useRuntimeConfig().public.apiUrl + "/api/activePromotions", { key: 'activePromotionData' })
  }

})


getExpNavigationTree('MAIN').data.value = await fetchNavigationTree('MAIN');
getExpNavigationTree('SUPER').data.value = await fetchNavigationTree('SUPER');
getExpNavigationTree('FOOTER').data.value = await fetchNavigationTree('FOOTER');
getExpNavigationTree('FOOTER_HARDLINKS').data.value = await fetchNavigationTree('FOOTER_HARDLINKS');
getExpNavigationTree('FOOTER_SOCIAL').data.value = await fetchNavigationTree('FOOTER_SOCIAL');

// #region <CookieBot and Google Tag Manager Init>

onNuxtReady(() => {
  if (useDevice()?.isLighthouse == true || useState("isLighthouse")?.value == true || useIsCrawlerState()?.value || useAppConfig()?.isAppUser) {
    console.info("loading nothing");
  } else {
    const gtm = useGtm();
    if (gtm?.enabled() && gtm?.dataLayer()) {
      console.debug('[bt-default] gtm enabled and found!!! starting to push it real good', gtm?.dataLayer())
      if (gtm.dataLayer()?.find(dl => dl[1] == "ads_data_redaction")) {
        console.debug('[bt-default] gtm already filled, skipping', gtm?.dataLayer())
      }
    } else {
      console.info('gtm is disabled')
    }

    useHead({
      script: [{
        src: "https://www.googletagmanager.com/gtm.js?id=GTM-KM6K3JR",
        type: "text/javascript",
        async: "true",
        "data-cookieconsent": "ignore",
        id: 'googletagmanager'
      }],
    });

    gtm?.push({ 'gtm.start': Date.now(), event: 'gtm.js' });

    gtm?.push({
      'consent': 'default', event: {
        ad_storage: "denied",
        analytics_storage: "denied",
        functionality_storage: "denied",
        personalization_storage: "denied",
        security_storage: "granted",
        wait_for_update: 500,
      }
    })

    gtm?.push({ 'set': 'ads_data_redaction', event: true })

    useHead({
      script: [{
        src: "https://consent.cookiebot.eu/uc.js",
        "data-cbid": "36f7f725-f52e-4678-a71c-fe212fc2c34e",
        id: "CookieDeclaration",
        type: "text/javascript",
        'data-blockingmode': computed(() => (!useRoute()?.fullPath?.toLowerCase().includes('/checkout') || useRoute()?.fullPath?.toLowerCase().includes('/confirmation')) ? 'auto' : 'none')
      }]
    });

    window.addEventListener("CookiebotOnConsentReady", function () {
      console.debug('handling Cookiebot Callback');
      var prefs = { REQUIRED: Cookiebot.consent.necessary, STATISTICS: Cookiebot.consent.statistics, MARKETING: Cookiebot.consent.marketing, PRODUCT_RATINGS: Cookiebot.consent.preferences };
      var currentCookie = document.cookie.split(";").map(function (cookie) { return cookie.trim(); }).filter(function (cookie) { return cookie != null && cookie.indexOf("UserCookiePermissions") == 0; })[0];
      if (currentCookie == null) {
        currentCookie = {};
      } else {
        try {
          currentCookie = JSON.parse(decodeURIComponent(currentCookie.split("=").pop()));
        } catch (err) {
          currentCookie = {};
        }
      }
      if (true || Object.keys(prefs).filter(function (cookieName) { return prefs[cookieName] != currentCookie[cookieName] }).length > 0) {
        $fetch("/api/neo/frontend/_api/user/consent", {
          body: JSON.stringify({
            preference: prefs,
            privacyPreferenceInfo: "CookiebotRenderPostProcessor",
            IABConsentString: Cookiebot.IABConsentString,
            mobile: window?.isMobileDevice
          }), method: "POST", headers: {
            'bt-use-user-auth': 'true',
            'csrf-token': useCurrentUserState()?.value?.csrfToken
          }
        })
        document.cookie = "UserCookiePermissions=" + encodeURIComponent(JSON.stringify(prefs));
      }
    });

  }
});
// #endregion </CookieBot and Google Tag Manager Init>

const showInfo = ref(false);

const { width, height } = useWindowSize();

function setDevice(width) {
  if (width < 1200 && useDevice().isDesktop) {
    // console.log('setting mobile for desktop')
    useDevice().isMobile = true;
    useDevice().isDesktop = false;
  } else if (width > 1200 && useDevice().isMobile) {
    // console.debug('setting desktop for desktop')
    useDevice().isMobile = false;
    useDevice().isDesktop = true;
  }
}

setDevice(width.value)
watch(width, (_someVal) => {
  setDevice(width.value)
});


onNuxtReady(async () => {
  if (useDevice()?.isLighthouse || useDevice()?.isCrawler) {
    let cb = document?.getElementById("CookieDeclaration");
    if (cb) {
      cb.remove();
    }
  } else if (!ratingsCookie?.value) {
    let bv = document?.getElementById("bazaarvoicejs");
    if (bv) {
      bv.remove();
    }
  }

  if (!useCurrentUserState()?.value && !useDevice()?.isCrawler) {
    const expUser = await getExpUserProfile();
    useCurrentUserState().value = expUser;
  }

  watch(() => useExpCookieIdentifier()?.value, async () => {
    if (useExpCookieIdentifier()?.value) {
      console.debug('fetching wishList');
      useCurrentWishList().value = (await getWishlist());
    }
  }, { immediate: true })

  // onNuxtReady(async () => {
  if (useCurrentLoggedInState()?.value != true) {
    // let decoded = jwtDecode(jwt.value);
    if (useCurrentUserState()?.value?.csrfToken == undefined)
      await getExpUserProfile();
    await $fetch('/api/login', {
      headers: {
        'csrf-token': useCurrentUserState()?.value?.csrfToken
      },
      credentials: 'include',
    })
    clearNuxtData(["currentUserProfile", "currentShoppingCart", "currentReservationCart", "shoppingCartItemsCount", "reservationCartItemsCount"]);
    clearNuxtData('userProfile');
    await getExpUserProfile();
    await fetchCurrentWishList();
    // await fetchCurrentCart(useCurrentUserState()?.value?.shoppingCart);

  } else if (useCurrentLoggedInState()?.value == true) {
    console.debug('logged in state == true, checking for jwt');
  } else {
    console.debug('logged in state == false, and no jwt found, never logged in?');
  }
  // })

});

// fetchCurrentWishList();

provide(ProvideNames.CURRENT_STORE_KEY, storeRef);

provide(ProvideNames.BASE_DIR, "/shop/unsere-produkte/");
provide(
  "ImagePlaceHolder",
  "/17/89/06/2d7e8a948fb264651203723a2101d1283b/defaultImage_100x100_90-0.jpeg"
);
</script>

<style>
.primaryColor {
  color: #ff5e19;
}

.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(1rem);
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.bubble {
  text-align: center;
  background-color: aliceblue;
}

.bubble-container {
  max-width: 1200px;
  max-height: 120px;
  margin: auto;
}
</style>
